import React, {useState, useContext, useCallback, useEffect, useLayoutEffect,  useMemo, useRef   } from 'react';
import {useHistory, useParams, Link} from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {NavContext} from '../context/NavContext'
import {useHttp} from '../hooks/http.hook';
import _ from "lodash";
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment-timezone';
import WorkItemsTable2 from './WorkItemsTable/WorkItemsTable'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChipInput from 'material-ui-chip-input';     
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StopIcon from '@material-ui/icons/Stop';
import ToggleButton from '@material-ui/lab/ToggleButton';
//import {QueryBuilder, formatQuery} from 'react-querybuilder';
//import GooglePicker from 'react-google-picker'
import Backdrop from '@material-ui/core/Backdrop';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import StyleIcon from '@material-ui/icons/Style';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import ProfileMenu from './ProfileMenu'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { Scrollbars } from 'react-custom-scrollbars';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';
import ChatIcon from '@material-ui/icons/Chat';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogEditor from './DialogEditor'
import 'moment/locale/ru'
import io from 'socket.io-client';
import ReactResizeDetector from 'react-resize-detector';
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import SmallSwitch from './DinaComponents/SmallSwitch'
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {Query as QbQuery, Builder, BasicConfig, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import './QueryBuilder.css'
//import 'react-awesome-query-builder/lib/css/styles.css';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import EditIcon from '@material-ui/icons/Edit';
import Board from './Kanban' //'react-trello'
import {MovableCardWrapper } from './Kanban/styles/Base'
import DeleteButton from './Kanban/widgets/DeleteButton'
import Tag from './Kanban/components/Card/Tag'
import RootRef from "@material-ui/core/RootRef";
import Popover from '@material-ui/core/Popover';
import { ruRU } from '@material-ui/core/locale';
import FullCalendar from '@fullcalendar/react'
import MenuList from '@material-ui/core/MenuList';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContainerDimensions from 'react-container-dimensions'
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import LockIcon from '@material-ui/icons/Lock';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from 'react-calendar-timeline'
import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT, onWindowResize} from './Timeline'
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import './Timeline/css/style.css'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'react-calendar-timeline/lib/Timeline.css'
//import TimelineFullCalendar from './TimelineFullCalendar'
//import timelinePlugin from '@fullcalendar/timeline';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
//import "@fullcalendar/core/main.css";
//import "@fullcalendar/timeline/main.css";
//import "@fullcalendar/resource-timeline/main.css";
//import resourceCommonPlugin from '@fullcalendar/resource-common'
//import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
//import 'react-calendar-timeline/lib/Timeline.css'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import InputAdornment from '@material-ui/core/InputAdornment';
import ruLocale from '@fullcalendar/core/locales/ru';
import ruLoc  from "date-fns/locale/ru";
import FormRenderer, { componentTypes } from '@data-driven-forms/react-form-renderer';
import { componentMapper, FormTemplate } from '@data-driven-forms/mui-component-mapper';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import './ReportEditor.css'
import { orange, green, red, purple, pink, blueGrey  } from '@material-ui/core/colors';
import SplitPane from 'react-split-pane';
import Query from '../Utils/query' 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MemberEditor from './WorkItemsTable/MemberEditor'
import MemberFormEditor from './WorkItemsTable/MemberFormEditor'
import ChatMemberEditor from './WorkItemsTable/ChatMemberEditor'
import SelectEditor from './WorkItemsTable/SelectEditor'
import LinkEditor from './WorkItemsTable/LinkEditor'
import ColumnSettingsDialog from './WorkItemsTable/ColumnSettingsDialog'
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { ListItemAvatar } from '@material-ui/core';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import SubjectIcon from '@material-ui/icons/Subject';
import FormLabel from '@material-ui/core/FormLabel';
import WebIcon from '@material-ui/icons/Web';
import XLSX from "xlsx";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import { Wrapper } from "@googlemaps/react-wrapper";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import AttachmentsEditor from './WorkItemsTable/AttachmentsEditor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import SaveIcon from '@material-ui/icons/Save';
import Chart from "react-google-charts";
import GridLayout from 'react-grid-layout';
import Plot from 'react-plotly.js';
import plotly from 'plotly.js/dist/plotly';
import PlotlyEditor from 'react-chart-editor';
import ObjectID from "./ObjectId";
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AddCommentIcon from '@material-ui/icons/AddComment';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SmsIcon from '@material-ui/icons/Sms'; 
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SearchIcon from '@material-ui/icons/Search';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import GroupIcon from '@material-ui/icons/Group';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DescriptionIcon from '@material-ui/icons/Description';
import EditorJS_Embed from '@editorjs/embed'
import EditorJS_Table from '@editorjs/table'
import EditorJS_Paragraph from '@editorjs/paragraph'
import EditorJS_List from '@editorjs/list'
import EditorJS_Warning from '@editorjs/warning'
import EditorJS_Code from '@editorjs/code'
import EditorJS_LinkTool from '@editorjs/link'
import EditorJS_Image from '@editorjs/image'
import EditorJS_Raw from '@editorjs/raw'
import EditorJS_Header from '@editorjs/header'
import EditorJS_Quote from '@editorjs/quote'
import EditorJS_Marker from '@editorjs/marker'
import EditorJS_CheckList from '@editorjs/checklist'
import EditorJS_Delimiter from '@editorjs/delimiter'
import EditorJS_InlineCode from '@editorjs/inline-code'
import EditorJS_SimpleImage from '@editorjs/simple-image'
import { createReactEditorJS } from 'react-editor-js'
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import 'react-chart-editor/lib/react-chart-editor.min.css'
//import { BASE_OPTION_REFINERS } from '@fullcalendar/core'
//BASE_OPTION_REFINERS.schedulerLicenseKey = "CC-Attribution-NonCommercial-NoDerivatives" 
//BASE_OPTION_REFINERS.resourceAreaWidth='80px'
const InitialConfig = MaterialConfig;//BasicConfig;

//const deepEqual = require('deep-equal')

moment.locale('ru');

const defaultDialog = {name:"", type: "standup", questions:[], goodbye_message:"", welcome_message:"", empty_message:""}

const mapState = {
  center: [55.751574, 37.573856],
  zoom: 9,
  behaviors: ["default", "scrollZoom"]
};

/**
 * Функция для сортировки элементов, которые расположены в объекте в виде _id: значнеие
 * @param {} keys 
 * @returns 
 */
function itemsKeySort (items, keys) {

  keys = keys || {index:"asc"};
  
  // via
  // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
  var obLen = function(obj) {
      var size = 0, key;
      for (key in obj) {
          if (obj.hasOwnProperty(key))
              size++;
      }
      return size;
  };
  
  // avoiding using Object.keys because I guess did it have IE8 issues?
  // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
  // whatever
  var obIx = function(obj, ix) {
      var size = 0, key;
      for (key in obj) {
          if (obj.hasOwnProperty(key)) {
              if (size == ix)
                  return key;
              size++;
          }
      }
      return false;
  };
  
  var keySort = function(a, b, d) {
      d = d !== null ? d : 1;
      // a = a.toLowerCase(); // this breaks numbers
      // b = b.toLowerCase();
      if (a == b)
          return 0;
      return a > b ? 1 * d : -1 * d;
  };
  
  var KL = obLen(keys);
  
  if (!KL)
      return this.sort(keySort);
  
  for ( var k in keys) {
      // asc unless desc or skip
      keys[k] = 
              keys[k] == 'desc' || keys[k] == -1  ? -1 
            : (keys[k] == 'skip' || keys[k] === 0 ? 0 
            : 1);
  }
  
  this.sort(function(a, b) {
      var sorted = 0, ix = 0;
  
      while (sorted === 0 && ix < KL) {
          var k = obIx(keys, ix);
          if (k) {
              var dir = keys[k];
              sorted = keySort(a[k], b[k], dir);
              ix++;
          }
      }
      return sorted;
  });
  return items;
  };

function getItemsAsArray (cols, items){
  console.log('getItemsAsArray ', cols, items)
  var __array = []
  var colsIdx = {}
  var newRow = []
  for(var ic=0; ic<cols.length; ic++){    
    var col = cols[ic]
    colsIdx[col._id] = ic
    newRow.push(col.name)
  }
  __array.push(newRow)
  
  for(var itemId in items){
    var item = items[itemId]
    let _itemRow = []
    for(var colId in colsIdx){
      let v = item[colId]
      if( typeof v ==='object'  ){
        v = ''
      }
      _itemRow[colsIdx[colId]] = v
    }
    __array.push(_itemRow)
  }
  console.log('getItemsAsArray __array ', __array)

  return __array
}

function getItemsForGChart (cols, items, members, tablesValues){
  console.log('getItemsAsArray ', cols, items)
  var res = {
    cols:[],
    rows:[]
  }
  var colsIdx = {}
  var newRow = []
  for(var ic=0; ic<cols.length; ic++){    
    var cl = cols[ic]        
    colsIdx[cl._id] = ic
    var _type = 'string'
    if(cl.type==="number"){
      _type = "number"
    } else if(cl.type==="date"){
      _type = "date"
    } else if(cl.type==="check"){
      _type = "boolean"
    }
    res.cols.push({
      type: _type,
      __type: cl.type,
      __lookupId: cl.lookupId,
      __lookupField: cl.lookupField,
      __linkId: cl.linkId,
      __linkViewField: cl.linkViewField,
      label: cl.name,

      //role?: GoogleDataTableColumnRoleType;
      //pattern?: string;
      //p?: {};
      id: cl._id
    })
  }
  
  for(var itemId in items){
    var item = items[itemId]
    let _itemRow = []
    for(var colId in colsIdx){
      let v = item[colId]
      if(colsIdx[colId].__type==="member"){
        if(v && members){
          var _mem = members.find(r => r._id === v)
          if(_mem){
            v = _mem.name
          }                
        }              
      } else if(colsIdx[colId].__type==="lookup"){
        if(colsIdx[colId].__lookupId && colsIdx[colId].__lookupField){
          const lookupColumn = cols.find(cl=> cl._id === colsIdx[colId].__lookupId)
          const lookupField = colsIdx[colId].__lookupField
          if(lookupColumn && lookupColumn.linkId && lookupField){
            
              if(tablesValues[lookupColumn.linkId] && item[lookupColumn._id]){
                let lookupitem = tablesValues[lookupColumn.linkId].items[item[lookupColumn._id]]
                //if(item[lookupField]){
                // val = item[lookupField]
                //}
                if(lookupitem && lookupitem[lookupField]){
                  var fieldCol = tablesValues[lookupColumn.linkId].columns.find(cl=> cl._id===lookupField)
                  v = lookupitem[lookupField]
                  if(fieldCol && fieldCol.type==='member'){
                    
                    var member = members.find(mb=> mb._id==lookupitem[lookupField])
                    //console.log('fieldCol member ', fieldCol, item[lookupField], props.members, member)
                    if(member){
                      v = member.name
                    }
                     
                  }
                  
                }                  
              }           
        } else {
          v = ""
        }
      } else {
        v = ""
      }                               
      } else if(colsIdx[colId].__type==="link"){
        if(tablesValues[colsIdx[colId].__linkId] && tablesValues[colsIdx[colId].__linkId].columns){
          var titleCol = colsIdx[colId].__linkId.__linkViewField 
          if(titleCol){
            var option = v?tablesValues[colsIdx[colId].__linkId].items[v]:null;
            v = option?option[titleCol]:""
          } else {
            v=""
          }
        } else {
          v=""
        }
      } else if(colsIdx[colId].__type==="number"){

      } else if(colsIdx[colId].__type==="geolocation"){
        v = v?`${v.longitude}, ${v.latitude}`:""
      } else if(colsIdx[colId].__type==="attachments"){
        v = ""
      } else if(colsIdx[colId].__type==="check"){
        //if(ws['!cols'][ic].check_true){
        //  v=v?ws['!cols'][ic].check_true:ws['!cols'][ic].check_false
        //  t = 's';
        //} else {
        //  t= 'b'
        //}              
      } else if(colsIdx[colId].__type==="date"){
        if (v) {
          if (v instanceof Date) {

          } else {
            v = new Date(v);
          }
        } else {
          v = ""
        }
      } else if(colsIdx[colId].__type==="multiline"){

      } else {

      }      

      _itemRow[colsIdx[colId]] = {v: v}

    }
    res.rows.push({c:_itemRow})
  }
  console.log('getItemsAsArray res ', res)

  return res
}

function geDataSourceForPlotlyEditor (cols, items, members, tablesValues){
  console.log('getItemsAsArray ', cols, items)
  var res = {
  }

  var newRow = []
  for(var ic=0; ic<cols.length; ic++){    
    var cl = cols[ic]    
    res[cl.name] = []
    var colId = cl._id
    for(var itemId in items){
      var item = items[itemId]
      let _itemRow = []
        let v = item[colId]
        if(cl.type==="member"){
          if(v && members){
            var _mem = members.find(r => r._id === v)
            if(_mem){
              v = _mem.name
            }                
          }              
        } else if(cl.type==="lookup"){
          if(cl.lookupId && cl.lookupField){
            const lookupColumn = cols.find(cl=> cl._id === cl.lookupId)
            const lookupField = cl.lookupField
            if(lookupColumn && lookupColumn.linkId && lookupField){
              
                if(tablesValues[lookupColumn.linkId] && item[lookupColumn._id]){
                  let lookupitem = tablesValues[lookupColumn.linkId].items[item[lookupColumn._id]]
                  //if(item[lookupField]){
                  // val = item[lookupField]
                  //}
                  if(lookupitem && lookupitem[lookupField]){
                    var fieldCol = tablesValues[lookupColumn.linkId].columns.find(cl=> cl._id===lookupField)
                    v = lookupitem[lookupField]
                    if(fieldCol && fieldCol.type==='member'){
                      
                      var member = members.find(mb=> mb._id==lookupitem[lookupField])
                      //console.log('fieldCol member ', fieldCol, item[lookupField], props.members, member)
                      if(member){
                        v = member.name
                      }
                       
                    }
                    
                  }                  
                }           
          } else {
            v = ""
          }
        } else {
          v = ""
        }                               
        } else if(cl.type==="link"){
          if(tablesValues[cl.linkId] && tablesValues[cl.linkId].columns){
            var titleCol = cl.linkId.__linkViewField 
            if(titleCol){
              var option = v?tablesValues[cl.linkId].items[v]:null;
              v = option?option[titleCol]:""
            } else {
              v=""
            }
          } else {
            v=""
          }
        } else if(cl.type==="number"){
  
        } else if(cl.type==="geolocation"){
          v = v?`${v.longitude}, ${v.latitude}`:""
        } else if(cl.type==="attachments"){
          v = ""
        } else if(cl.type==="check"){
          //if(ws['!cols'][ic].check_true){
          //  v=v?ws['!cols'][ic].check_true:ws['!cols'][ic].check_false
          //  t = 's';
          //} else {
          //  t= 'b'
          //}              
        } else if(cl.type==="date"){
          if (v) {
            if (v instanceof Date) {
  
            } else {
              v = new Date(v);
            }
          } else {
            v = ""
          }
        } else if(cl.type==="multiline"){
  
        } else {
  
        }      
        res[cl.name].push(v)
      }
      //res.rows.push({c:_itemRow})
    }

  console.log('getItemsAsArray res ', res)

  return res
}


const drawerWidth = 240;
//const socket = io();

//console.log("socket.connected", socket.connected); // false

//socket.on('connect', () => {
//  console.log("socket.connected 2", socket.connected); // true
//});

//socket.on('disconnect', () => {
//  console.log("socket disconnect", socket.connected); // false
//});

function ExpandIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
    </SvgIcon>
  );
}

function CollapseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19.5,3.09L15,7.59V4H13V11H20V9H16.41L20.91,4.5L19.5,3.09M4,13V15H7.59L3.09,19.5L4.5,20.91L9,16.41V20H11V13H4Z" />
    </SvgIcon>
  );
}

function RobotBrainIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 4H18V3H20.5C20.78 3 21 3.22 21 3.5V5.5C21 5.78 20.78 6 20.5 6H20V7H19V5H20V4M19 9H20V8H19V9M17 3H16V7H17V3M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C14.34 7 14.67 7.03 15 7.08V10H19.74C20.53 11.13 21 12.5 21 14H22C22.55 14 23 14.45 23 15M10 15.5C10 14.12 8.88 13 7.5 13S5 14.12 5 15.5 6.12 18 7.5 18 10 16.88 10 15.5M19 15.5C19 14.12 17.88 13 16.5 13S14 14.12 14 15.5 15.12 18 16.5 18 19 16.88 19 15.5M17 8H16V9H17V8Z" />
    </SvgIcon>
  );
}


function DiscordIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z" />
    </SvgIcon>
  );
}

function SlackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" />
    </SvgIcon>
  );
}



function MsTeamsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19.19 8.77q-.46 0-.86-.17q-.39-.17-.69-.47q-.3-.3-.47-.69q-.17-.4-.17-.86q0-.45.17-.85q.17-.4.47-.69q.3-.3.69-.47q.4-.18.86-.17q.45-.01.85.17q.4.17.7.47q.29.29.47.69q.17.4.17.85q0 .46-.17.86q-.17.39-.47.69q-.3.3-.7.47q-.4.17-.85.17m0-3.12q-.39 0-.69.27q-.25.27-.25.66t.25.67q.3.25.69.25q.39 0 .66-.25q.28-.25.28-.67q0-.39-.28-.66q-.27-.27-.66-.27M22 10.33V15q0 .63-.24 1.2q-.26.57-.67.99q-.43.43-1 .67q-.59.25-1.21.25q-.38 0-.76-.11q-.39-.07-.71-.25q-.24.79-.71 1.44q-.47.65-1.1 1.11q-.63.46-1.39.7q-.76.27-1.58.27q-.96 0-1.81-.33q-.82-.33-1.5-.94q-.66-.57-1.09-1.36q-.44-.8-.57-1.74H2.83q-.33 0-.59-.25q-.24-.24-.24-.58V7.73q0-.34.24-.59q.26-.24.59-.24H10q-.29-.6-.29-1.25q0-.61.23-1.15q.22-.5.62-.92q.4-.39.94-.62q.5-.23 1.12-.23q.61 0 1.14.23q.53.23.93.62q.4.42.62.92q.23.54.23 1.15q0 .6-.23 1.14q-.22.53-.62.92q-.4.4-.93.63q-.53.23-1.14.23q-.15 0-.31-.02q-.15-.02-.31-.05v.9h9.06q.39 0 .67.27q.27.27.27.66M12.63 4q-.35 0-.63.11q-.33.13-.56.36q-.22.23-.35.53q-.13.31-.13.65q0 .35.13.65q.13.3.35.53q.23.22.56.36q.28.13.63.13q.34 0 .64-.13q.3-.14.53-.36q.23-.23.36-.53q.14-.3.14-.65q0-.34-.14-.65q-.13-.3-.36-.53q-.23-.23-.53-.36q-.3-.11-.64-.11m-4.85 6.18h1.88V8.62H4.34v1.56h1.88v5h1.56m8.6 1.09v-5.62H12v5.42q0 .34-.24.58q-.26.25-.59.25H8.92q.13.67.47 1.25q.34.57.82.99q.48.41 1.1.65q.61.21 1.32.21q.77 0 1.45-.27q.68-.3 1.2-.81q.51-.51.8-1.19q.3-.68.3-1.46M20.75 15v-4.35h-3.12v5.71q.25.25.57.38q.3.12.68.12q.39 0 .73-.15q.34-.15.59-.4q.26-.25.4-.6q.15-.34.15-.71z" />
    </SvgIcon>
  );
}

function ChatMemberIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
    </SvgIcon>
  );
}

function LookupIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
    </SvgIcon>
  );
}  



function RobotIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,2A2,2 0 0,1 14,4C14,4.74 13.6,5.39 13,5.73V7H14A7,7 0 0,1 21,14H22A1,1 0 0,1 23,15V18A1,1 0 0,1 22,19H21V20A2,2 0 0,1 19,22H5A2,2 0 0,1 3,20V19H2A1,1 0 0,1 1,18V15A1,1 0 0,1 2,14H3A7,7 0 0,1 10,7H11V5.73C10.4,5.39 10,4.74 10,4A2,2 0 0,1 12,2M7.5,13A2.5,2.5 0 0,0 5,15.5A2.5,2.5 0 0,0 7.5,18A2.5,2.5 0 0,0 10,15.5A2.5,2.5 0 0,0 7.5,13M16.5,13A2.5,2.5 0 0,0 14,15.5A2.5,2.5 0 0,0 16.5,18A2.5,2.5 0 0,0 19,15.5A2.5,2.5 0 0,0 16.5,13Z" />
    </SvgIcon>
  );
}

function AddColumnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M11,2A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H2V2H11M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M15,11H18V8H20V11H23V13H20V16H18V13H15V11Z" />
    </SvgIcon>
  );
}

function ConfigColumnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M16,5V18H21V5M4,18H9V5H4M10,18H15V5H10V18Z" />
    </SvgIcon>
  );
}


function RowHightIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3,5H15A2,2 0 0,1 17,7V17A2,2 0 0,1 15,19H3A2,2 0 0,1 1,17V7A2,2 0 0,1 3,5M3,9V12H8V9H3M10,9V12H15V9H10M3,14V17H8V14H3M10,14V17H15V14H10M23,14V7H19V9H21V12H19V14H23Z" />
    </SvgIcon>
  );
}

function TimelineIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M2,2H4V20H22V22H2V2M7,10H17V13H7V10M11,15H21V18H11V15M6,4H22V8H20V6H8V8H6V4Z" />
    </SvgIcon>
  );
}


function FormIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15 5H18L16.5 7L15 5M5 2H19C20.11 2 21 2.9 21 4V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V4C3 2.9 3.9 2 5 2M5 4V8H19V4H5M5 20H19V10H5V20M7 12H17V14H7V12M7 16H17V18H7V16Z" />
    </SvgIcon>
  );
}

function ExcelIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z" />
    </SvgIcon>
  );
}

function ExternalContentIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </SvgIcon>
  );
}

function AddItemFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M11,6V9H8V11H11V14H13V11H16V9H13V6H11Z" />
    </SvgIcon>
  );
}

function UpdateItemsFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M8,14H6V12H8V14M8,11H6V9H8V11M8,8H6V6H8V8M15,14H10V12H15V14M18,11H10V9H18V11M18,8H10V6H18V8Z" />
    </SvgIcon>
  );
}

function UpdateItemFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.11 18 22 17.11 22 16V4C22 2.89 21.1 2 20 2M10.47 14L7 10.5L8.4 9.09L10.47 11.17L15.6 6L17 7.41L10.47 14Z" />
    </SvgIcon>
  );
}

function SendItemstoChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />
    </SvgIcon>
  );
}


function SendMsgtoChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3 7C2.4 7 2 7.4 2 8S2.4 9 3 9H5V7H3M2 11C1.4 11 1 11.4 1 12S1.4 13 2 13H5V11H2M1 15C.4 15 0 15.4 0 16C0 16.6 .4 17 1 17H5V15H1M20 5H9C7.9 5 7 5.9 7 7V21L11 17H20C21.1 17 22 16.1 22 15V7C22 5.9 21.1 5 20 5Z" />
    </SvgIcon>
  );
}

function PollInChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M13 14H11V12H13V14M14.8 9C14.5 9.4 14.1 9.6 13.7 9.8C13.4 10 13.3 10.1 13.2 10.3C13 10.5 13 10.7 13 11H11C11 10.5 11.1 10.2 11.3 9.9C11.5 9.7 11.9 9.4 12.4 9.1C12.7 9 12.9 8.8 13 8.6C13.1 8.4 13.2 8.1 13.2 7.9C13.2 7.6 13.1 7.4 12.9 7.2C12.7 7 12.4 6.9 12.1 6.9C11.8 6.9 11.6 7 11.4 7.1C11.2 7.2 11.1 7.4 11.1 7.7H9.1C9.2 7 9.5 6.4 10 6C10.5 5.6 11.2 5.5 12.1 5.5C13 5.5 13.8 5.7 14.3 6.1C14.8 6.5 15.1 7.1 15.1 7.8C15.2 8.2 15.1 8.6 14.8 9Z" />
    </SvgIcon>
  );
}



function ColumnTypeIcon({ type }) {

  return (
    <ListItemIcon>
      {type === "text" ? <TextFieldsIcon fontSize="small" /> :
        type === "multiline" ? <SubjectIcon fontSize="small" /> :
          type === "title" ? <TextFieldsIcon fontSize="small" /> :
            type === "number" ? <Looks3Icon fontSize="small" /> :
              type === "date" ? <EventIcon fontSize="small" /> :
                type === "select" ? <FilterListIcon fontSize="small" /> :
                  type === "member" ? <PersonIcon fontSize="small" /> :
                    type === "link" ? <LinkIcon fontSize="small" /> :
                      type === "geolocation" ? <LocationOnIcon fontSize="small" /> :
                        type === "attachments" ? <AttachFileIcon fontSize="small" /> :
                          type === "check" ? <CheckBoxIcon fontSize="small" /> :
                            type === "lookup" ? <LookupIcon fontSize="small" /> : <></>
      }
    </ListItemIcon>
  );
}




  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerOpen: {
      width: drawerWidth,
      visibility: 'visible',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 0, //theme.spacing(7) + 1,
      visibility: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: 0,//theme.spacing(9) + 1,
      },
    },    
    drawerContainer: {
      overflow: 'auto',
    },

    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      //padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0//-drawerWidth,
    },
    contentShift: {
      height: '100vh',
      overflow: 'auto',      
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },    
    menuButton: {
      marginRight: theme.spacing(2),
    },    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    paper_send_invite: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: purple[50]
    },    

    paper_invite_links: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
    },       
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },

    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    fabButton:  {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 99
    },
    fileinput: {
      display: 'none',
    },    
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    settingsPaper: {
      //marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)      
    },
    dialogCloseButton:{
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    nonSelectedListItemSecondaryAction:{
      visibility: "hidden",
      //cursor:"move"
    },
    selectedListItemSecondaryAction:{
      
    },    
    nonSelectedListItem: {
      "&:hover $nonSelectedListItemSecondaryAction": {
        visibility: "inherit"
      }
    },
    viewButton: {
      marginRight: "0.5rem"
    },
    filterButton: {
      marginRight: "0.5rem",
        backgroundColor: "#d1f7c4",
        '&:hover': {
          backgroundColor: "#d1f7c4",
          opacity: 0.8
            
        }
    },
    dialogsButton: {
      marginRight: "0.5rem",
      backgroundColor: "rgb(159, 246, 227)",
      '&:hover': {
        backgroundColor: "rgb(159, 246, 227)",
        opacity: 0.8
          
      }
  },
    viewsButton: {
      marginRight: "0.5rem",
      backgroundColor: "rgba(0,0,0,0.05)",
      '&:hover': {
        backgroundColor: "rgba(0,0,0,0.05)",
        opacity: 0.8
          
      }
  },
    groupButton: {
      marginRight: "0.5rem",
      backgroundColor: "#ede2fe",
      '&:hover': {
        backgroundColor: "#ede2fe",
        opacity: 0.8
          
      }
  },
  sortButton: {
    marginRight: "0.5rem",
    backgroundColor: "#fee2d5",
    '&:hover': {
      backgroundColor: "#fee2d5",
      opacity: 0.8
        
    }
  },

    
  }));

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }  



  function IconForViewList (props){
    return (<ViewListIcon fontSize="small" {...props} color="primary"/>)
  }

  function IconForViewKanban (props){
    return (<TableChartIcon fontSize="small" {...props} style={{ color: green[500] }}/>)
  }
  
  function IconForViewCalendar (props){
    return (<EventIcon fontSize="small" {...props}style={{ color: orange[700] }}/>)
  }

  function IconForViewTimeline (props){
    return (<TimelineIcon fontSize="small" {...props}style={{ color: red[700] }}/>)
  }  

  function IconForViewPage (props){
    return (<DescriptionIcon fontSize="small" {...props}style={{ color: blueGrey[700] }}/>)
  }  


  function IconForViewForm (props){
    return (<FormIcon fontSize="small" {...props}style={{ color: orange[700] }}/>)
  }    

  function IconForViewDashboard (props){
    return (<DashboardIcon fontSize="small" {...props}style={{ color: pink[700] }}/>)
  }    
  
  function IconForViewBot (props){
    return (<QuestionAnswerIcon fontSize="small" {...props} color="secondary"/>)
  }  

  function IconForExternalContent (props){
    return (<ExternalContentIcon fontSize="small" {...props} style={{color: "rgb(33, 150, 243)"}}/>)
  }  

  function IconForMap (props){
    return (<MapIcon fontSize="small" {...props} style={{color: "rgb(33, 150, 243)"}}/>)
  }  


  const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
      minHeight: 24,
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      minHeight: 24,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);  

  function contentEditable() {

    return class extends React.Component {
  
      state = {
        editing: false
      }
  
      toggleEdit = (e) => {
        e.stopPropagation();
        if (this.state.editing) {
          this.cancel();
        } else {
          this.edit();
        }
      };
  
      edit = () => {
        this.setState({
          editing: true
        }, () => {
          this.domElm.focus();
        });
      };
  
      save = () => {
        this.setState({
          editing: false
        }, () => {
          if (this.props.onSave && this.isValueChanged()) {
            //console.log('Value is changed', this.domElm.textContent);
            this.props.onSave(this.domElm.textContent);
          }
        });
      };
  
      cancel = () => {
        this.setState({
          editing: false
        });
      };
  
      isValueChanged = () => {
        return this.props.value !== this.domElm.textContent
      };
  
      handleKeyDown = (e) => {
        const { key } = e;
        switch (key) {
          case 'Enter':
          case 'Escape':
            this.save();
            break;
        }
      };
  
      render() {
        let editOnClick = true;
        const {editing} = this.state;
        if (this.props.editOnClick !== undefined) {
          editOnClick = this.props.editOnClick;
        }
        return (
          
            <Tooltip title={'Нажмите, чтобы отредактировать'}>
          <span
            className={editing ? 'editing' : ''}
            onClick={editOnClick ? this.toggleEdit : undefined}
            contentEditable={editing}
            
            ref={(domNode) => {
              this.domElm = domNode;
            }}
            onBlur={this.save}
            onKeyDown={this.handleKeyDown}
            {...this.props}
        >
          {this.props.value}
          </span></Tooltip>
        
        )
      }
    }
  }

  function MyMapComponent({
    center,
    zoom,
    height,
    mapItems
  }) {
    const ref = useRef();
  
    useEffect(() => {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,        
      });
      if (mapItems) {
        mapItems.map( item =>{
            const marker = new window.google.maps.Marker({
          position: item.position,
          map: map,
        }); 
        } 
      )
}
    });
  
    return <div style={{height:height}} ref={ref} id="map" />;
  }

  function AddViewDialog(props) {
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('list');
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(null);
    const [kanbanColumnsOptions, setKanbanColumnsOptions] = useState({});
    const [calendarColumnsOptions, setCalendarColumnsOptions] = useState({});
    const [timelineColumnsOptions, setTimelineColumnsOptions] = useState({});
    const [ganttColumnsOptions, setGanttColumnsOptions] = useState({});
    const [externalContentOptions, setExternalContentOptions] = useState({});
    const [mapOptions, setMapOptions] = useState({});    
    const [groupBy, setGroupBy] = useState(null);    
    const [titleColumns, setTitleColumns] = useState([]);
    const [dateColumns, setDateColumns] = useState([]);
    const [colorColumns, setColorColumns] = useState([]);  
    const [resourceColumns, setResourceColumns] = useState([]);  
    const [locationColumns, setLocationColumns] = useState([]); 
    const [dialogSettings, setDialogSettings] = useState({});   
    const [permissionType, setPermissionType] = useState("c"); 
    const history = useHistory();
    const classes = useStyles();

    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onChangeExternalLink = (event) => {
      const val = event.target.value
      setExternalContentOptions(cur => { var n = {...cur}; n["link"] = val; return n} )
    };

    const onChangeTimelineResourceColumn = (event) => {
      const val = event.target.value
      setTimelineColumnsOptions(cur => { var n = {...cur}; n["resourceColumn"] = val; return n} )
    };    

    const onChangeMapLocation = (event) => {
      const val = event.target.value
      setMapOptions(cur => { var n = {...cur}; n["location"] = val; return n} )
    };

    const onChangeMapTitleColumn = (event) => {
      const val = event.target.value
      setMapOptions(cur => { var n = {...cur}; n["titleColumn"] = val; return n} )
    }; 
    
    const onChangeMapColorColumn = (event) => {
      const val = event.target.value
      setMapOptions(cur => { var n = {...cur}; n["colorColumn"] = val; return n} )
    };        

    const onChangeTimelineTitleColumn = (event) => {
      const val = event.target.value
      setTimelineColumnsOptions(cur => { var n = {...cur}; n["titleColumn"] = val; return n} )
    };

    const onChangeTimelineStartColumn = (event) => {
      const val = event.target.value
      setTimelineColumnsOptions(cur => { var n = {...cur}; n['startColumn'] = val; return n} )

    };

    const onChangeTimelineEndColumn = (event) => {
      const val = event.target.value
      setTimelineColumnsOptions(cur => { var n = {...cur}; n["endColumn"] = val; return n} )

    };

    const onChangeTimelineColorColumn = (event) => {
      const val = event.target.value
      setTimelineColumnsOptions(cur => { var n = {...cur}; n["colorColumn"] = val; return n} )

    };    
    
    const onChangeCalendarTitleColumn = (event) => {
      const val = event.target.value
      setCalendarColumnsOptions(cur => { var n = {...cur}; n["titleColumn"] = val; return n} )
    };

    const onChangeStartColumn = (event) => {
      const val = event.target.value
      setCalendarColumnsOptions(cur => { var n = {...cur}; n['startColumn'] = val; return n} )

    };

    const onChangeEndColumn = (event) => {
      const val = event.target.value
      setCalendarColumnsOptions(cur => { var n = {...cur}; n["endColumn"] = val; return n} )

    };

    const onChangeColorColumn = (event) => {
      const val = event.target.value
      setCalendarColumnsOptions(cur => { var n = {...cur}; n["colorColumn"] = val; return n} )

    };
    const onChangeTitleColumn = (event) => {
      const val = event.target.value
      setKanbanColumnsOptions(cur=>  { var n = {...cur}; n["titleColumn"] = val; return n} )     
   };
 
   const onChangeDescriptionColumn = (event) => {
    const val = event.target.value
    setKanbanColumnsOptions(cur=> { var n = {...cur}; n['descriptionColumn'] = val; return n} )
        
  };
 
  const onChangeLabelColumn = (event) => {
    const val = event.target.value
    setKanbanColumnsOptions(cur=> { var n = {...cur}; n["labelColumn"] = val; return n} )
    
  };  

  const onChangeGroupByColumn = (event) => {
    const val = event.target.value
    setGroupBy(val)    
  };    

  const onChangeDialogHandler = (event) => {
    setDialogSettings({...dialogSettings, [event.target.name]:event.target.value})     
 };

 const onChangeDialogOnExternalItems = (event) => {
   const v = event.target.checked;    
   setDialogSettings({...dialogSettings, [event.target.name]:v})         
   //setDialogEdited(true);       
}      
  

    const handleClickOpen = () => {
      setOpen(true);
      setType('list')
      setTitle("")
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCreate = (event) => {
      if(!title || title.trim().length<3){
        setTitleError('Название меньше 3 симоволов!')
        return
      }
      var newView = {
        title: title,
        work_item_list: props.tableId,
        viewtype: type,
        filter: {},
        sort: {},
        groupBy: '',
        kanbanColumnsOptions: {},
        calendarColumnsOptions: {},
        listColumnsOptions: {},
        timelineColumnsOptions:{},
        pageContent:{},
        permissionType: permissionType
      }
      if(type==='list'){
        var listColumnsOptions = {};
        for(let ic=0; ic<props.columns.length; ic++){
            var col = props.columns[ic]
            var width =  150;
            if(col._id==="ex_rAnswered" ||
                col._id==="ex_requestDt" ||
                col._id==="ex_rAnswerDt" ||
                col._id==="ex_updatedAt" ||
                col._id==="ex_createdAt" ||
                col._id==="ex_changeAutor" ||
                col._id==="ex_createdAutor" ||
                col._id==="ex_requestAc" ||
                col._id==="_id"
            )  {
                width = 0;
            }
            console.log('newView col._id', col._id)
            listColumnsOptions[col._id] = {
                index: ic,
                frozen:false,
                width:width                        
            }
            console.log('newView listColumnsOptions', listColumnsOptions)
        }        
        newView.listColumnsOptions = listColumnsOptions
      } else if(type==='kanban'){
        newView.kanbanColumnsOptions = kanbanColumnsOptions//TODO надо искать по типу, когда переделаю
        newView.groupBy  =  groupBy //     
      } else if(type==='calendar'){
        newView.calendarColumnsOptions = calendarColumnsOptions//TODO надо искать по типу, когда переделаю      
      } else if(type==='timeline'){
        newView.timelineColumnsOptions = timelineColumnsOptions//TODO надо искать по типу, когда переделаю      
      } else if(type==='gantt'){
        newView.ganttColumnsOptions = ganttColumnsOptions//TODO надо искать по типу, когда переделаю      
      } else if(type==='external'){
        newView.externalContentOptions = externalContentOptions
      } else if(type==='map'){
        newView.mapOptions = mapOptions        
      } else if(type==='dialog'){
        newView.dialogSettings = dialogSettings
      }
      props.handleAddView(newView, (newViewId)=>{
        history.push(`/db/${props.dbId}/${newView.work_item_list}/${newViewId}`)
      })
      handleClose();
    };      

    useEffect(()=>{  
      const _titleColumns = []
      const _dateColumns = []
      const _colorColumns = []
      const _resourceColumns=[]
      const _locationColumns=[]
      if(props.columns){        
        for(let ic=0; ic<props.columns.length; ic++){
          let col = props.columns[ic]
          if(col.type==='string' 
          || col.type==='text' 
          || col.type==='title' 
          || col.type==='multiline'
          || col.type==='member'
          || col.type==='select'
          || col.type==='number'
          ){
            _titleColumns.push(col)
          }  
          if(col.type==='date' || col.type==='datetime' || col.type==='due'){
            _dateColumns.push(col)
          } 
          if(col.type==='select'){
            _colorColumns.push(col)
          } 
          if(col.type==='link' || col.type==='member' || col.type==='select'){
            _resourceColumns.push(col)
          } 
          if(col.type==='geolocation'){
            _locationColumns.push(col)
          }
          
        }  
    
        setTitleColumns(_titleColumns)
        setDateColumns(_dateColumns)
        setColorColumns(_colorColumns)  
        setResourceColumns(_resourceColumns)
        setLocationColumns(_locationColumns)
      } 
    
    }, [props.columns])    

    return (
      <>
                      <List dense={true} 
                      style={{
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        paddingTop: "0px",
                        paddingBottom: "0px"                    
                        //maxHeight: "150px",
                      }}
                      >
                        <ListItem disableGutters button onClick={handleClickOpen}>
                          <ListItemIcon 
                      style={{
                        minWidth:"30px",
                      }}           
                                    
                          >
                            <AddCircleOutlineIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Добавить Представление" />
                        </ListItem>
                        </List>          
        <Dialog
          fullWidth={true} 
          maxWidth="md"
          //fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="add_view-dialog-title"
        >
          <DialogTitle id="add_view-dialog-title">Добавить представление
          <Toolbar position="static" color="default">
        <Tabs
                value={type}
                onChange={(event, newValue) => {
                  setType(newValue);
                }}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label='Таблица' value="list" icon={<IconForViewList fontSize="default"/>} {...a11yProps(0)} />
          <Tab label="Канбан" value="kanban" icon={<IconForViewKanban fontSize="default"/>} {...a11yProps(1)} />
          <Tab label="Календарь" value="calendar" icon={<IconForViewCalendar fontSize="default"/>} {...a11yProps(2)}/>          
          <Tab label="Timeline" value="timeline" icon={<IconForViewTimeline fontSize="default"/>} {...a11yProps(2)}/>
          {false && <Tab label="Страница" value="page" icon={<IconForViewPage fontSize="default"/>} {...a11yProps(2)}/> }
          {false && <Tab label="Форма" value="form" icon={<IconForViewForm fontSize="default"/>} {...a11yProps(2)}/>          }
          {false && <Tab label="Диалог" value="dialog" icon={<IconForViewBot fontSize="default"/>} {...a11yProps(3)}/> }
          <Tab label="Dashboard" value="dashboard" icon={<IconForViewDashboard fontSize="default"/>} {...a11yProps(2)}/>          
          <Tab label="Карта" value="map" icon={<IconForMap fontSize="default"/>} {...a11yProps(2)}/>          
          {false && <Tab label="Gantt" value="gantt" icon={<IconForViewTimeline fontSize="default"/>} {...a11yProps(2)}/>      }
          <Tab label="Внешний контент" value="external" icon={<IconForExternalContent fontSize="default"/>} {...a11yProps(2)}/>          
          
        </Tabs>
      </Toolbar>             
          </DialogTitle>
          <DialogContent>
         
            <DialogContentText>   
              <FormControl component="fieldset">               
                <RadioGroup row aria-label="position" name="position" defaultValue="c" onChange={(event) => {
                  setPermissionType(event.target.value);
                }}>
                  <FormControlLabel
                    value="c"
                    control={<Radio color="primary" />}
                    label="Совместный доступ"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="p"
                    control={<Radio color="primary" />}
                    label="Персональный"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="l"
                    control={<Radio color="primary" />}
                    label="Заблокированный"
                    labelPlacement="end"
                  />
                </RadioGroup>
                <FormHelperText id="my-helper-text">
                  {permissionType==="p"?"Только вы сможете изменять настройки этого представления":
                    permissionType==="l"?"Только администратор сможет изменять настройки этого представления":
                    "Все редакторы смогут изменять настройки этого представления"
                  }
                  </FormHelperText>
              </FormControl>                                                
            <TextField
                          id="add_view_title"
                          name="view_title"
                          //label="Название вида"
                          error={Boolean(titleError)}
                          helperText={titleError}
                          value={title}
                          onChange={(e)=>{setTitle(e.target.value); setTitleError(null)}}
                          placeholder="Введите название представления"
                          //helperText=""                            
                          margin="normal"
                          fullWidth
                          
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {type==="calendar" && <div> 
              <TextField
                        id="calendar_title_column"
                        label="Название"
                        value={calendarColumnsOptions?calendarColumnsOptions.titleColumn:''}
                        onChange={onChangeCalendarTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="calendar_start_column"
                        label="Дата начала"
                        value={calendarColumnsOptions?calendarColumnsOptions.startColumn:''}
                        onChange={onChangeStartColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                    <TextField
                        id="calendar_and_column"
                        label="Дата окончания"
                        value={calendarColumnsOptions?calendarColumnsOptions.endColumn:''}
                        onChange={onChangeEndColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>    
                    
                    <TextField
                        id="calendar_color_column"
                        label="Цвет"
                        value={calendarColumnsOptions?calendarColumnsOptions.colorColumn:''}
                        onChange={onChangeColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                  
                </div>}
                {type==="timeline" && <div> 
                <TextField
                        id="new_timeline_Resource_column"
                        label="Ресурсы"
                        value={timelineColumnsOptions?timelineColumnsOptions.resourceColumn:''}
                        onChange={onChangeTimelineResourceColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {resourceColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                     
              <TextField
                        id="new_timeline_title_column"
                        label="Название"
                        value={timelineColumnsOptions?timelineColumnsOptions.titleColumn:''}
                        onChange={onChangeTimelineTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="new_timeline_start_column"
                        label="Дата начала"
                        value={timelineColumnsOptions?timelineColumnsOptions.startColumn:''}
                        onChange={onChangeTimelineStartColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                    <TextField
                        id="new_timeline_and_column"
                        label="Дата окончания"
                        value={timelineColumnsOptions?timelineColumnsOptions.endColumn:''}
                        onChange={onChangeTimelineEndColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>    
                    
                    <TextField
                        id="new_timeline_color_column"
                        label="Цвет"
                        value={timelineColumnsOptions?timelineColumnsOptions.colorColumn:''}
                        onChange={onChangeTimelineColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                  
                </div>}
        {type==="kanban" && <div> 
        <TextField
                        id="kanban_groupBy_column"
                        label="Группировать по"
                        value={groupBy}
                        onChange={onChangeGroupByColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
          {props.columns && props.columns.map((column, idx)=>((column.type==='select' || column.type==='member' || column.type==='chatmember') &&
            <MenuItem key={column._id} value={column._id} >{column.name}</MenuItem>
          ))}
                    </TextField>          
          <TextField
                        id="kanban_title_column"
                        label="Название"
                        value={kanbanColumnsOptions?kanbanColumnsOptions.titleColumn:''}
                        onChange={onChangeTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="kanban_description_column"
                        label="Описание"
                        value={kanbanColumnsOptions?kanbanColumnsOptions.descriptionColumn:''}
                        onChange={onChangeDescriptionColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                    <TextField
                        id="kanban_label_column"
                        label="Лейбел"
                        value={kanbanColumnsOptions?kanbanColumnsOptions.labelColumn:''}
                        onChange={onChangeLabelColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> </div>}
                    {type==="dashboard" && <div> 
                                              
                </div>}                  
                {type==="external" && <div> 
              <TextField
                        id="external_link"
                        label="Ссылка на внешний контент"
                        value={externalContentOptions ?externalContentOptions.link:''}
                        onChange={onChangeExternalLink} 
                        placeholder="Введите ссылку на внешний контент"
                        helperText=""
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }} />                                                
                </div>}   
                {type==="map" && <div> 
              <TextField
                        id="map_location"
                        label="Локация объектов"
                        value={mapOptions?mapOptions.map_location:''}
                        onChange={onChangeMapLocation} 
                        placeholder="Поле с локацией объектов"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>         
                        {locationColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}                                                               
                        </TextField>
                        <TextField
                        id="new_map_title_column"
                        label="Название"
                        value={mapOptions?mapOptions.titleColumn:''}
                        onChange={onChangeMapTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="new_map_color_column"
                        label="Цвет"
                        value={mapOptions?mapOptions.colorColumn:''}
                        onChange={onChangeMapColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                                            
                </div>}                                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose}>
              Отмена
            </Button>            
            <Button onClick={handleCreate} color="primary">
              Добавить
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }   


  function DefaultItemValuesDialog(props) {
    const [open, setOpen] = useState(false);
    const [editedItem,setEditedItem] = useState({});
    const theme = useTheme();
    const classes = useStyles();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      //props.setEditedIdx(null);
      setEditedItem({});
      setOpen(false);
    };

    const handleSubmit = (event) => {            
        props.saveDefaultValues(props.tableId, props.viewId, editedItem)
        handleClose()
    };    
    
    const  lookupValue =(col)=>{
      let lookupColumn = props.columns.find(cl=> cl._id === col.lookupId) 
      if(lookupColumn ){
        if(props.tables[lookupColumn.linkId] && editedItem[lookupColumn._id]){
          let item = props.tables[lookupColumn.linkId].items[editedItem[lookupColumn._id]]
          if(item[col.lookupField]){
            var fieldCol = props.tables[lookupColumn.linkId].columns.find(cl=> cl._id===col.lookupField)
  
            if(fieldCol && fieldCol.type==='member'){
              var member = props.members.find(mb=> mb._id===item[col.lookupField])
              if(member){
                return member.name
              }
               
            }
            return item[col.lookupField]
          }
          
        }            
      }              
      return ""
    }
          

    useEffect(() => {
      if(props.defaultValues){
        setEditedItem(props.defaultValues)
      } else {
        setEditedItem({})
      }
      
    }, [props.defaultValues])

    return (
<>
<Button disabled={props.accessLevel<5} fullWidth onClick={handleClickOpen}>Значения по-умолчанию</Button>
        <Dialog
          fullWidth={true} 
          maxWidth="sm"
          //fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-view-dafult-values-edit-dialog-title"
        >
          <DialogTitle id="responsive-view-dafult-values-edit-dialog-title" >
            <Typography variant="h6" noWrap>Установка значений по-умолчанию</Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton></DialogTitle>          
          <DialogContent dividers>
            <div >
              {editedItem && props.columns && props.columns.map( column=>(
                <Grid container alignItems="flex-end">
                <Grid item xs={4}>
<Typography variant="body1">{column.name}:</Typography>
                  </Grid>              
                  <Grid item xs={8}>
                {column.type ===   "member"?(
                  <MemberEditor
                  value={editedItem[column._id]}
                  inForm={true}
                  onChange={value => {
                    const newVal=value; 
                    const colkey = column._id
                    setEditedItem(curItem=>{
                      var newItem = {...curItem}
                      newItem[colkey] = newVal
                      return newItem
                    })
                  }}
                  members={props.members}
                  rowHeight={"25px"}
                  //menuPortalTarget={p.editorPortalTarget}
                  //onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет значений для выбора!"
                />  
                ):
                /*
                (column.type ===   "chatmember") ? (
                  <ChatMemberEditor
                  value={editedItem[column._id]}
                  inForm={true}
                  onChange={value => {
                    const newVal=value; 
                    const colkey = column._id
                    setEditedItem(curItem=>{
                      var newItem = {...curItem}
                      newItem[colkey] = newVal
                      return newItem
                    })
                  }}
                  members={props.chatmembers}
                  rowHeight={"25px"}
                  //menuPortalTarget={p.editorPortalTarget}
                  //onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет значений для выбора!"
                />  
                ): */
                (column.type ===   "select") ? (
                  <SelectEditor
                  value={editedItem[column._id]}
                  inForm={true}
                  onChange={value => {
                    const newVal=value; 
                    const colkey = column._id
                    setEditedItem(curItem=>{
                      var newItem = {...curItem}
                      newItem[colkey] = newVal
                      return newItem
                    })
                  }}
                  options={column.options}
                  rowHeight={"25px"}
                  //menuPortalTarget={p.editorPortalTarget}
                  //onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет значений для выбора!"
                  />     
                ): (column.type ===   "link" && column.linkId && props.tables[column.linkId])? (
                  <LinkEditor
                  //options, linkId, titleColName
                  value={editedItem[column._id]}
                  titleCol={column.linkViewField}
                  inForm={true}
                  tables={props.tables}
                  linkId={props.linkId}
                  filterView={column.linkSelectView}
                  currentMember={props.currentMember}
                  members={props.members}
                  chatmembers = {props.chatmembers}                    
                  onChange={value => {
                    const newVal=value; 
                    const colkey = column._id
                    setEditedItem(curItem=>{
                      var newItem = {...curItem}
                      newItem[colkey] = newVal
                      return newItem
                    })
                  }}    
                
                  //options={props.tables[column.linkId].itemsList}//{props.tables}
                  rowHeight={"25px"}
                  //menuPortalTarget={p.editorPortalTarget}
                  //onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет значений для выбора!"
                  /> 
                  ): (column.type ===   "lookup" && column.lookupId && column.lookupField)? (
                    <TextField
                    id="item_lookup_editor"
                    multiline
                    value={lookupValue(column)}

                    //placeholder="Введите название для вида"
                    //helperText=""                            
                    //margin="normal"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />                          
                ): (column.type ===   "number")?(
                        <TextField
                          id="item_number_editor"
                          //label={column.name}
                          type="number"
                          //error={Boolean(titleError)}
                          //helperText={titleError}
                          value={editedItem[column._id]}
                          onChange={(e) => { 
                            const newVal=e.target.value; 
                            const colkey = column._id
                            setEditedItem(curItem=>{
                              var newItem = {...curItem}
                              newItem[colkey] = newVal
                              return newItem
                            })                                                        
                          }}
                          //placeholder="Введите название для вида"
                          //helperText=""                            
                          //margin="normal"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        ): (column.type ===   "geolocation")?(
                          <TextField
                            id="item_geolocation_editor"
                            value={(editedItem[column._id] && editedItem[column._id].longitude)?`${editedItem[column._id].longitude}, ${editedItem[column._id].latitude}`:"" }                        
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                  <IconButton target="_blank" href={(editedItem[column._id] && editedItem[column._id].longitude)?`https://yandex.ru/maps/?ll=${editedItem[column._id].longitude}%2C${editedItem[column._id].latitude}84&z=18`:"#"} size="small">
                                    <LinkIcon fontSize="small" />
                                  </IconButton>
                              )
                            }}
                          />                        
                          ): (column.type ===   "attachments")?(
                            <AttachmentsEditor
                              id="item_attachments_editor"
                              value={editedItem[column._id]}                                                      
                            />                        
                  ):(column.type ===   'check')?(
                  <Checkbox 
                    checked={editedItem[column._id]}
                    color="primary" 
                    onChange={(e) => { 
                    const newVal=e.target.checked; 
                    const colkey = column._id
                    setEditedItem(curItem=>{
                      var newItem = {...curItem}
                      newItem[colkey] = newVal
                      return newItem
                    })                                                        
                  }}/>
                  ):(column.type ===   'multiline')?(
                    <TextField
                    id="item_text_editor"
                    multiline
                    rows={3}
                    value={editedItem[column._id]}
                    onChange={(e) => { 
                      const newVal=e.target.value; 
                      const colkey = column._id
                      setEditedItem(curItem=>{
                        var newItem = {...curItem}
                        newItem[colkey] = newVal
                        return newItem 
                        return newItem
                      })                                                        
                    }}
                    //placeholder="Введите название для вида"
                    //helperText=""                            
                    //margin="normal"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />               
                    ):(column.type === 'date')?(
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLoc}>
        <KeyboardDateTimePicker
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy HH:mm"
          //margin="normal"
          id="item-editor-date-picker-inline"
          //label={column.name}
          value={editedItem[column._id]}
          onChange={(e) => { 
            const newVal=e; 
            const colkey = column._id
            setEditedItem(curItem=>{
              var newItem = {...curItem}
              newItem[colkey] = newVal
              return newItem
            })                                                        
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
                  </MuiPickersUtilsProvider>
                ) :                         
                <TextField
                id="item_text_editor"
                //label={column.name}
                //type="number"
                //error={Boolean(titleError)}
                //helperText={titleError}
                value={editedItem[column._id]}
                onChange={(e) => { 
                  const newVal=e.target.value; 
                  const colkey = column._id
                  setEditedItem(curItem=>{
                    var newItem = {...curItem}
                    newItem[colkey] = newVal
                    return newItem 
                    return newItem
                  })                                                        
                }}
                //placeholder="Введите название для вида"
                //helperText=""                            
                //margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              }
               </Grid>          
             </Grid> ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button onClick={handleSubmit}>Сохранить</Button>
          </DialogActions>
        </Dialog>
        </>
    );
  }  

  function AddChartToDashboardDialog(props) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const [layout, setLayout] = useState({})
    const [frames, setFrames] = useState([])    

    const theme = useTheme();
    const classes = useStyles();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
      setData([])
      setLayout({})
      setFrames([])
      setOpen(true)
    };
  

    const handleClose = () => {      
      setOpen(false);
    };

    const handleSubmit = (event) => {
      props.setDashboardSettings(cur => {
        var newSettings = { ...cur }
        var i = ObjectID().toHexString()
        const newChat = {
          i: i,
          type:"plotly",
          data: data,
          layout: layout,
          frames: frames,
          data_grid: { x: 4, y: 10, w: 50, h: 10 }
        }
        if (!newSettings.elements) {
          newSettings.elements = []
        }
        //console.log('onClick AddChartToDashboardDialog cur', [...cur.elements])
        newSettings.elements.push(newChat)
        //console.log('onClick AddChartToDashboardDialog ', [...newSettings.elements])
        return newSettings
      })
      props.setEditedDashboardSettings(true)
        handleClose()
    };    
 
  

    return (
<>
  <IconButton 
    size="small" 
    variant="text"
    disabled={props.accessLevel<9}      
    onClick={handleClickOpen}
    >      
    <DonutSmallIcon fontSize="small"/>
      </IconButton>
        <Dialog
          fullWidth={true} 
          maxWidth="lg"
          //fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-view-add-chart-dialog-title"
        >
          <DialogTitle id="responsive-view-add-chart-dialog-title" >
            <Typography variant="h6" noWrap>Добавить диаграмму</Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton></DialogTitle>          
          <DialogContent dividers>
            <div >
            <PlotlyEditor
          data={data}
          layout={layout}
          config={{editable: true}}
          frames={frames}
          dataSources={props.dataSources}
          dataSourceOptions={ Object.keys(props.dataSources).map(name => ({
            value: name,
            label: name,
          }))}
          plotly={plotly}
          onUpdate={(data, layout, frames) =>{
            console.log('AddChartToDashboardDialog onUpdate', data, layout, frames)
            setData(data)
            setLayout(layout)
            setFrames(frames)   
                      
            }
          }
          useResizeHandler
          debug
          advancedTraceTypeSelector
        />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button onClick={handleSubmit}>Сохранить</Button>
          </DialogActions>
        </Dialog>
        </>
    );
  }   
  

  function EditChartInDashboardDialog(props) {
   
    const theme = useTheme();
    const classes = useStyles();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  
    const handleClose = () => {      
      props.setEditedChart(null);
    };

    const handleSubmit = (event) => {            
        props.saveChartParams(props.editedChart)
        handleClose()
    };    


    return (
<>

        <Dialog
          fullWidth={true} 
          maxWidth="lg"
          //fullScreen={fullScreen}
          open={Boolean(props.editedChart)}
          onClose={handleClose}
          aria-labelledby="responsive-view-add-chart-dialog-title"
        >
          <DialogTitle id="responsive-view-add-chart-dialog-title" >
            <Typography variant="h6" noWrap>Изменить диаграмму</Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton></DialogTitle>          
          <DialogContent dividers>
            <div >
            <PlotlyEditor
          data={props.editedChart?props.editedChart.data:[]}
          layout={props.editedChart?props.editedChart.layout:{}}
          config={{editable: true}}
          frames={props.editedChart?props.editedChart.frames:{}}
          dataSources={props.dataSources}
          dataSourceOptions={ Object.keys(props.dataSources).map(name => ({
            value: name,
            label: name,
          }))}
          plotly={plotly}
          onUpdate={(data, layout, frames) =>{
            //console.log('AddChartToDashboardDialog onUpdate', data, layout, frames)
              props.setEditedChart(cur =>                 
                {
                  var newChatParams = {...cur}
                  newChatParams.data=data;
                  newChatParams.layout=layout;
                  newChatParams.frames=frames;
                  return newChatParams                                
              })
            }
          }
          useResizeHandler
          debug
          advancedTraceTypeSelector
        />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button onClick={handleSubmit}>Сохранить</Button>
          </DialogActions>
        </Dialog>
        </>
    );
  }   

  function ViewDialogsMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [countOfDialog, setCountOfDialog] = useState(0);
    const history = useHistory();
    const classes = useStyles();

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const handleOpenDialog = (dialogId)=>{
      props.setEditedDialogId(dialogId); 
      props.setEditedDialogTableId(props.tableId); 
      props.setIsEditedJobOpen('dialog'); 
      props.setRigthPanelContent("jobs"); 
      handleClose()
    }

    const handleCreate = async (title, type) => {


    
      var dialogSettings = {
        name:title,
        type,
        filter: props.viewId,
        work_item_list: props.tableId,
        database: props.dbId,
      }
      props.addDialog(dialogSettings, (newViewId)=>{
        props.setEditedDialogId(newViewId); 
        props.setEditedDialogTableId(props.tableId); 
        props.setIsEditedJobOpen('dialog'); 
        props.setRigthPanelContent("jobs"); 
      })



      handleClose();
    };      


    
  useEffect(()=>{ 
    setCountOfDialog(props.dialogs?props.dialogs.length:0)
    console.log('useEffect props.dialogs ', props.dialogs)
  }, [props.dialogs])


    const open = Boolean(anchorEl);
    const id = open ? 'view-dialogs-popover' : undefined; 



    return (
      <React.Fragment>
                                  
        <Tooltip title="Настроить диалоги">
          {props.collapseHeaderButton ?
            <IconButton 
            onClick={handleClickOpen} 
            size="small" 
            variant="text" 
            className={(countOfDialog > 0) ? classes.dialogsButton: null}>
              <QuestionAnswerIcon fontSize="small" />
            </IconButton>
            :
            <Button size="small" variant="text" startIcon={<QuestionAnswerIcon fontSize="small" />} onClick={handleClickOpen} 
            className={(countOfDialog > 0) ? classes.dialogsButton: null}>
              {countOfDialog>0?((countOfDialog===1 || countOfDialog===21)?(""+countOfDialog+" диалог"):((countOfDialog===2 || countOfDialog===3 || countOfDialog===4 || countOfDialog===22 || countOfDialog===23 || countOfDialog===24)?(""+countOfDialog+" диалога"): (""+countOfDialog+" диалогов"))):"Нет диалогов"}</Button>
          }
        </Tooltip>  

        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style:{
            maxWidth:"350px"
          }
        }}   
      >
       
       {props.dialogs && props.dialogs.length>0 && <List  dense={true} subheader={<ListSubheader>Диалоги для работы с данными:</ListSubheader>}>
             {props.dialogs.map((dialog, idx)=>(
      <ListItem
        button
        ContainerComponent="li" 
        onClick={e=>handleOpenDialog(dialog._id)}  

      >
      <ListItemIcon style={{minWidth:"35px"}}>
      {dialog && dialog.type==="standup"?
                   <AddItemFromChatIcon fontSize="small" color="primary"/> :
                   dialog.type==="update_request"?
                   <UpdateItemsFromChatIcon fontSize="small" color="primary"/> :
                   dialog.type==="update_item_request"?
                   <UpdateItemFromChatIcon fontSize="small" color="primary"/>:
                   <SendMsgtoChatIcon fontSize="small" color="primary"/>
                    }
      </ListItemIcon>
      <ListItemText id={"view-dialog-list-item-"+idx} primary={dialog.name} />
    </ListItem>
                    ))}
    </List>          
    }
    <List  dense={true} subheader={<ListSubheader>Создать диалог:</ListSubheader>}>
    {false && <Typography variant="caption">Диалог - это сценарий взаимодействия бота с пользователем в чате, чтобы выполнить определенное действие по обработке данных в таблице</Typography>   }                                           

    <ListItem
        button
        ContainerComponent="li" 
        onClick={ e => {handleCreate("Добавить элемент в "+props.viewTitle, "standup")  }}  

      >
      <ListItemIcon style={{minWidth:"35px"}}>
      <AddItemFromChatIcon fontSize="small" color="disabled"/>
      </ListItemIcon>
      <ListItemText id={"view-add-dialog-list-item-standup"} primary="Добавление элемента ч/з чат" />
    </ListItem>
    <ListItem
        button
        ContainerComponent="li" 
        onClick={ e => {handleCreate("Обновить элементы в "+props.viewTitle, "update_request")  }}  

      >
      <ListItemIcon style={{minWidth:"35px"}}>
      <UpdateItemsFromChatIcon fontSize="small" color="disabled"/> 
      </ListItemIcon>
      <ListItemText id={"view-add-dialog-list-item-update_request"} primary="Вывод или обновление элементов в чат" />
    </ListItem>
      <ListItem
        button
        ContainerComponent="li" 
        onClick={ e => {handleCreate("Обновить один элемент в "+props.viewTitle, "update_item_request")  }}   

      >
      <ListItemIcon style={{minWidth:"35px"}}>
      <UpdateItemFromChatIcon fontSize="small" color="disabled"/>
      </ListItemIcon>
      <ListItemText id={"view-add-dialog-list-item-update_item_request"} primary="Обновление одного элемента ч/з чат" />
    </ListItem>

    </List>     
      <div>             
                      </div>             
        </Popover>  
      </React.Fragment>
    );
  } 

  /**
   * Переделал на свою форму
   * @param {*} props 
   * @returns 
   */


  function ViewFilterDialog(props) {
    const {token} = useContext(AuthContext);
    const {loading, error, request} = useHttp();
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [fields, setFields] = React.useState({});
    //const [config, setConfig] = React.useState({});
    const [tree, setTree] = React.useState(null);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [edited, setEdited] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();
    const {onChangeReport, setCurrentPageTitle} = useContext(NavContext);



    const handleClickOpen = (event) => {
      setEdited(false)
      setAnchorEl(event.currentTarget);
    }; 


    const handleClose = () => {
      //props.saveDialog();
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();  
        //console.log("handleSave jsonLogicFormat", JSON.stringify(QbUtils.jsonLogicFormat(tree, config)));      
        //console.log("handleSave tree", QbUtils.getTree(tree));
        if(edited){
          props.handleChangeFilterViewOptions(QbUtils.getTree(tree))
        }
        
        handleClose();        
    };

    const renderBuilder = (props) => (
      <div className="query-builder-container" >
        <div className="query-builder qb-lite">
            <Builder {...props} />
        </div>
      </div>
    )
 

    const onChange = (immutableTree, _config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`

      setTree(immutableTree);
      setEdited(true)
      //setConfig(_config);
 
      //const jsonTree = QbUtils.getTree(immutableTree);
      
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    }    
  
    useEffect(()=>{
      console.log("ViewFilterDialog useEffect ", props.columns, props.filter, props.config)
      if(props.config){
      var _filter = props.filter?props.filter:{"id": QbUtils.uuid(), "type": "group"}
      var _isFiltered = false;
        let _fields = {}

        var _immutableTree = QbUtils.checkTree(QbUtils.loadTree(_filter), props.config)
      
        var mongoFilter = QbUtils.mongodbFormat(_immutableTree, props.config)
      //console.log('sqlFormat ', sqlFormat)
        setTree(_immutableTree)
        setIsFiltered(mongoFilter)
        console.log('setMongoFilter ', mongoFilter)
        props.setMongoFilter(mongoFilter?mongoFilter:{})   
      }   
    }, [props.columns, props.filter, props.config])

    const open = Boolean(anchorEl);

    return (
      <React.Fragment>  
        <Tooltip title="Настроить фильтр данных">
          {props.collapseHeaderButton ? 
            <IconButton 
              className={isFiltered?classes.filterButton: classes.viewButton}
              onClick={handleClickOpen} 
              size="small" 
              variant="text">
              <FilterListIcon fontSize="small" />
            </IconButton>
            : 
             <Button 
              //color="primary"
              className={isFiltered?classes.filterButton: classes.viewButton}
              size="small" 
              variant="text"
              startIcon={<FilterListIcon fontSize="small" />} 
              onClick={handleClickOpen}>
              Фильтр
              </Button>}
        </Tooltip>                                
          <Popover
        id="table-filter-popover"
        container={props.containerEl?props.containerEl.current:null}
        open={open}
        anchorEl={anchorEl}
        onClose={handleSave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      <div style={{minWidth:250}}>
      <ListSubheader>Фильтр </ListSubheader>
        <QbQuery
            {...props.config} 
            value={tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
        />
        
      </div>                     
        </Popover>
        </React.Fragment>
    );
  }    

  function generateStringFromTemplate(template, params) {
    //console.log('generateStringFromTemplate template', template, params);
    var str = template;
    for (var par in params) {
        let rex = new RegExp('\\[' + par + '\\]', 'gi');
        var v = params[par];
        if (v) {
            str = str.replace(rex, v);
        }
    }
    return str;
  }


  function ViewMoreMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);        

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleDelelteView = () => {
      console.log('handleDelelteView ', props.viewId)
      props.deleteView(props.tableId, props.viewId);
      handleClose();
    };  
  
    const handleChangeViewName = () => {
      //props.handleCopy(); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
      handleClose();
    }


    return (
      <React.Fragment>
        <IconButton size="small" edge="end" aria-label="menu" aria-controls="more-view-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreIcon fontSize="small"/>
        </IconButton>
        <Menu
          id="more-view-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleChangeViewName}>Переименовать</MenuItem>
          <MenuItem onClick={handleDelelteView}>Удалить</MenuItem>
        </Menu>
      </React.Fragment>
    );
  }  

function ViewsConfigListSettings(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpen = (event) => {
    //setGroup(props.groups[0]._id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };  
  return (
    <React.Fragment>
                    <IconButton size="small" onClick={handleClickOpen}>
                      <SettingsIcon style={{ fontSize: 16 }} />
                    </IconButton>      
      <Popover
        id={'Popover-quicklyViewsDrawer-settings'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ style:{padding: "5px"} }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <div>
          
          <List 
          subheader={<b>Настройки</b>}
          dense>
          {false && <ListItem
                          disableGutters                          
              >

<ListItemText
  //id={"tb-el-label-" + idx}
  primary="Показать диалоги"
  //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
/>
<ListItemSecondaryAction
  onClick={ e => { props.setViewDialogs(!props.viewDialogs) }}
>
  
    <IconButton size="small" edge="end" aria-label="view_dialogs">
      {props.viewDialogs?<ToggleOnIcon color="primary"  fontSize="small"/>:
      <ToggleOffOutlinedIcon color="disabled" fontSize="small"/>
      }
    </IconButton>  
  
</ListItemSecondaryAction>

                        </ListItem>   }
                        <ListItem
                          disableGutters                          
              >

<ListItemText
  //id={"tb-el-label-" + idx}
  primary="Показать все персональные представления"
  //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
/>
<ListItemSecondaryAction
  onClick={ e => { props.setViewAllPersonal(!props.viewAllPersonal) }}
>
  
    <IconButton size="small" edge="end" aria-label="view_dialogs">
      {props.viewAllPersonal?<ToggleOnIcon color="primary"  fontSize="small"/>:
      <ToggleOffOutlinedIcon color="disabled" fontSize="small"/>
      }
    </IconButton>  
  
</ListItemSecondaryAction>

                        </ListItem>                           
        </List>      
        </div>
      </Popover>
    </React.Fragment>
  )
}
  
  function ViewsConfigList(props) {
    const [views, setViews] = useState([]);
    const [dialogs, setDialogs] = useState([]);
    const [findViewInput, setFindViewInput] = useState("");
    const [dialogExpanded, setDialogExpanded] = useState(false);
    const classes = useStyles();

    const onDragEndDialog = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      //console.log('ViewsConfigList onDragEnd ', result.source, result.destination)
      //props.handleViewsReorder(props.views[result.source.index]._id, props.views[result.destination.index]._id)
      
      var _newDialogs = [...dialogs];
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;

      //const sourceColumnIndex = reorderedColumns.findIndex(c => c.key === sk);
      //const targetColumnIndex = reorderedColumns.findIndex(c => c.key === tk);
      //const reorderedColumns = [...columns];
  
      _newDialogs.splice(
        targetIndex,
        0,
        _newDialogs.splice(sourceIndex, 1)[0]
      ); 
      //console.log('onDragEnd _newDialogs', _newDialogs)
      setDialogs(_newDialogs)
      
      for(let idx=0; idx<_newDialogs.length; idx++){
        if(_newDialogs[idx].index===idx){

        } else {
          props.updateView(props.tableId, _newDialogs[idx]._id, {index: idx}) //TODO Сделать групповое обновление
        }
      }   
    }

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      //console.log('ViewsConfigList onDragEnd ', result.source, result.destination)
      //props.handleViewsReorder(props.views[result.source.index]._id, props.views[result.destination.index]._id)
      
      var _newViews = [...views];
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;

      //const sourceColumnIndex = reorderedColumns.findIndex(c => c.key === sk);
      //const targetColumnIndex = reorderedColumns.findIndex(c => c.key === tk);
      //const reorderedColumns = [...columns];
  
      _newViews.splice(
        targetIndex,
        0,
        _newViews.splice(sourceIndex, 1)[0]
      ); 
      //console.log('onDragEnd _newViews', _newViews)
      const __tableId = props.tableId

      setViews(_newViews)      
      for(let idx=0; idx<_newViews.length; idx++){
        if(_newViews[idx].index===idx){

        } else {
          props.updateView(__tableId, _newViews[idx]._id, {index: idx}) //TODO Сделать групповое обновление
        }
      }   
    }

    const handleChangeExpanded = (event) => {
      setDialogExpanded(!dialogExpanded);
    };

    useEffect(()=>{
      if(props.views){
        //console.log('ViewsConfigList props.views', props.views, props.currentMember, props.currentMember);
        var sortedViewsIds =  Object.keys(props.views).sort((a, b) => props.views[a].index - props.views[b].index);
        var _views = []
        var _dialogs = []
        for(let iv=0; iv<sortedViewsIds.length; iv++){
          var _view =  props.views[sortedViewsIds[iv]]
          var ok = false
          if(findViewInput && findViewInput.length>0){
            if(_view.title.toUpperCase().indexOf(findViewInput.toUpperCase())>=0){
              ok = true
            }
          } else {
            ok = true
          }
          if(ok){
            if (!props.viewAllPersonal && _view.permissionType==="p") {
              let ownerId = _view.owner?_view.owner:null
              if(ownerId !== props.currentMember._id){
                ok = false;
              }

            }
            if(ok){
              if (_view.viewtype === 'dialog') {
                if (props.viewDialogs) {
                  _views.push(_view)
                }
              } else {
                _views.push(_view)
              }
            }
          }
        }
        //console.log('ViewsConfigList views', views);
        setViews(_views)
        setDialogs(_dialogs)
      } else {
        setViews([])
        setDialogs([])
      }
    }, [props.views, findViewInput, props.viewDialogs, props.viewAllPersonal, props.currentMember])

    return (
      <React.Fragment>
        <div style={{ 
                height: props.height,
                //overflowY:"scroll",
                //overflowX:"hidden",                
              }}>
            <TextField
              id="find_view_input"
              name="find_view_input"
              autoFocus
              value={findViewInput}
              onChange={(e) => { setFindViewInput(e.target.value) }}
              placeholder="Поиск вида"
              //helperText=""   
              size='small'                         
              margin="dense"
              fullWidth
              autoComplete="off"
              style={{
                paddingLeft:"5px",
                paddingRight:"5px"           
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 16 }} />
                  </InputAdornment>
                ),
                endAdornment: (                  
                  <ViewsConfigListSettings viewDialogs={props.viewDialogs} setViewDialogs={props.setViewDialogs} viewAllPersonal={props.viewAllPersonal} setViewAllPersonal={props.setViewAllPersonal}/>                  
                ),                
                style:{
                  fontSize:12
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />                
              <div style={{ 
                height: props.height2,
                overflowY:"scroll",
                overflowX:"hidden",
                minHeight: "90px",
              }}>   
          <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableViews">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >
            <div style={{ 
              overflow:"auto" 
              }}>         
          <List  dense={true} >
            {views.map((view, idx)=>(
                  <Draggable key={view._id} draggableId={view._id} index={idx}>
                    {(provided, snapshot) => (

      <ListItem
        disableGutters
        button        
        onClick={e => {props.handleChangeView(e, view._id); setFindViewInput("")}} 
        selected={view._id===props.viewId} 
        ContainerComponent="li"
        ContainerProps={{ ref: provided.innerRef, className:classes.nonSelectedListItem }}
        //style={{
        //paddingLeft: "2px"
        //}}         
          {...provided.draggableProps}
          {...provided.dragHandleProps}     
      >
      <ListItemIcon style={{minWidth:"32px", paddingRight:"5px"}}

      >
    <DragIndicatorIcon fontSize="small" color="disabled" className={classes.nonSelectedListItemSecondaryAction}      />        
      {view.viewtype==='list' && <IconForViewList/>}
    {view.viewtype==='kanban' && <IconForViewKanban/>}
    {view.viewtype==='calendar' && <IconForViewCalendar/>}
    {view.viewtype==='form' && <IconForViewForm/>}
    {view.viewtype==='dialog' && <IconForViewBot />}
    {view.viewtype==='dashboard' && <IconForViewDashboard/>}
    {view.viewtype==='external' && <IconForExternalContent />}
    {view.viewtype==='page' && <IconForViewPage />}
    {view.viewtype==='map' && <IconForMap />}
    {view.viewtype==='timeline' && <IconForViewTimeline />}
    

      </ListItemIcon>      
      <ListItemText 
      
      id={"view-el-label-"+idx} 
      primary={view.title} 
      primaryTypographyProps={{noWrap:true, style:{cursor:"pointer"}}}
      />
      <ListItemSecondaryAction       
      >
      {view._id===props.viewId ? <CheckIcon fontSize="small" style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.40)"}}/>:      
      view.permissionType === "c" ? <GroupIcon size="small" style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.40)"}} className={classes.nonSelectedListItemSecondaryAction}/> :
      view.permissionType === "p" ? <PersonIcon size="small" style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.40)"}} className={classes.nonSelectedListItemSecondaryAction}/> :
        <LockIcon size="small" style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.40)"}} className={classes.nonSelectedListItemSecondaryAction}/>      
      }
      </ListItemSecondaryAction>
    </ListItem>
                    )}
                    </Draggable>    
    ))}
    {provided.placeholder}
    </List>          
    </div >  
    </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      </div >
      <Divider />
      {props.accessLevel>=5 && <AddViewDialog 
                    handleAddView={props.handleAddView} 
                    columns={props.columns}                     
                    accessLevel={props.accessLevel}
                    views={props.nonDialogViews}
                    memberColumns={props.memberColumns}
                    linkColumns={props.linkColumns}
                    linkMemberColumns={props.linkMemberColumns}
                    linkViews={props.linkViews}
                    editedColumns={props.editedColumns}
                    dbId={props.dbId}
                    tableId={props.tableId}
                    />}
      </div>      
      </React.Fragment>
    );
  } 
 

  function ColumnsViewMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

  
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      console.log('onDragEnd ', result.source, result.destination)
      props.handleColumnsReorder(props.tableId, props.viewId, props.columns[result.source.index]._id, props.columns[result.destination.index]._id)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined; 

    return (
      <React.Fragment>
                                  
        <Tooltip title="Настройка видимости колонок">
          {props.collapseHeaderButton ?
            <IconButton onClick={handleClickOpen} size="small" variant="text" className={classes.viewButton}>
              <VisibilityIcon fontSize="small" />
            </IconButton>
            :
            <Button size="small" variant="text"  className={classes.viewButton} startIcon={<VisibilityIcon fontSize="small" />} onClick={handleClickOpen}>
              Видимость</Button>
          }
        </Tooltip>     
            
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableColumns">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >  
       
          <List  dense={true} subheader={<ListSubheader>Видимость колонок</ListSubheader>}>
            {props.columns.map((column, idx)=>(
                  <Draggable key={column._id} draggableId={column._id} index={idx}>
                    {(provided, snapshot) => (

      <ListItem
        button
        ContainerComponent="li"
        ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}      
      >
      <ListItemIcon style={{minWidth:"35px"}}>
      {column.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
      {column.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
    {column.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
    {column.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
    {column.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
    {column.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
    {column.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
    {column.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}
    {column.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}
    {column.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}
    {column.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}
    {column.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
    {column.type==='lookup' && <LookupIcon fontSize="small"  color="disabled"/>}
      </ListItemIcon>
      <ListItemText id={"switch-column-label-"+idx} primary={column.name} />
      <ListItemSecondaryAction>
        <SmallSwitch
          edge="end"
          onChange={(e)=>{            
            const width = e.target.checked?150:0;
            props.onColumnResize(props.tableId, props.viewId, column._id, width)            
          }}
          checked={column.width>0}
          //size="small"
        />
      </ListItemSecondaryAction>
    </ListItem>
                    )}
                    </Draggable>    
    ))}
    {provided.placeholder}
    </List>            
    </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      { false && <div>
      <DefaultItemValuesDialog 
            saveDefaultValues={props.saveDefaultValues} 
            members={props.members} 
            chatmembers={props.chatmembers}  
            columns={props.defaultcolumns }
          tables={props.tables}
          tableId={props.tableId}
          viewId={props.viewId}
          defaultValues={props.defaultValues}
          accessLevel={props.accessLevel}
          currentMember={props.currentMember}
          />                  
                      </div> }            
        </Popover>  
      </React.Fragment>
    );
  }  

  function KanbanViewSettingsMenu(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      console.log('onDragEnd ', result.source, result.destination)
      props.handleColumnsReorder(props.tableId, props.viewId, props.columns[result.source.index]._id, props.columns[result.destination.index]._id)
    }

    const onChangeTitleColumn = (event) => {
     props.handleChangeKanbanViewOptions("titleColumn", event.target.value)     
  };

  const onChangeDescriptionColumn = (event) => {
    props.handleChangeKanbanViewOptions('descriptionColumn', event.target.value)     
       
};

const onChangeLabelColumn = (event) => {
  props.handleChangeKanbanViewOptions("labelColumn", event.target.value)     
   
};


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Установить видимость колонок">
          {props.collapseHeaderButton ?
            <IconButton size="small" variant="text" onClick={handleClickOpen} >
              <SettingsIcon />
            </IconButton>
            :
            <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<SettingsIcon />}>
              Настроить
            </Button>
          }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
          <div className={classes.settingsPaper}>
        <div >Настройте вид карточек</div>
        <TextField
                        id="kanban_title_column"
                        label="Название"
                        disabled={props.accessLevel <5}
                        value={props.kanbanColumnsOptions?props.kanbanColumnsOptions.titleColumn:''}
                        onChange={onChangeTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => 
                            {
                              return (option.type!=="geolocation" && option.type!=="attachments") && 
                              <MenuItem key={option._id} value={option._id}>
                                <ColumnTypeIcon type={option.type} />
                                {option.name}                              
                            </MenuItem>
                            }
                          )}
                    </TextField>   

                    <TextField
                        id="kanban_label_column"
                        label="Лейбел"
                        disabled={props.accessLevel <5}
                        value={props.kanbanColumnsOptions?props.kanbanColumnsOptions.labelColumn:''}
                        onChange={onChangeLabelColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => 
                          {return (option.type!=="geolocation" && option.type!=="attachments") && 
                              <MenuItem key={option._id} value={option._id}>
                                <ColumnTypeIcon type={option.type} />
                              {option.name}
                            </MenuItem>
                            }
                          )}
                    </TextField>   
                   {props.kanbanColumnsOptions && props.kanbanColumnsOptions.descriptionColumn && <TextField
                        id="kanban_description_column"
                        label="Описание"
                        disabled={props.accessLevel <5}
                        value={props.kanbanColumnsOptions.descriptionColumn}
                        onChange={onChangeDescriptionColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map((option) => 
                          {return (option.type!=="geolocation" && option.type!=="attachments") && 
                              <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                            }
                          )}
                    </TextField> }   
                    <ListSubheader>Содержание карточки</ListSubheader>                 
                    </div> 
                    <div
                    style={{
                      overflowY: "scroll",
                      overflowX: "hidden",
                      maxHeight: "250px"                                        
                    }}
                    >
                    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableColumns">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >  
       
          <List  dense={true} 
          >
            {props.columns.map((column, idx)=>(
                  <Draggable key={column._id} draggableId={column._id} index={idx}>
                    {(provided, snapshot) => (

      <ListItem
        button
        ContainerComponent="li"
        ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}      
      >
      <ListItemIcon>
      {column.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
      {column.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
    {column.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
    {column.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
    {column.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
    {column.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
    {column.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
    {column.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}
    {column.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}
    {column.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}
    {column.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}
    {column.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
    {column.type==='lookup' && <LookupIcon fontSize="small"  color="disabled"/>}
      </ListItemIcon>
      <ListItemText id={"switch-column-label-"+idx} primary={column.name} />
      <ListItemSecondaryAction>
        <SmallSwitch
          edge="end"
          onChange={(e)=>{            
            const width = e.target.checked?150:0;
            props.onColumnResize(props.tableId, props.viewId, column._id, width)            
          }}
          checked={column.width>0}
          //size="small"
        />
      </ListItemSecondaryAction>
    </ListItem>
                    )}
                    </Draggable>    
    ))}
    {provided.placeholder}
    </List>            
    </RootRef>
          )}
        </Droppable>
      </DragDropContext>                      
                    </div>
                    { false && <div>
                      
                      <DefaultItemValuesDialog 
            saveDefaultValues={props.saveDefaultValues} 
            members={props.members} 
            chatmembers={props.chatmembers}  
            columns={props.columns }
          tables={props.tables}
          tableId={props.tableId}
          viewId={props.viewId}
          defaultValues={props.defaultValues}
          accessLevel={props.accessLevel}   
          currentMember={props.currentMember}               
          />                        
                      </div>    }                                                
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }    

  function GroupsSettingsMenu(props) {
    const [addedColumn, setAddedColumn] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    const onAddGroupColumn= (event) => {
      var colId = event.target.value
      var _newGroup = [...props.groups];
      _newGroup.push({
        column: colId,
        order:""
      })
      props.handleChangeGroups(props.tableId, props.viewId, _newGroup)
      setAddedColumn(null)
  };


  const onDeleteGroup= (idx) => {
    var _newGroup = [...props.groups];
    _newGroup.splice(idx, 1)
    props.handleChangeGroups(props.tableId, props.viewId, _newGroup)
  };  

  const onChangeOrderGroup= (idx, order) => {
    var _newGroup = [...props.groups];
    if(order==="ASC"){
      _newGroup[idx].order = 'DESC';
    } else {
      _newGroup[idx].order = 'ASC';
    }
    
    props.handleChangeSorts(props.tableId, props.viewId, _newGroup)
  };      
  
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      var _newGroup = [...props.groups];
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;

  
      _newGroup.splice(
        targetIndex,
        0,
        _newGroup.splice(sourceIndex, 1)[0]
      );             
      props.handleChangeGroups(props.tableId, props.viewId, _newGroup)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-groups-settings-menu-popover' : undefined; 

    return (
      <React.Fragment>
                                  
        <Tooltip title="Настроить группировку">
          {props.collapseHeaderButton ?
            <IconButton
              onClick={handleClickOpen}
              size="small"
              variant="text"
              className={(props.groups && props.groups.length > 0) ?classes.groupButton: null}
            >
              <ViewAgendaOutlinedIcon fontSize="small" />
            </IconButton>
            :
            <Button
              size="small"
              className={(props.groups && props.groups.length > 0) ?classes.groupButton: null}
              variant="text"
              startIcon={<ViewAgendaOutlinedIcon fontSize="small" />}
              onClick={handleClickOpen}
            >
              Группировка</Button>
          }
        </Tooltip>                           
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style:{
            minWidth:"150px"
          }
        }}          
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableColumns">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >  
       
          <List  
          dense={true} 
          subheader= {<ListSubheader>Группировка по </ListSubheader>}
          >
            {props.groups.map((group, idx)=>(
                  <Draggable key={group.column} draggableId={group.column} index={idx}>
                    {(provided, snapshot) => (

      <ListItem
        button
        ContainerComponent="li"
        ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}      
      >
      <ListItemIcon style={{minWidth:"35px"}}>
      <IconButton
          edge="end"
          onClick={(e)=>{            
            onDeleteGroup(idx)            
          }}
          size="small"
        >
        <HighlightOffIcon fontSize="small" />
        </IconButton>                  
      </ListItemIcon>
      <ListItemText id={"switch-groups-settings-label-"+idx} primary={group.name} />
      <ListItemSecondaryAction>
      <IconButton
          edge="end"
          onClick={(e)=>{            
            onChangeOrderGroup(idx, group.order)            
          }}
          size="small"
        >
        {group.order==="ASC"?<ArrowDownwardIcon fontSize="small" />: <ArrowUpwardIcon fontSize="small" />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
                    )}
                    </Draggable>    
    ))}
    {provided.placeholder}
    </List>            
    </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      <div>
          
                        <TextField
                        id="add_group_column_field"
                        label="Добавить колонку"
                        value={addedColumn}
                        onChange={onAddGroupColumn} 
                        helperText=""
                        fullWidth
                        select
                        size="small"
                        variant="filled"                      
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map(cl=>
                            <MenuItem key={cl._id} value={cl._id}>
                              {cl.name}
                            </MenuItem>
                          )}
                    </TextField>           
      </div>          
        </Popover>  
      </React.Fragment>
    );
  }    

  function SortsSettingsMenu(props) {
    const [addedColumn, setAddedColumn] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {      
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    const onAddSortColumn= (event) => {
      var colId = event.target.value
      var _newSort = [...props.sorts];
      _newSort.push({
        columnKey: colId,
        direction:"ASC" //'ASC' | 'DESC'
      })
      props.handleChangeSorts(props.tableId, props.viewId, _newSort)
      setAddedColumn(null)
  };


  const onDeleteSort= (idx) => {
    var _newSort = [...props.sorts];
    _newSort.splice(idx, 1)
    props.handleChangeSorts(props.tableId, props.viewId, _newSort)
  };  

  const onChangeDirectionSort= (idx, direction) => {
    var _newSort = [...props.sorts];
    if(direction==="ASC"){
      _newSort[idx].direction = 'DESC';
    } else {
      _newSort[idx].direction = 'ASC';
    }
    
    props.handleChangeSorts(props.tableId, props.viewId, _newSort)
  };    

  

  
  
    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      var _newSort = [...props.sorts];
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;

  
      _newSort.splice(
        targetIndex,
        0,
        _newSort.splice(sourceIndex, 1)[0]
      );             
      props.handleChangeSorts(props.tableId, props.viewId, _newSort)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-sorts-settings-menu-popover' : undefined; 

    return (
      <React.Fragment>
        
                

        <Tooltip title="Настроить сортировку">
          {props.collapseHeaderButton ?
            <IconButton
              onClick={handleClickOpen}
              size="small"
              variant="text"
              className={(props.sorts && props.sorts.length > 0) ? classes.sortButton: classes.viewButton}
            >
              <SortByAlphaIcon fontSize="small" />
            </IconButton>
            :
            <Button
              size="small"
              variant="text"
              className={(props.sorts && props.sorts.length > 0) ? classes.sortButton: classes.viewButton}
              startIcon={<SortByAlphaIcon fontSize="small" />}
              onClick={handleClickOpen}
            >
              Сортировка</Button>
          }
        </Tooltip>                   
            
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{
          style:{
            minWidth:"250px"
          }
        }}        
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableColumns">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef} >  
       
          <List  
          dense={true} 
          subheader={<ListSubheader>Сортировать по </ListSubheader>}
          >
            {props.sorts.map((sort, idx)=>(
                  <Draggable key={sort.columnKey} draggableId={sort.columnKey} index={idx}>
                    {(provided, snapshot) => (

      <ListItem
        button
        ContainerComponent="li"
        ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}      
      >

      <ListItemText id={"switch-sort-settings-label-"+idx} primary={sort.name} />
      <ListItemSecondaryAction>
        
          <IconButton
          edge="end"
          onClick={(e)=>{            
            onChangeDirectionSort(idx, sort.direction)            
          }}
          size="small"
        >
        {sort.direction==="ASC"?<ArrowDownwardIcon fontSize="small" />: <ArrowUpwardIcon fontSize="small" />}
        </IconButton>
        <IconButton
          edge="end"
          onClick={(e)=>{            
            onDeleteSort(idx)            
          }}
          size="small"
        >
        <HighlightOffIcon fontSize="small" />
        </IconButton>    
      </ListItemSecondaryAction>
    </ListItem>
                    )}
                    </Draggable>    
    ))}
    {provided.placeholder}
    </List>            
    </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      <div>
          
                        <TextField
                        id="add_sort_column_field"
                        label="Выберите колонку"
                        value={addedColumn}
                        onChange={onAddSortColumn} 
                        helperText=""
                        fullWidth
                        select
                        size="small"
                        variant="filled"                      
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {props.columns && props.columns.map(cl=>
                            <MenuItem key={cl._id} value={cl._id}>
                              {cl.name}
                            </MenuItem>
                          )}
                    </TextField>           
      </div>          
        </Popover>  
      </React.Fragment>
    );
  }   

  function TimelineViewSettingsMenu(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [titleColumns, setTitleColumns] = useState([]);
    const [dateColumns, setDateColumns] = useState([]);
    const [colorColumns, setColorColumns] = useState([]);    
    const [resourceColumns, setResourceColumns] = useState([]);  
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const onChangeTimelineResourceColumn = (event) => {
      props.handleChangeTimelineViewOptions("resourceColumn", event.target.value)     
   };

    const onChangeTitleColumn = (event) => {
     props.handleChangeTimelineViewOptions("titleColumn", event.target.value)     
  };

  const onChangeStartColumn = (event) => {
    props.handleChangeTimelineViewOptions('startColumn', event.target.value)     
       
};

const onChangeEndColumn = (event) => {
  props.handleChangeTimelineViewOptions("endColumn", event.target.value)     
   
};

const onChangeColorColumn = (event) => {
  props.handleChangeTimelineViewOptions("colorColumn", event.target.value)     
   
};


useEffect(()=>{  
  const _titleColumns = []
  const _dateColumns = []
  const _colorColumns = []
  const _resourceColumns=[]
  if(props.columns){        
    for(let ic=0; ic<props.columns.length; ic++){
      let col = props.columns[ic]
      if(col.type==='string' 
      || col.type==='text' 
      || col.type==='title' 
      || col.type==='multiline'
      || col.type==='member'
      || col.type==='select'   
      || col.type==='number'   
      ){
        _titleColumns.push(col)
      } 
      if(col.type==='date' || col.type==='datetime' || col.type==='due'){
        _dateColumns.push(col)
      } 
      if(col.type==='link' || col.type==='select' || col.type==='member' ){
        _resourceColumns.push(col)
      } 
      if(col.type==='select'){        
        _colorColumns.push(col)
      }
    }  

    setTitleColumns(_titleColumns)
    setDateColumns(_dateColumns)
    setColorColumns(_colorColumns)  
    setResourceColumns(_resourceColumns)
  } 

}, [props.columns])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Настройки события">
          {props.collapseHeaderButton ?
            <IconButton size="small" variant="text" onClick={handleClickOpen} >
              <SettingsIcon />
            </IconButton> :
            <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<SettingsIcon />}>
              Настроить
            </Button>
          }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
        <div className={classes.settingsPaper}>
        <div>Настройки события</div>
        <TextField
                        id="timeline_title_column"
                        label="Ресурсы"
                        disabled={props.accessLevel<5}
                        value={props.timelineColumnsOptions?props.timelineColumnsOptions.resourceColumn:''}
                        onChange={onChangeTimelineResourceColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {resourceColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>         
        <TextField
                        id="timeline_title_column"
                        label="Название"
                        disabled={props.accessLevel<5}
                        value={props.timelineColumnsOptions?props.timelineColumnsOptions.titleColumn:''}
                        onChange={onChangeTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="timeline_start_column"
                        label="Дата начала"
                        disabled={props.accessLevel<5}
                        value={props.timelineColumnsOptions?props.timelineColumnsOptions.startColumn:''}
                        onChange={onChangeStartColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                    <TextField
                        id="timeline_and_column"
                        label="Дата окончания"
                        disabled={props.accessLevel<5}
                        value={props.timelineColumnsOptions?props.timelineColumnsOptions.endColumn:''}
                        onChange={onChangeEndColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>    
                    
                    <TextField
                        id="timeline_color_column"
                        label="Цвет"
                        disabled={props.accessLevel<5}
                        value={props.timelineColumnsOptions?props.timelineColumnsOptions.colorColumn:''}
                        onChange={onChangeColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                        

          </div>     
          { false && <div>
          <DefaultItemValuesDialog 
            saveDefaultValues={props.saveDefaultValues} 
            members={props.members} 
            chatmembers={props.chatmembers}  
            columns={props.columns }
          tables={props.tables}
          tableId={props.tableId}
          viewId={props.viewId}
          defaultValues={props.defaultValues}
          //columns={props.defaultcolumns }
          accessLevel={props.accessLevel}
          />                  
                      </div> }                                                        
          <DialogActions>         
            <Button onClick={handleSave} color="primary">
              ОК
            </Button>                     
          </DialogActions>
          
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  } 

  function MapViewSettingsMenu(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [locationColumns, setLocationColumns] = useState([]); 
    const [titleColumns, setTitleColumns] = useState([]);
    const [colorColumns, setColorColumns] = useState([]);    
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const onChangeLocationColumn = (event) => {
      props.handleChangeMapViewOptions("location", event.target.value)     
   };

    const onChangeTitleColumn = (event) => {
     props.handleChangeMapViewOptions("titleColumn", event.target.value)     
  };


const onChangeColorColumn = (event) => {
  props.handleChangeMapViewOptions("colorColumn", event.target.value)     
   
};


useEffect(()=>{  
  const _titleColumns = []

  const _colorColumns = []
  const _locationColumns=[]
  if(props.columns){        
    for(let ic=0; ic<props.columns.length; ic++){
      let col = props.columns[ic]
      if(col.type==='string' 
      || col.type==='text' 
      || col.type==='title' 
      || col.type==='multiline'
      || col.type==='member'
      || col.type==='select'   
      || col.type==='number'   
      ){
        _titleColumns.push(col)
      } 
      if(col.type==='select'){        
        _colorColumns.push(col)
      }
      if(col.type==='geolocation'){        
        _locationColumns.push(col)
      }      
    }  

    setTitleColumns(_titleColumns)
    setColorColumns(_colorColumns)  
    setLocationColumns(_locationColumns)
  } 

}, [props.columns])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Настройки для представления даных на карте">
          {props.collapseHeaderButton ?
            <IconButton size="small" variant="text" onClick={handleClickOpen} >
              <SettingsIcon />
            </IconButton> :
            <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<SettingsIcon />}>
              Настроить
            </Button>
          }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
        <div className={classes.settingsPaper}>
        <div>Настройки для представления на карте</div>
        <TextField
                        id="map_title_column"
                        label="Координаты"
                        disabled={props.accessLevel<5}
                        value={props.mapOptions?props.mapOptions.location:''}
                        onChange={onChangeLocationColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {locationColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>         
        <TextField
                        id="map_title_column"
                        label="Название"
                        disabled={props.accessLevel<5}
                        value={props.mapOptions?props.mapOptions.titleColumn:''}
                        onChange={onChangeTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    
                    <TextField
                        id="map_color_column"
                        label="Цвет"
                        disabled={props.accessLevel<5}
                        value={props.mapOptions?props.mapOptions.colorColumn:''}
                        onChange={onChangeColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                        

          </div>     
          { false && <div>
         <DefaultItemValuesDialog 
            saveDefaultValues={props.saveDefaultValues} 
            members={props.members} 
            chatmembers={props.chatmembers}  
            columns={props.columns }
          tables={props.tables}
          tableId={props.tableId}
          viewId={props.viewId}
          defaultValues={props.defaultValues}
          //columns={props.defaultcolumns }
          accessLevel={props.accessLevel}
          />                  
                      </div>  }                                                       
          <DialogActions>         
            <Button onClick={handleSave} color="primary">
              ОК
            </Button>                     
          </DialogActions>
          
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  } 

  function CalendarViewSettingsMenu(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [titleColumns, setTitleColumns] = useState([]);
    const [dateColumns, setDateColumns] = useState([]);
    const [colorColumns, setColorColumns] = useState([]);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    const onChangeTitleColumn = (event) => {
     props.handleChangeCalendarViewOptions("titleColumn", event.target.value)     
  };

  const onChangeStartColumn = (event) => {
    props.handleChangeCalendarViewOptions('startColumn', event.target.value)     
       
};

const onChangeEndColumn = (event) => {
  props.handleChangeCalendarViewOptions("endColumn", event.target.value)     
   
};

const onChangeColorColumn = (event) => {
  props.handleChangeCalendarViewOptions("colorColumn", event.target.value)     
   
};


useEffect(()=>{  
  const _titleColumns = []
  const _dateColumns = []
  const _colorColumns = []
  if(props.columns){        
    for(let ic=0; ic<props.columns.length; ic++){
      let col = props.columns[ic]
      if(col.type==='string' 
      || col.type==='text' 
      || col.type==='title' 
      || col.type==='multiline'
      || col.type==='member'
      || col.type==='select'  
      || col.type==='number'    
      ){
        _titleColumns.push(col)
      } 
      if(col.type==='date' || col.type==='datetime' || col.type==='due'){
        _dateColumns.push(col)
      } 
      if(col.type==='select'){
        _colorColumns.push(col)
      }
    }  

    setTitleColumns(_titleColumns)
    setDateColumns(_dateColumns)
    setColorColumns(_colorColumns)  
  } 

}, [props.columns])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Настройки события">
          {props.collapseHeaderButton ?
            <IconButton size="small" variant="text" onClick={handleClickOpen} >  
            <SettingsIcon />                                
        </IconButton>   :
        <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<SettingsIcon /> }>  
            Настроить
        </Button>         
        }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
        <div className={classes.settingsPaper}>
        <div>Настройки события</div>
        <TextField        
                        id="calendar_title_column"
                        label="Название"
                        disabled={props.accessLevel<5}
                        value={props.calendarColumnsOptions?props.calendarColumnsOptions.titleColumn:''}
                        onChange={onChangeTitleColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {titleColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                    <TextField
                        id="calendar_start_column"
                        label="Дата начала"
                        disabled={props.accessLevel<5}
                        value={props.calendarColumnsOptions?props.calendarColumnsOptions.startColumn:''}
                        onChange={onChangeStartColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                    <TextField
                        id="calendar_and_column"
                        label="Дата окончания"
                        disabled={props.accessLevel<5}
                        value={props.calendarColumnsOptions?props.calendarColumnsOptions.endColumn:''}
                        onChange={onChangeEndColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {dateColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>    
                    
                    <TextField
                        id="calendar_color_column"
                        label="Цвет"
                        disabled={props.accessLevel<5}
                        value={props.calendarColumnsOptions?props.calendarColumnsOptions.colorColumn:''}
                        onChange={onChangeColorColumn} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        variant="outlined"
                        size='small'
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}>                                                
                        {colorColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                        

          </div>     
          { false && <div>
          <DefaultItemValuesDialog 
            saveDefaultValues={props.saveDefaultValues} 
            members={props.members} 
            chatmembers={props.chatmembers}  
            columns={props.columns }
          tables={props.tables}
          tableId={props.tableId}
          viewId={props.viewId}
          defaultValues={props.defaultValues}
          accessLevel={props.accessLevel}
          />                  
                      </div>  }                                                                 
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }  

  function DialogViewMenu(props) {
    const [anchorElType, setAnchorElType] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 
    const history = useHistory(); 

    const handleClickType = (event) => {
      setAnchorElType(event.currentTarget);
  
    };
    const handleCloseType = (event) => {
      setAnchorElType(null);
    };  

    const handleToggle = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (event) => {

  
      setAnchorEl(null);
    };

    const handleClickView = (event) => {
      if(props.dialog && props.dialog.filter){
        history.push(`/db/${props.dbId}/${props.tableId}/${props.dialog.filter}`);
      }
    };

    const handlChangeTypeClick = (newType) => {

      props.onChangeDialog("type", newType) 
      setAnchorElType(null);
    };  
    const handlChangeFilterClick = (newFilter) => {

      props.onChangeDialog("filter", newFilter) 
      setAnchorEl(null);
    };  

    const dialogTypeTitle = {
      simple:"Отправка сообщений (без данных)",
      standup:"Добавление элемента в",
      update_item_request:"Обновление элемента в",
      update_request: "Вывод или обновление списка элементов из"
    }


    return (
      <React.Fragment>
        
        <Tooltip title="Установить действие диалога">

            <ButtonGroup  
              color="inherit" 
              ariant="text" 
            >         
            <Button  
            size="small" 
            variant="text" 
            onClick={handleClickType} 
            className={classes.menuButton}
            style={{
              marginRight:0
            }}
            > 
            {props.dialog?dialogTypeTitle[props.dialog.type]: ""} 
            </Button>     
           {props.dialog && props.dialog.type!=="simple" && 
           <>      
            <Button 
            size="small"
            //variant="outlined" 
            //startIcon={<MenuIcon fontSize="small"/>}
            //size="small" 
            //href={(props.dialog && props.dialog.filter) ? :null}
            variant="text" 
            onClick={handleClickView} 
            className={classes.menuButton}
            style={{
              marginRight:0
            }}
            >
              {(props.dialog && props.views && props.dialog.filter)?props.views[props.dialog.filter].title: ""} 
  </Button> 
  <Button         
  variant="text"
  size="small"
  className={classes.menuButton}
  onClick={handleToggle}
  style={{
    minWidth:16
  }}
  >            
  <ArrowDropDownIcon fontSize='small'/>
                  </Button>
                  </>}
  </ButtonGroup>             
        </Tooltip> 
        <Menu
          id="rdialog-view-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
                  <MenuList id="dialog-view-list-menu">
                    {props.viewsList && props.viewsList.map((view, index) => (
                      <MenuItem
                        key={view._id}
                        //disabled={index === 2}
                        selected={props.dialog && props.dialog.filter === view._id}
                        onClick={(event) => handlChangeFilterClick(view._id)}
                      >
                        {view.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu> 
                <Menu
          id="row-height-menu"
          anchorEl={anchorElType}
          keepMounted
          open={Boolean(anchorElType)}
          onClose={handleCloseType}
        >
          <MenuList id="row-height-menu-list" dense>
            <MenuItem 
              key="simple" 
              value="simple"
              selected={props.dialog && props.dialog.type === "simple"}
              onClick={(event) => handlChangeTypeClick("simple")}
            >Простой</MenuItem>          
            <MenuItem 
            key="standup" 
            value="standup"
            selected={props.dialog && props.dialog.type === "standup"}
            onClick={(event) => handlChangeTypeClick("standup")}
            >Добавление элемента</MenuItem>          
            <MenuItem 
            key="update_item_request" 
            value="update_item_request"
            selected={props.dialog && props.dialog.type === "update_item_request"}
            onClick={(event) => handlChangeTypeClick("update_item_request")}
            >Обновление элемента</MenuItem>          
            <MenuItem 
            key="update_request" 
            value="update_request"
            selected={props.dialog && props.dialog.type === "update_request"}
            onClick={(event) => handlChangeTypeClick("update_request")}
            >Вывод или обновление списка элементов</MenuItem>
          </MenuList>
        </Menu>            
      </React.Fragment>
    );
  } 


  function DialogViewSettingsMenu(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const onChangeHandler = (event) => {
     props.onChangeDialog(event.target.name, event.target.value)     
  };

  const onChangeOnExternalItems = (event) => {
    const v = event.target.checked;    
    props.onChangeDialog('on_external_items', v)          
    //setDialogEdited(true);       
}    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Установить видимость колонок">
          {props.collapseHeaderButton ? 
          <IconButton size="small" variant="text" onClick={handleClickOpen} >
            <SettingsIcon />
          </IconButton> :
            <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<SettingsIcon />}>              
                Настроить
            </Button>
          }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
         {props.dialog && <div className={classes.settingsPaper}>
        <div >Настройте параметры диалога</div>

        <TextField
                            id="dialog-type"
                            name="type"
                            label="Тип диалога"
                            value={props.dialog.type}
                            onChange={onChangeHandler}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={props.accessLevel<5}
                        >
            <MenuItem key="simple" value="simple">Простой</MenuItem>          
            <MenuItem key="standup" value="standup">Добавление элемента</MenuItem>          
            <MenuItem key="update_item_request" value="update_item_request">Обновление элемента</MenuItem>          
            <MenuItem key="update_request" value="update_request">Вывод списка элементов</MenuItem>          
  </TextField> 

        {(props.dialog.type==="update_request" || props.dialog.type==="update_item_request" || props.dialog.type==="standup") &&

                        <TextField
                            id="dialog-filter"
                            name="filter"
                            label={"Представление"}
                            value={props.dialog.filter}
                            onChange={onChangeHandler}
                            placeholder="Выберите представление данных"
                            helperText=""
                            fullWidth
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={props.accessLevel<5}
                        >
                        {props.views && props.views.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.title}
                            </MenuItem>
                          ))}
  </TextField> 
                    }
                    
      {(props.dialog.type==="update_request" || props.dialog.type==="standup" || props.dialog.type==="update_item_request") 
      &&     
                        
                        <TextField
                        id="dialog-respondents_column"
                        label={props.dialog.type==="standup"?"Записывать пользователя в поле:": "И текущий пользователь указан в поле:"}
                        name="respondents_column"
                        value={props.dialog.respondents_column}
                        onChange={onChangeHandler} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        className={classes.selectEmpty}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        SelectProps={{
                          displayEmpty:true
                        }}                        
                        disabled={props.accessLevel<5}
                        >      
                            <MenuItem key="dialog-respondents_column" value={null}>
                              -
                            </MenuItem>                                                                    
                        {props.memberColumns && props.memberColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> }
                    {(props.dialog.type==="update_request" || props.dialog.type==="standup" || props.dialog.type==="update_item_request") 
      &&     
                        
                        <TextField
                        id="dialog-parent_column"
                        label={props.dialog.type==="standup"?"Записывать родителя в поле:": "И текущий родитель указан в поле:"}
                        name="parent_column"
                        value={props.dialog.parent_column}
                        onChange={onChangeHandler} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        className={classes.selectEmpty}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        SelectProps={{
                          displayEmpty:true
                        }}                        
                        disabled={props.accessLevel<5}
                        >      
                            <MenuItem key="dialog-parent_column" value={null}>
                              -
                            </MenuItem>                                                                    
                        {props.linkColumns && props.linkColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> }                    
          {props.dialog.type==="standup" && <>
            <FormControlLabel
                        control={
                          <Switch
                          checked={props.dialog.on_external_items}
                          onChange={onChangeOnExternalItems}
                          id="dialog-on_external_items"
                          color="primary"
                          disabled={props.accessLevel<5}
                        />                          
                        }
                        label="На основании данных таблицы"
                    />                
                   
          {props.dialog.on_external_items && props.linkColumns && <>
                        
                        <TextField
                        id="dialog-external_items_column"
                        label="Реквизит-источник:"
                        name="external_items_column"
                        value={props.dialog.external_items_column}
                        onChange={onChangeHandler} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select
                        className={classes.selectEmpty}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={props.accessLevel<5}
                        >                                                                        
                        {props.linkColumns.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField> 
                                        

          {props.dialog.external_items_column 
          && props.linkViews 
          && props.linkViews[props.dialog.external_items_column] && <>       

                          <TextField
                          id="dialog-external_items_view"
                          label="Вид-источник:"
                          name="external_items_view"
                          value={props.dialog.external_items_view}
                          onChange={onChangeHandler} 
                          placeholder="Выберите колонку"
                          helperText=""
                          fullWidth
                          select
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          disabled={props.accessLevel<5}
                          >                                                
                          { props.linkViews[props.dialog.external_items_column].map((option) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.title}
                              </MenuItem>
                            ))}
                      </TextField>                                            

                        <TextField
                        id="dialog-external_items_respondent_column"
                        label="И текущий пользователь указан в параметре:"
                        name="external_items_respondent_column"
                        value={props.dialog.external_items_respondent_column}
                        onChange={onChangeHandler} 
                        placeholder="Выберите колонку"
                        helperText=""
                        fullWidth
                        select                        
                        className={classes.selectEmpty}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        SelectProps={{
                          displayEmpty:true
                        }}
                        disabled={props.accessLevel<5}
                        >       
                            <MenuItem key="dialog-external_items_respondent_column_empty" value={null}>
                              -
                            </MenuItem>                                                                 
                        {props.linkMemberColumns && props.linkMemberColumns[props.dialog.external_items_column].map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>                        
                      </>
                      }
                      </>}
                      </>                    
                    }   
                    </div> }
                                        
          <DialogActions>         
            <Button disabled={props.accessLevel<5} onClick={handleSave} color="primary">
              ОК
            </Button>                     
          </DialogActions>
          
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }    

  function ShareDialogSettingsMenu(props) {
    const [chatViewLinksByBot, setChatViewLinksByBot] = useState([]);
    const [hasShare, setHasShare] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const onChangeRunByURL = (event, channelId, chatViewLinkId) => {
      console.log('onChangeRunByURL ',chatViewLinkId)
      const v = event.target.checked;
      if(v){
        props.createChatViewLink(props.tableId, {
          botchannel:channelId,
          viewId:props.viewId
        })
      } else {
        props.deleteChatViewLink(props.tableId, chatViewLinkId)
      }
  
      //props.onChangeDialog('run_by_url', v)  
      //setDialogEdited(true);        
    }  

  useEffect(()=>{
  var _chatViewLinksByBot = []
  var _hasShare = false
  if(props.botchannel){        
    
      let botchannel = props.botchannel
      let chatViewLink = props.chatViewLinks.find(e=> (e.botchannel===botchannel._id && e.work_item_view===props.viewId))
      
      _chatViewLinksByBot.push({
        ...botchannel,
        chatViewLink: chatViewLink
      })
      if(chatViewLink && chatViewLink._id){
        _hasShare =true
      }

    //console.log('ViewsConfigList views', views);
  } 
  //console.log('_chatViewLinksByBot ', _chatViewLinksByBot);
  setHasShare(_hasShare)
  setChatViewLinksByBot(_chatViewLinksByBot)
  
}, [props.chatViewLinks, props.botchannel, props.viewId])


    const open = Boolean(anchorEl);
    const id = open ? 'share-dialog-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Поделиться диалогом для запуска">
          {props.collapseHeaderButton ?
            <IconButton 
            size="small" 
            variant={hasShare?"contained":"text"}
            color={hasShare ?"primary":"default"} 
            onClick={handleClickOpen} >              
            <ShareIcon fontSize='small'/>                                   
        </IconButton> :
        <Button 
            size="small" 
            variant={hasShare?"contained":"text"}
            color={hasShare ?"primary":"default"} 
            onClick={handleClickOpen} 
            startIcon={<ShareIcon /> }>              
              Поделиться            
        </Button>         
        }
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
          <div className={classes.settingsPaper}>        
<div>
<FormControl component="fieldset" size="small">                      
                            {chatViewLinksByBot && chatViewLinksByBot.map( channel =>(
                              
      <FormControlLabel
        control={<Switch size="small" disabled={props.accessLevel<5} checked={channel.chatViewLink && channel.chatViewLink._id} onChange={e=>onChangeRunByURL(e, channel._id, channel.chatViewLink?channel.chatViewLink._id:null)} name={"run_by_url_"+channel._id} />}
        label={<span>Запускать диалог по URL {channel.chatViewLink?(<span> <a href={channel.chatViewLink.url} target="_blank">{channel.chatViewLink.url}</a></span>):""}</span>}
      />    )
                            )}
     

      </FormControl>          
           

                                                          
                      
  </div> 
                    </div> 
                                              
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }   
  
  function ShareViewSettingsMenu(props) {    
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles(); 

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const onChangeShareView = (event) => {

      const v = event.target.checked;
      if(v){
        props.createViewShareLink(props.tableId, {
          viewId:props.viewId
        })
      } else {
        props.deleteViewShareLink(props.tableId, {
          viewId:props.viewId
        })
      }
    }  

    const open = Boolean(anchorEl);
    const id = open ? 'share-view-popover' : undefined;

    return (
      <React.Fragment>
        
        <Tooltip title="Поделиться представлением для просмотра">
            <IconButton 
            size="small" 
            variant={props.shareLinkId?"contained":"text"}
            color={props.shareLinkId ?"primary":"default"} 
            onClick={handleClickOpen}             
            >  
            <ShareIcon fontSize="small"
            />                                  
        </IconButton>   
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <Paper style={{maxWidth:"350px"}} >
            <div className={classes.settingsPaper}>
              <div>
                <FormControl component="fieldset" size="small">
                  {props.viewId &&
                    <FormControlLabel
                      control={<Switch size="small" disabled={props.accessLevel < 5} checked={props.shareLinkId} onChange={onChangeShareView} name={"share_by_url_" + props.viewId} />}
                      label={<span>Ссылка для просмотра 
                        {props.shareLinkId ? 
                        (<span> <a href={"https://app.projecton.xyz/shared/" + props.shareLinkId} target="_blank">{"https://app.projecton.xyz/shared/" + props.shareLinkId}</a>
                        
                        </span>) : ""}                    
                      </span>}
                    />
                  }
                </FormControl>
              </div>
              {props.shareLinkId && <DialogActions >
              <CopyToClipboard text={"https://app.projecton.xyz/shared/" + props.shareLinkId}>
                    <Button size="small" 
                    startIcon={<FilterNoneIcon fontSize="small" />}
                    >
                      Копировать
                    </Button>
                  </CopyToClipboard>  
              </DialogActions>}
            </div>

          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }     

  function DialogTypeSettingsMenu(props) {
    const [dialogType, setDialogType] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const handleChangeDialogType = (dtype) => {     
     props.onChangeDialog('type', dtype)   
     handleClose()
  };


  useEffect(()=>{ 
    var __dialogType = ""
    if(props.dialogType === "simple"){
      __dialogType = "Простой"
    } else if(props.dialogType === "standup"){
      __dialogType = "Добавление элемента"
    }  else if(props.dialogType === "update_item_request"){
      __dialogType =  "Обновление элемента"
    }  else if(props.dialogType === "update_request"){      
      __dialogType = "Вывод списка элементов"
    }
    setDialogType(__dialogType)
    
  }, [props.dialogType])

    return (
      <React.Fragment>
        <Tooltip title="Тип диалога">
          <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<ViewWeekIcon />}>
            <Box display={{ xs: 'none', sm: 'block' }}>
              {dialogType ?  dialogType : 'Не установлен'}
            </Box>
          </Button>
        </Tooltip>     

        <Menu
          id="dialog-type-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuList id="dialog-type-menu-list" dense>
            <MenuItem onClick={(e)=>{handleChangeDialogType("simple")}}>Простой</MenuItem>          
            <MenuItem onClick={(e)=>{handleChangeDialogType("standup")}}>Добавление элемента</MenuItem>          
            <MenuItem onClick={(e)=>{handleChangeDialogType("update_item_request")}}>Обновление элемента</MenuItem>          
            <MenuItem onClick={(e)=>{handleChangeDialogType("update_request")}}>Вывод списка элементов</MenuItem>          
          </MenuList>
        </Menu>        
      </React.Fragment>
    );
  }   

  function GroupBySettingsMenu(props) {
    const [groupByName, setGroupByName] = React.useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const handleChangeGroupBy = (key) => {
     props.handleChangeGroupBy(props.tableId, props.viewId, key)     
     handleClose()
  };


  useEffect(()=>{ 
    if(props.groupBy){
      var groupCol = props.columns.find(cl=> cl._id===props.groupBy)
      if(groupCol){
        setGroupByName(groupCol.name)
        return
      }
      
    }
    setGroupByName("")
    
  }, [props.groupBy])

    return (
      <React.Fragment>
        <Tooltip title="Группировать по колонке">
          {props.collapseHeaderButton ?
            <IconButton size="small" variant="text" onClick={handleClickOpen} >
              <ViewWeekIcon />
            </IconButton>
            :
            <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<ViewWeekIcon />}>
              {groupByName ? ('По ' + groupByName) : 'Не сгруппировано'}

            </Button>
          }
        </Tooltip>     

        <Menu
          id="groupby-view-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.columns && props.columns.map((column, idx)=>((column.type==='select' || column.type==='member' || column.type==='chatmember' || column.type==='link' ) &&
            <MenuItem onClick={(e)=>{handleChangeGroupBy(column._id)}}>
              <ColumnTypeIcon type={column.type} />
                  {column.name}
              </MenuItem>
          ))}
        </Menu>        
      </React.Fragment>
    );
  }   

  function datenum(v, date1904) {
    var basedate = new Date(1899, 11, 30, 0, 0, 0); // 2209161600000
    var epoch = v.getTime();
    if(date1904) epoch -= 1462*24*60*60*1000;
    var dnthresh = basedate.getTime() + (v.getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
    return (epoch - dnthresh) / (24 * 60 * 60 * 1000);
  }

  function encode_cell(cell) {
    var col = cell.c + 1;
    var s="";
    for(; col; col=((col-1)/26)|0) s = String.fromCharCode(((col-1)%26) + 65) + s;
    return s + (cell.r + 1);
  }  

  function ViewPermissionSettings(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [ownerName, setOwnerName] = useState("");
  
    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
    
    
    const handleChangePermissionType = (newType) => {
      props.updateView(props.tableId, props._id, {permissionType: newType})
      handleClose(null)
      props.handleClose()
    };    

    const handleClose = () => {
      setAnchorEl(null);
    };  

    useEffect(()=>{
      var __owner_name = ""
     //console.log('ViewPermissionSettings useEffect ', props.owner, props.members)
      if(props.owner && props.members){
        
        let __owner = props.members.find(o => o._id === props.owner)
        if(__owner && __owner.name){
          __owner_name = __owner.name
        }
      }
      setOwnerName(__owner_name)
      //setIcon(props.icon)
      
    }, [props.owner, props.members])    
    
    

    return (
      <React.Fragment>

        <MenuItem disabled={props.accessLevel < 5} onClick={handleClickOpen}>
          <ListItemIcon>
            {props.permissionType === "c" ? <GroupIcon size="small" /> :
              props.permissionType === "p" ? <PersonIcon size="small" /> :
                <LockIcon size="small" />}
          </ListItemIcon> 
          <ListItemText
                  //id={"tb-el-label-" + idx}
                  primary= {props.permissionType === "c" ? "Совместный доступ" :
                  props.permissionType === "p" ? "Персональный доступ" :
                  "Заблокированный доступ"} 
                  secondary= {props.permissionType === "c" ? "Редакторы и выше могут изменять настройки" :
                  props.permissionType === "p" ? `Только ${ownerName} может изменять настройки` :
                  "Только админы могут изменять настройки"} 
                //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
                />          
 
                <ListItemSecondaryAction
                //onClick={ e => { props.setViewDialogs(!props.viewDialogs) }}
                >
                  <ArrowRightIcon fontSize="small"/>
                  
                </ListItemSecondaryAction>                 
        </MenuItem>                       
        <Popover
          id={'Popover-ViewPermission-settings'}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{ style:{padding: "5px"} }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <div>
            
            <List
              dense>
              <ListItem
                disableGutters
                button
                onClick={e=>handleChangePermissionType("c") }
              >
                <ListItemIcon> 
                  <GroupIcon size="small" />
                </ListItemIcon>
                <ListItemText
                  //id={"tb-el-label-" + idx}
                  primary="Совместный доступ"
                  secondary="Редакторы и выше могут изменять настройки"
                //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
                />
                {props.permissionType === "c" && <ListItemSecondaryAction
                //onClick={ e => { props.setViewDialogs(!props.viewDialogs) }}
                >
                  <CheckIcon fontSize="small"/>
                  
                </ListItemSecondaryAction>}

              </ListItem>
              <ListItem
                disableGutters
                button
                onClick={e=>handleChangePermissionType("p") }
              >
                <ListItemIcon> 
                  <PersonIcon size="small" />
                </ListItemIcon>
                <ListItemText
                  //id={"tb-el-label-" + idx}
                  primary="Персональный доступ"
                  secondary="Только вы можете изменять настройки"
                //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
                />
                {props.permissionType === "p" && <ListItemSecondaryAction
                //onClick={ e => { props.setViewDialogs(!props.viewDialogs) }}
                >
                  <CheckIcon fontSize="small"/>
                  
                </ListItemSecondaryAction>}

              </ListItem>
              <ListItem
                disableGutters
                button
                onClick={e=>handleChangePermissionType("l") }
              >
                <ListItemIcon> 
                  <LockIcon size="small" />
                </ListItemIcon>
                <ListItemText
                  //id={"tb-el-label-" + idx}
                  primary="Заблокированный доступ"
                  secondary="Только админы могут изменять настройки"
                //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
                />
                {props.permissionType === "l" && <ListItemSecondaryAction
                //onClick={ e => { props.setViewDialogs(!props.viewDialogs) }}
                >
                   <CheckIcon fontSize="small"/>
                  
                </ListItemSecondaryAction>}

              </ListItem>              
            </List>      
          </div>
        </Popover>
      </React.Fragment>
    )
  }  

  function ChangeViewOwnerDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [owner, setOwner] = React.useState(null);
    const handleClose = () => {

      setOpen(false)
        
      };

      const handleCancel = () => {

        handleClose()
          
      };

      const handleSave = () => {

        props.updateView(props.tableId, props._id, {owner: owner})
        handleClose()
      };   
      
      const handleNotSave = () => {

        setOpen(false)
      }; 
      
      useEffect(()=>{
        setOwner(props.owner)
        //setIcon(props.icon)
      }, [props.owner])
      
    return (
      <React.Fragment >    
                <MenuItem disabled={props.accessLevel<5} onClick={e=>setOpen(true)}>               
                <ListItemIcon>                  
                </ListItemIcon> Изменить владельца для представления
              </MenuItem>                  
      <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Изменить владельца для представления</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Укажите нового владельца для персонального представления:            
          </DialogContentText>
          <DialogContentText>
          <MemberFormEditor
                    value={props.owner}
                    inForm={true}
                    onChange={value => {
                      setOwner(value)
                    }}
                    members={props.members}
                    //rowHeight={"25px"}
                    //menuPortalTarget={p.editorPortalTarget}
                    //onCreateOption={handleCreateColumnOpinion}
                    noOptionsMessage="Нет значений для выбора!"
                  />            
            </DialogContentText>
          </DialogContent>
          <DialogActions>        
            <Button onClick={handleNotSave} color="primary">
              Отмена
            </Button>            
            <Button onClick={handleSave} color="primary">
              Ок
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  }  


  function ViewSettingsPopover(props) {
    const [title, setTitle] = useState("");
    //const [icon, setIcon] = useState("");
    //const [viewEmoji, setViewEmoji] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClickOpen = (event) => {
      if(props.accessLevel>1){
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
      }
    };

    const onChangeTitle = (event) => {
      setTitle(event.target.value)
    }

    const onChangeTitleHandler = (event) =>{
      //console.log('event.target.name',event.target.name);
      if(event.target.value!==props.title){
        props.updateView(props.tableId, props._id, {title: event.target.value})
      }
      
  }
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };



    const handleDel = () => {
      props.handleDeleteView(props.tableId, props._id);
      handleClose();
    };  
  
    const handleCopy = () => {
      props.handleCopyView(props.tableId, props._id); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
      handleClose();
    }

    const exportJS = () => {
      handleClose();
      const headers = props.viewColumns.map(vc=> vc._id)//Object.keys(data[0]);
  
      const wb = XLSX.utils.book_new(); // book
      //const ws = XLSX.utils.json_to_sheet(props.sortedAndFilteredViewItems, { header: headers }); // sheet
      const ws = {
        '!cols':[],
        '!ref': "" //"A1:Z14"
      }
      var icc = -1;
      
      for(let ic=0; ic<props.viewColumns.length; ic++){
        let col = props.viewColumns[ic]
        if(col.width>0){
          icc++;
          ws['!cols'].push({
            hidden: false,
            wpx: col.width,
            _id: col._id,
            check_true: col.check_true,
            check_false: col.check_false,
            type: col.type,
            lookupId:col.lookupId,
            lookupField:col.lookupField,
            linkId: col.linkId,
            linkViewField: col.linkViewField,
            date_format:  col.date_format
            //title: col.name 
          })
          ws[encode_cell({c:icc,r:0})] = {
            t:"s",
            v:col.name
          }
        }
      }

      for(let ir=0; ir<props.sortedAndFilteredViewItems.length; ir++){
        let item = props.sortedAndFilteredViewItems[ir]
        for(let ic=0; ic<ws['!cols'].length; ic++){
          var v = item[ws['!cols'][ic]._id];
          var t = 'z';
          var z = "";
          var ref = encode_cell({c:ic,r:ir+1});
          let cell = XLSX.utils.sheet_get_cell(ws, ref);

            if(ws['!cols'][ic].type==="text" || ws['!cols'][ic].type==="title"){
              t = 's';
           
            } else if(ws['!cols'][ic].type==="member"){
              t = 's';
              if(v && props.members){
                var _mem = props.members.find(r => r._id === v)
                if(_mem){
                  v = _mem.name
                }                
              }              
            } else if(ws['!cols'][ic].type==="select"){
              t = 's';
            } else if(ws['!cols'][ic].type==="lookup"){
              t = 's';
              if(ws['!cols'][ic].lookupId && ws['!cols'][ic].lookupField){
                const lookupColumn = props.columns.find(cl=> cl._id === ws['!cols'][ic].lookupId)
                const lookupField = ws['!cols'][ic].lookupField
                if(lookupColumn && lookupColumn.linkId && lookupField){
                  
                    if(props.tablesValues[lookupColumn.linkId] && item[lookupColumn._id]){
                      let lookupitem = props.tablesValues[lookupColumn.linkId].items[item[lookupColumn._id]]
                      //if(item[lookupField]){
                      // val = item[lookupField]
                      //}
                      if(lookupitem && lookupitem[lookupField]){
                        var fieldCol = props.tablesValues[lookupColumn.linkId].columns.find(cl=> cl._id===lookupField)
                        v = lookupitem[lookupField]
                        if(fieldCol && fieldCol.type==='member'){
                          
                          var member = props.members.find(mb=> mb._id==lookupitem[lookupField])
                          //console.log('fieldCol member ', fieldCol, item[lookupField], props.members, member)
                          if(member){
                            v = member.name
                          }
                           
                        }
                        
                      }                  
                    }           
              } else {
                v = ""
              }
            } else {
              v = ""
            }                               
            } else if(ws['!cols'][ic].type==="link"){
              t = 's';
              if(props.tablesValues[ws['!cols'][ic].linkId] && props.tablesValues[ws['!cols'][ic].linkId].columns){
                var titleCol = ws['!cols'][ic].linkViewField 
                if(titleCol){
                  var option = v?props.tablesValues[ws['!cols'][ic].linkId].items[v]:null;
                  v = option?option[titleCol]:""
                } else {
                  v=""
                }
              } else {
                v=""
              }
            } else if(ws['!cols'][ic].type==="number"){
              t = 'n'
            } else if(ws['!cols'][ic].type==="geolocation"){
              t = 's';
              v = v?`${v.longitude}, ${v.latitude}`:""
            } else if(ws['!cols'][ic].type==="attachments"){
              t = 's';
              v = ""
            } else if(ws['!cols'][ic].type==="check"){
              if(ws['!cols'][ic].check_true){
                v=v?ws['!cols'][ic].check_true:ws['!cols'][ic].check_false
                t = 's';
              } else {
                t= 'b'
              }              
            } else if(ws['!cols'][ic].type==="date"){
              if(v instanceof Date) {
                t = 'n'; 
                v = datenum(v); 
                z = ws['!cols'][ic].date_format
              } else if(v) {
                t = 'n'; 
                v = datenum(new Date(v)); 
                z = ws['!cols'][ic].date_format                
              } else {
                v = ""
              }              
            } else if(ws['!cols'][ic].type==="multiline"){
              t = 's';
            } else {
            }

            if(!cell) 
              ws[ref] = cell = ({t:t, v:v});
            else {
              cell.t = t; cell.v = v;
              delete cell.w; delete cell.R;
              if(z) cell.z = z;
            }
            if(z) cell.z = z;
          }          
      }

      ws['!ref'] = "A1:"+encode_cell({c:icc,r:props.sortedAndFilteredViewItems.length+1}) 

      //XLSX.utils.sheet_add_json(_ws, js, opts)

      //console.log('ExportToExcelButton ', ws)
      XLSX.utils.book_append_sheet(wb, ws, title); // 底部的 sheet name      
      XLSX.writeFile(wb, props.dbtitle+".xlsx");
    };    

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(()=>{
      setTitle(props.title)
      //setIcon(props.icon)
    }, [props.title])

    return (
      <React.Fragment>
        <Tooltip title={'Нажмите, чтобы отредактировать'}>
        <ListItem
        disableGutters
        button        
        
        ContainerComponent="div"
        style={{
          paddingTop:0,
          paddingBottom:0
        }}
      >
      <ListItemIcon style={{minWidth:"25px"}}

      >
      {props.viewtype==='list' && <IconForViewList/>}
    {props.viewtype==='kanban' && <IconForViewKanban/>}
    {props.viewtype==='calendar' && <IconForViewCalendar/>}
    {props.viewtype==='form' && <IconForViewForm/>}
    {props.viewtype==='dialog' && <IconForViewBot />}
    {props.viewtype==='dashboard' && <IconForViewDashboard />}
    {props.viewtype==='external' && <IconForExternalContent />}
    {props.viewtype==='page' && <IconForViewPage />}
    {props.viewtype==='map' && <IconForMap />}
    {props.viewtype==='timeline' && <IconForViewTimeline />}
    

      </ListItemIcon>      
      <ListItemText 
      onClick={handleClickOpen} 
      onContextMenu={handleClickOpen}
      id={"view-view-label-"+props._id} 
      primary={<span>{props.title} 
        </span>} 
      primaryTypographyProps={{noWrap:true}}
      style={{
        maxWidth:200
      }}
      />
      <ListItemSecondaryAction       
      >
        {props.permissionType==="c"?<GroupIcon  style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.54)"}}/>:
        props.permissionType==="p"?<PersonIcon style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.54)"}}/>:
        <LockIcon  style={{fontSize:"0.8rem", color:"rgba(0, 0, 0, 0.40)"}}/>}

      </ListItemSecondaryAction>
    </ListItem>

        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        //        style={{minWidth:"250px"}}
        >
          <Paper elevation={3} >
          <div className={classes.settingsPaper}>
              <TextField
                autoFocus
                id={"table_title_field"}
                disabled={props.accessLevel<5}
                value={title}
                name="title"
                fullWidth
                onChange={onChangeTitle}
                onBlur={onChangeTitleHandler}
                placeholder="Название"
                helperText=""
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    props.updateView(props.tableId, props._id, {title: title}) // here was the mistake
                    handleClose()
                  }
                }}                
                //margin="normal"
                //variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {props.viewtype === 'list' && <IconForViewList />}
                      {props.viewtype === 'kanban' && <IconForViewKanban fontSize="small" />}
                      {props.viewtype === 'calendar' && <IconForViewCalendar/>}
                      {props.viewtype === 'form' && <IconForViewForm/>}
                      {props.viewtype === 'dialog' && <IconForViewBot />}
                      {props.viewtype === 'dashboard' && <IconForViewDashboard />}
                      {props.viewtype === 'external' && <IconForExternalContent />}
                      {props.viewtype === 'page' && <IconForViewPage />}
                      {props.viewtype === 'map' && <IconForMap />}
                      {props.viewtype === 'timeline' && <IconForViewTimeline />}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <MenuList id="view-title-edit-menu-list" dense>
              <ViewPermissionSettings 
              permissionType={props.permissionType} 
              updateView={props.updateView} 
              handleClose={handleClose}
              tableId={props.tableId}
              owner={props.owner}
              members={props.members}
              _id={props._id}
              />
              {props.permissionType === "p" && 
              <ChangeViewOwnerDialog  {...props}   />
            }
            <MenuItem disabled={props.accessLevel<5} onClick={handleCopy}>
              <ListItemIcon>
                <FilterNoneIcon fontSize="small" />
              </ListItemIcon> Копировать
            </MenuItem>
            <MenuItem disabled={props.accessLevel<5} onClick={handleDel}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>Удалить
              </MenuItem>
              {props.viewtype === 'list' && <MenuItem onClick={exportJS}>
              <ListItemIcon>
                <ExcelIcon fontSize="small" />
              </ListItemIcon>Сохранить в Excel
              </MenuItem>
              
              }              
                </MenuList>
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }  

  function RowHeightMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSetRowHeight = (height) => {
      var rowHieght = 25;
      if(height==="middle"){
        var rowHieght = 50;
      } else if (height==="tall"){
        var rowHieght = 75;
      } else if (height==="extratall"){
        var rowHieght = 100;
      }
      props.handleSetRowHeight(props.tableId, props.viewId, rowHieght);
      handleClose()
    };  



    return (
      <React.Fragment>
        
        <Tooltip title="Установить высоту строк">
            <IconButton  size="small" variant="text" className={classes.viewButton}  onClick={handleClick}> 
            <RowHightIcon /> 
            </IconButton>                
        </Tooltip>     
        <Menu
          id="row-height-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuList id="row-height-menu-list" dense>
          <MenuItem onClick={(e)=>handleSetRowHeight("small")}>Узкая</MenuItem>
          <MenuItem onClick={(e)=>handleSetRowHeight("middle")}>Средняя</MenuItem>
          <MenuItem onClick={(e)=>handleSetRowHeight("tall")}>Широкая</MenuItem>
          <MenuItem onClick={(e)=>handleSetRowHeight("extratall")}>Супер широкая</MenuItem>
          </MenuList>
        </Menu>        
      </React.Fragment>
    );
  }  

  

  function SavingDialog(props) {
    //const [open, setOpen] = React.useState(false);
    const handleClose = () => {

      props.handleClose()
        
      };

      const handleCancel = () => {

        props.handleClose()
          
      };

      const handleSave = () => {

        props.handleSave();
        props.handleClose()
      };   
      
      const handleNotSave = () => {

        props.handleClose()
      };         
      
    return (
      <React.Fragment >           
      <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Сохранить отчет</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Сохранить изменения в отчете?
          </DialogContentText>
          </DialogContent>
          <DialogActions>        
            <Button onClick={handleNotSave} color="primary">
              Не сохранять
            </Button>            
            <Button onClick={handleSave} color="primary">
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  }





export default function ReportEditor(props) {
    const {token, userId} = useContext(AuthContext);
    const {onChangeReport, setCurrentPageTitle} = useContext(NavContext);
    const {request, error} = useHttp();
    const unblockHandle = useRef();
    //const [errorMsg, setErrorMsg] = useState(null);
    const [isShowSaving, setShowSaving] = useState(false)
    const [openSets, setOpenSets] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);
    const gridRef = useRef(null);
    //const [isOpenDescription, setOpenDescription] = useState(true);     
    const [nextPage, setNextPage] = useState(null)
    const [edited, setEdited] = useState(false);
    const [dialogEdited, setDialogEdited] = useState(false);
    const [dialog, setDialog] = useState(null);
    const [linkColumns, setLinkColumns] = useState([]);
    const [memberColumns, setMemberColumns] = useState([]);   
    const [attachmentsColumns, setAttachmentsColumns] = useState([]);
    const [geolocationColumns, setGeolocationColumns] = useState([]);
    const [linkMemberColumns, setLinkMemberColumns] = useState({});    
    const [linkViews, setLinkViews] = useState({});
    const [config, setConfig] = useState({}); 
    const [editedColumns, setEditedColumns] = useState([]);     
    const [nonDialogViews, setNonDialogViews] = useState([]);    
    //const [workitemlist, setWorkItemList] = useState(null);
    //const [items, setItems] = useState({});
    const [newItemIndex, setNewItemIndex] = useState(0);
    //const [columns, setColumns] = useState(null);
    const [desc, setDesc] = useState('');
    const [title, setTitle] = useState('') 
    const [title_column, setTitle_column] = useState(null)
    const [hiddenDesc, setHiddenDesc] = useState(true)
    
    //const [loadingWI, setLoadingWI] = useState(true);
    
    //const [chatViewLinks, setChatViewLinks] = useState([]);  
    const [extPanelsHeight, setExtPanelsHeight] = useState(props.sharedView?55:85)  
    const [headerWidth ,setHeaderWidth]= useState(800)
    const [headerHeight ,setHeaderHeight]= useState(800)
    const pageEditorRef = useRef(null)
    //const [viewContentWidth ,setViewContentWidth]= useState(800)
    //const [viewContentHeight ,setViewContentHeight]= useState(800)    
    const [tableWidth ,setTableWidth]= useState(800)
    const [viewDialogs, setViewDialogs] = useState(false);
    const [viewAllPersonal, setViewAllPersonal] = useState(false);
    const [boardLaneHeight, setBoardLaneHeight] = useState('150px')
    const [collapseHeaderButton ,setCollapseHeaderButton]= useState(false)
    const headerRef = useRef()   
    //const viewContentRef = useRef()   
    const history = useHistory(); 
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElBotMenu, setAnchorElBotMenu] = useState(null);
    //const [view, setView] = useState('list');  
    const [currentView, setCurrentView] = useState(null); 
    
    //const [currentTable, setCurrentView] = useState(null);
    //const [views, setViews] = useState({}) //{_id:'list', title:'Все задачи', viewtype:'list'}, {_id:'kanban', title:'Просроченные задачи', viewtype:'kanban'}, {_id:'kanban2', title:'На доске', viewtype:'kanban'}, {_id:'list2', title:'Еще задачи', viewtype:'list'}, {_id:'calendar', title:'В календаре', viewtype:'calendar'}, {_id:'robot', title:'Опрос по статусу', viewtype:'robot'}
    //const [icon, setIcon] = useState(null);   
    const [sortedAndFilteredViewItems, setSortedAndFilteredViewItems] = useState([])
    const [viewColumns, setViewColumns] = useState([])
    const [viewVisibleColumns, setViewVisibleColumns] = useState([])
    const [defaultcolumns, setDefaultColumns] = useState([]);
    const [kanbanItems, setKanbanItems] = useState({});   
    const [calendarItems, setCalendarItems] = useState([])
    const [mapItems, setMapItems] = useState([])
    const [timelineItems, setTimelineItems] = useState([])
    const [timelineResource, setTimelineResource] = useState([])
    const [timelineResourceName, setTimelineResourceName] = useState('Задачи') 
    const [timelineResourceTitle, setTimelineResourceTitle] = useState("")
    const [timelineData, setTimelineData] = useState(
      new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week))
    const [externalContentOptions, setExternalContentOptions] = useState({})
    const [dashboardSettings, setDashboardSettings] = useState({})
    const [editedDashboardSettings, setEditedDashboardSettings] = useState(false)
    const [dashboardEditedMode, setDashboardEditedMode] = useState(false)
    const [mapOptions, setMapOptions] = useState({})
    const [mongoFilter, setMongoFilter] = useState({})
    const [googleChart, setGoogleChart] = useState(null)
    const [chartEditor, setChartEditor] = useState(null)
    const [editedChart, setEditedChart ] = useState(null)
    const [pageContent, setPageContent ] = useState({})
    
    //const [kanbanViewOptions, setKanbanViewOptions] = useState({lineColumn:'Статус', titleColumn: 'title', descriptionColumn: 'Комментарий исполнителя', labelColumn: 'Проблемы'});
    //const [calendarViewOptions, setCalendarViewOptions] = useState({titleColumn:'title', startColumn:'start', endColumn:'end'});
    //const [openItemEditor, setOpenItemEditor] = useState(false)
    //const [editedItem, setEditedItem] = useState(null)
    //const [editedIdx, setEditedIdx] = useState(null)
    const [openViewsDrawer, setOpenViewsDrawer]= useState(false)
    const [quicklyOpenViewsDrawer, setQuicklyOpenViewsDrawer]= useState(null) 
    const [delayQuicklyOpenViewsDrawer, setDelayQuicklyOpenViewsDrawer] = useState(null)
    const [viewsDrawerSize, setViewsDrawerSize]= useState(200)    
    const [robotTab, setRobotTab] = useState(0); //schedule:2, dialog:0, delivery:1
    //const [chatgroups, setChatgroups] = useState([]);
    const [currentDBTab, setCurrentDBTab] = useState(null)
    const containerEl = useRef()
    //const groupId = useParams().groupid;
    //const dbId = useParams().dbId;
    //const wiId = useParams().wiId;
    //const viewId = useParams().viewId;
    const classes = useStyles();
    //const [tab, setTab] = React.useState(0);
    //const [tabIdx, setTabIdx] = React.useState({workItems:0, logs:1, schedule:2, dialog:3, delivery:4});
    //const [sheets, setSheets] = React.useState([]);
    //const [sheet_columns, setSheetColumns] = React.useState([]);
    // const google = window.google ? window.google : {};
    let EditableDIV = contentEditable();
    //const handleFullScreen = useFullScreenHandle();
    const ReactEditorJS = createReactEditorJS()

     const init = useCallback(async()=>{
        try {
          console.log('ReportEditor init start!')
          /*
            setLoadingWI(true); 
             getChatViewLinks()
  
              var table = await request(`/api/workitemlist/${props.dbId}/${props.tableId}`,'GET',null, {Authorization: `Bearer ${token}`});
              console.log('init table', table);                      

              setColumns(table.columns);
              updateViews()
              loadItems()
            setLoadingWI(false);             
            */
            if(props.tableId ){
              let __data = localStorage.getItem('table_state_'+props.tableId)
              if(__data){
                const data = JSON.parse(__data);
                setOpenViewsDrawer(data.OpenViewsDrawer)
                //setViewDialogs(data.ViewDialogs)
              }      
            } else {
              
            }           
           setDialogEdited(false)
        } catch (e) {
          props.setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
          console.log('init report error', e); 
        }
    },[props.tableId, token, request])    

  function EmptyRowsRenderer() {

    return <div style={{ textAlign: 'center' }}>
      <div>Нажмите "Добавить" для добавления элементов в таблицу</div>
      <div><Button size="small" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={(e) => props.insertRow({ rowIdx: props.items.length })}>
        Добавить
  </Button></div> </div>;

  }

  const onResize = (_headerWidth, _headerHeight) => {
    //console.log('onResize ', _headerWidth, _headerHeight)
    setHeaderWidth(_headerWidth)
    setHeaderHeight(_headerHeight)    

    if(_headerWidth<850){
      setCollapseHeaderButton(true)
    } else {
      setCollapseHeaderButton(false)
    }
  }

  const onResizeViewContent = (_headerWidth, _headerHeight) => {

    props.setViewContentWidth(_headerWidth)
    props.setViewContentHeight(_headerHeight)    
  }  
  
/*
  const sortedAndFilteredViewItems = useMemo(() => {

    //console.log('sortedAndFilteredViewItems !!!')
    //сперва отфильтруем данные
    var _maxIndex = 0;
    var items = Query.query(props.itemsList, mongoFilter, (row, key) => {_maxIndex = Math.max(row.index, _maxIndex);  return row[key]});

    console.log('sortedAndFilteredViewItems _maxIndex ', _maxIndex)
    //let rows = [];
    //rows.push({_id:'new_row'})
    //let _maxIndex = 0;
    //for (var i in props.items) {
    //  rows.push(props.items[i])
    //  _maxIndex = Math.max(props.items[i].index, _maxIndex)
    //}
    setNewItemIndex(_maxIndex)
    return items.sort((a, b) => a.index - b.index);
    //console.log('sortedAndFilteredViewItems !!!', rows)
    //setSortedAndFilteredViewItems(rows)
  }, [props.itemsList, mongoFilter]);  

*/
  const handleClose = () => {
    setShowSaving(false);
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (nextPage) {
      history.push(nextPage)
    }
    // history.push(`/reports/${groupId}`);
  };

  const handleSave = () => {
    if (unblockHandle) {
      unblockHandle.current()
    }
    saveDialog();
    if (nextPage) {
      history.push(nextPage)
    }
  };

  //const handleDelete = () => {
  //  deleteWI();

  //};



      //const handleSaveAsTeamplate = (tmpl) => {      
        
      //  onSaveAsTeamplate(tmpl)
      //};                 

     
/*
    const onChangeTitle = (value) => {
        const v = value;
        if(v.length>0){
          setTitle(v);  
          if(!props.isTemplate){
            saveWI({title:v});
            //setCurrentPageTitle(<EditableDIV value={v} hiddenDesc={hiddenDesc} handleHiddenDesc={handleHiddenDesc} onSave={onChangeTitle}/>); 
            onChangeReport();            
          }        

          //setEdited(true);           
        }        
        //setWorkItemList((prev) =>{
        //  var newWI =  {...prev};
        //  newWI.title = v;     
        //  return newWI;
        //}); 
        
    }

    const handleChangeDesc = (e, desc) => {
      const v = desc;
      setDesc(curdesc=>{
        if(curdesc!==v && !props.isTemplate){
          saveWI({desc:v});          
        }
        return v
      });
      
      //saveWIParam('desc',{desc:v});
      //setWorkItemList((prev) =>{
      //  var newWI =  {...prev};
      //  newWI.desc = v;     
      //  return newWI;
      //});       
      //setEdited(true);         
  }

  const handleHiddenDesc = (v) => {
    console.log('handleHiddenDesc ', v)
    setHiddenDesc(v);    
    if(!props.isTemplate){
      saveWI({hiddenDesc:v});  
      console.log('title ', title)      
    }
  }


*/

    
    //saveWIParam('desc',{desc:v});
    //setWorkItemList((prev) =>{
    //  var newWI =  {...prev};
    //  newWI.desc = v;     
    //  return newWI;
    //});       
    //setEdited(true);         

  
    
  //  const handleUpdateColumns = (newColumns) => {      
  //    setColumns(newColumns); 
      //setEdited(true);         
      //СОХРАНИТЬ!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1
  //}
    


    const onRunDialog = () => {
      //runDialog();
    }   
  
    const onStopDialog = () => {
      //stopDialog();
    }   

    const onChangeFullDialog = (newdialog) => {
      setDialog(newdialog)  
      setDialogEdited(true)       
    }   

    const onChangeDialog = (prop, value) => {
      const pr = prop;
      const v =value;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js[pr] = v;
        return js;
      })  
      setDialogEdited(true)       
    }    
    
    const onChangeDialogQuestionMenuItem = (qidx, idx, prop, value) => {
      const pr = prop;
      const v =value;
      const iq = qidx;
      const ii = idx;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions[iq]["menu_items"][ii][pr] = v;
        return js;
      })   
      setDialogEdited(true)   
    }       
    
    const onAddQuestionMenuItem = (qidx, newItem) =>{
      const iq = qidx;
      const ni = newItem
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        if(!js.questions[iq]["menu_items"]){
          js.questions[iq]["menu_items"] = []
        }
        js.questions[iq]["menu_items"].push(ni);
        return js;
      })   
      setDialogEdited(true)       
    }

    const onDeleteQuestionMenuItem = (qidx, item_index) =>{
      const iq = qidx;
      const ni = item_index
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        if(js.questions[iq]["menu_items"]){
          js.questions[iq]["menu_items"].splice(item_index,1); 
        }
        return js;
      })   
      setDialogEdited(true)       
    }    

    const onChangeDialogQuestion = (qidx, prop, value) => {
      const pr = prop;
      const v =value;
      const iq = qidx;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions[iq][pr] = v;
        return js;
      })   
      setDialogEdited(true)   
    }       

    const onChangeQuestions = (newQuestions) => {
      const qs = newQuestions;

      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions = newQuestions;
        return js;
      })   
      setDialogEdited(true)      
    }     
    const handleClickDialogSettings = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseDialogSettings = () => {
      setAnchorEl(null);
    };    

    const handleCloseBotMenu = () => {
      setAnchorElBotMenu(null);
    };    

    const handleClickDashboardSettingsSave = (event) => {
      props.updateView(props.tableId, props.viewId, { dashboardSettings: dashboardSettings });
    }; 

    

    const handleClickBotMenu = (event) => {
      console.log('handleClickBotMenu ', event.currentTarget)
      setAnchorElBotMenu(event.currentTarget);
    };   

    const handleChangeView = (event, newViewId) => {
      //console.log('handleChangeView ', event, newView);
      if (newViewId) {
        history.push(`/db/${props.dbId}/${props.tableId}/${newViewId}`)
        //setView(newView);
        //if(!props.isTemplate){
        //  saveWI({view:newView});
        //}        
      }
    }; 

  const onExpandedGroupIdsChange = (expandedGroupIds) => {
    if (props.views && props.viewId) {
      const _expandedGroupIds = Array.from(expandedGroupIds)
      props.views[props.viewId].expandedGroupIds = _expandedGroupIds
      if (!props.isTemplate) {
        props.updateView(props.tableId, props.viewId, { expandedGroupIds: _expandedGroupIds });
        //        
      }
    }
  }


    const handleChangeTimelineViewOptions = (param, newOpt) => {
      const v = newOpt
      const p = param
      var current = props.views[props.viewId].timelineColumnsOptions;
      let _opts = { ...current };
      _opts[p] = v;
      props.views[props.viewId].timelineColumnsOptions = _opts
      if (!props.isTemplate) {
        props.updateView(props.tableId,props.viewId, { timelineColumnsOptions: _opts });
        //saveWI({ calendarViewOptions: _opts });
      }
    };  

    const handleChangeMapViewOptions = (param, newOpt) => {
      const v = newOpt
      const p = param
      var current = props.views[props.viewId].mapOptions;
      let _opts = { ...current };
      _opts[p] = v;
      props.views[props.viewId].mapOptions = _opts
      if (!props.isTemplate) {
        props.updateView(props.tableId,props.viewId, { mapOptions: _opts });
        //saveWI({ calendarViewOptions: _opts });
      }
    };      

  const handleChangeCalendarViewOptions = (param, newOpt) => {
    const v = newOpt
    const p = param
    var current = props.views[props.viewId].calendarColumnsOptions;
    let _opts = { ...current };
    _opts[p] = v;
    props.views[props.viewId].calendarColumnsOptions = _opts
    if (!props.isTemplate) {
      props.updateView(props.tableId,props.viewId, { calendarColumnsOptions: _opts });
      //saveWI({ calendarViewOptions: _opts });
    }
  };  
  
  const handleChangeFilterViewOptions= (newOpt) => {
    const v = newOpt    
    //console.log('handleChangeFilterViewOptions ', newOpt);
    if (!props.isTemplate) {
      props.updateView(props.tableId,props.viewId, { filter: v });
      //saveWI({ calendarViewOptions: _opts });
    }
  };  

  const handleChangeFilterDialogOptions= (newOpt) => {
    const v = newOpt    
    //console.log('handleChangeFilterDialogOptions ', newOpt);
    
    dialog.filterIn = v;      
      //saveWI({ calendarViewOptions: _opts });
    saveDialog(dialog)      
  };    

  const handleChangeKanbanViewOptions = (param, newOpt) => {
    const v = newOpt
    const p = param
    var current = props.views[props.viewId].kanbanColumnsOptions;
    let _opts = { ...current };
    _opts[p] = v;
    props.views[props.viewId].kanbanColumnsOptions = _opts
    if (!props.isTemplate) {
      props.updateView(props.tableId, props.viewId, { kanbanColumnsOptions: _opts });
      //saveWI({ calendarViewOptions: _opts });
    }
  };      

  const handleEventChangeInTimeline = (event) => {
    console.log('handleEventChangeInTimeline ', event);
    
    if(event.event.id){
      let item = props.items[event.event.id];
      item[props.views[props.viewId].timelineColumnsOptions.resourceColumn] = event.event.resourceId;
      item[props.views[props.viewId].timelineColumnsOptions.titleColumn] = event.event.title;
      item[props.views[props.viewId].timelineColumnsOptions.startColumn] = event.event.start;
      item[props.views[props.viewId].timelineColumnsOptions.endColumn] =  event.event.end;
      props.onItemUpdate(props.tableId, item, event.event.extendedProps.rowIdx)
    }      
    
  }  
  
    const handleEventsSet = (events) => {
      console.log('handleEventsSet ', events);
    }
    const handleEventRemove = (event) => {
      console.log('handleEventRemove ', event);
    }
    const handleEventChange = (event) => {
      console.log('handleEventChange ', event);
      
      if(event.event.id){
        let item = props.items[event.event.id];
        item[props.views[props.viewId].calendarColumnsOptions.titleColumn] = event.event.title;
        item[props.views[props.viewId].calendarColumnsOptions.startColumn] = event.event.start;
        item[props.views[props.viewId].calendarColumnsOptions.endColumn] =  event.event.end;
        props.onItemUpdate(props.tableId, item, event.event.extendedProps.rowIdx)
      }      
      
    }
    const handleEventAdd = (event) => {
      console.log('handleEventAdd ', event);
    }

    const handleTimelineAdd = (schedulerData, slotId, slotName, start, end, type, item) => {
      console.log(`handleTimelineAdd {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)
        var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
        var newRow = {...defaultValues, index: newItemIndex}
        newRow[props.views[props.viewId].timelineColumnsOptions.titleColumn] = "!Новое";
        newRow[props.views[props.viewId].timelineColumnsOptions.startColumn] = start;
        newRow[props.views[props.viewId].timelineColumnsOptions.endColumn] = end;
        newRow[props.views[props.viewId].timelineColumnsOptions.resourceColumn] = slotId;
        props.insertItem(props.tableId, newRow)
      /*
          let newFreshId = 0;
          schedulerData.events.forEach((item) => {
              if(item.id >= newFreshId)
                  newFreshId = item.id + 1;
          });

          let newEvent = {
              id: newFreshId,
              title: 'New event you just created',
              start: start,
              end: end,
              resourceId: slotId,
              bgColor: 'purple'
          }
          schedulerData.addEvent(newEvent);
          this.setState({
              viewModel: schedulerData
          })
          */
     // }
  }    
      

    const handleEventClick = (event) => {
      console.log('handleEventClick ', event.event);
      if(event.event.id){
        //let item = props.items[event.event.id];
        //props.setEditedItemId(event.event.id)
        //setEditedIdx(item.index)
        //props.setOpenItemEditor(true)     
        history.push(`/db/${props.dbId}/${props.wiId}/${props.viewId}/${event.event.id}`) 
      }
    }

    const handleTimelineEventClick = (schedulerData, event) => {
      console.log('handleTimelineEventClick ', event.event);
      if(event.id){
        //let item = props.items[event.id];
        //props.setEditedItemId(event.event.id)
        //setEditedIdx(item.index)
        //props.setOpenItemEditor(true)      
        history.push(`/db/${props.dbId}/${props.wiId}/${props.viewId}/${event.event.id}`) 
      }
    }    

    const  updateEventStart = (schedulerData, event, newStart) => {
      //if(confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
      //    schedulerData.updateEventStart(event, newStart);
      //}
      //this.setState({
      //    viewModel: schedulerData
      //})
      if(event.id){
        let item = props.items[event.id];
        item[props.views[props.viewId].timelineColumnsOptions.startColumn] = newStart;
        props.onItemUpdate(props.tableId, item)
      }        
  }

  const updateEventEnd = (schedulerData, event, newEnd) => {
    if(event.id){
      let item = props.items[event.id];
      item[props.views[props.viewId].timelineColumnsOptions.endColumn] = newEnd;
      props.onItemUpdate(props.tableId, item)
    }   
  }

  const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if(event.id){
      let item = props.items[event.id];
      item[props.views[props.viewId].timelineColumnsOptions.startColumn] = start;
      item[props.views[props.viewId].timelineColumnsOptions.endColumn] = end;
      item[props.views[props.viewId].timelineColumnsOptions.resourceColumn] = slotId;

      props.onItemUpdate(props.tableId, item)
    }  
  }    

  const prevTimelineClick = (schedulerData)=> {
    schedulerData.prev();
    const _schedulerData = _.cloneDeep(schedulerData)
    _schedulerData.setEvents(timelineItems);
    //schedulerData._createRenderData()
    setTimelineData(_schedulerData )
    //setTimelineData(schedulerData)
    //this.setState({
    //    viewModel: schedulerData
    //})
}

const nextTimelineClick = (schedulerData)=> {
    schedulerData.next();
    const _schedulerData = _.cloneDeep(schedulerData)
    _schedulerData.setEvents(timelineItems);
    //schedulerData._createRenderData()
    setTimelineData(_schedulerData )

    //let _timelineData = new SchedulerData(date, schedulerData.viewType, schedulerData.showAgenda, schedulerData.isEventPerspective);
    //_timelineData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    //_timelineData.setLocaleMoment(moment);
    //_timelineData.setEvents(timelineItems);
    //_timelineData.setResources(_resource);
    //setTimelineData(_timelineData)        
    //schedulerData.setEvents(DemoData.events);
    //this.setState({
    //    viewModel: schedulerData
    //})
}

const onViewTimelineChange = (schedulerData, view) => {
  schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
  const _schedulerData = _.cloneDeep(schedulerData)
  _schedulerData.setEvents(timelineItems);
  //schedulerData._createRenderData()
  setTimelineData(_schedulerData )
    //let _timelineData = new SchedulerData(date, schedulerData.viewType, schedulerData.showAgenda, schedulerData.isEventPerspective);
    //_timelineData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    //_timelineData.setLocaleMoment(moment);
    //_timelineData.setEvents(timelineItems);
    //_timelineData.setResources(_resource);
    //setTimelineData(_timelineData)    
    //schedulerData.setEvents(DemoData.events);
    //this.setState({
    //    viewModel: schedulerData
    //})
}

const onSelectDateTimeline = (schedulerData, date) => {
      schedulerData.setDate(date);
      const _schedulerData = _.cloneDeep(schedulerData)
      _schedulerData.setEvents(timelineItems);
      //schedulerData._createRenderData()
      setTimelineData(_schedulerData )
       // let _timelineData = new SchedulerData(date, schedulerData.viewType, schedulerData.showAgenda, schedulerData.isEventPerspective);
       // _timelineData.setLocaleMoment(moment);
       // _timelineData.setEvents(timelineItems);
       // _timelineData.setResources(_resource);
       // setTimelineData(_timelineData)
        

    //schedulerData.setEvents(DemoData.events);
    //this.setState({
    //    viewModel: schedulerData
    //})
}  

  const handleDateSelect = (selectInfo) => {
    //let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    //id: "createEventId()",
    //title,
    //start: selectInfo.startStr,
    //end: selectInfo.endStr,
    //allDay: selectInfo.allDay
    //}
    if (props.views[props.viewId].calendarColumnsOptions) {
      
      //var _id = ObjectID()
      //newRow._id = _id.toHexString()
      var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
      var newRow = {...defaultValues, work_item_list: props.tableId}
      newRow[props.views[props.viewId].calendarColumnsOptions.titleColumn] = 'Новое событие';
      newRow[props.views[props.viewId].calendarColumnsOptions.startColumn] = selectInfo.startStr;
      newRow[props.views[props.viewId].calendarColumnsOptions.endColumn] = selectInfo.endStr;
      props.setNewItem(newRow)
      //setEditedIdx(-1)
      props.setOpenItemNew(true)
    }
  }    

    const handleAddItem = () => {
      console.log('handleAddItem !')
      //var _id = ObjectID().toHexString() 
       var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
      props.setNewItem(
        {title:'Новый элемент',...defaultValues, work_item_list: props.tableId}
        )
      //setEditedIdx(-1)
      props.setOpenItemNew(true)
    }

    const handleSetDefaultValues = (viewId) => {
      props.setDefaultValues({title:'', work_item_list: props.tableId, __ex_viewId:viewId,  })
      //setEditedIdx(-1)
      props.setOpenItemEditor(true)
    }    

    const handleEditItemById = (rowId) => {  
      if(rowId){
        //props.setEditedItemId(rowId)
        //setEditedIdx(item.index)
        //props.setOpenItemEditor(true)   
        history.push(`/db/${props.dbId}/${props.wiId}/${props.viewId}/${rowId}`)   
      }
    }  
    
    const onChangeKanbanCard = (newData) =>{
      console.log('onChangeKanbanCard', newData)
    }

    const onKanbanCardClick = (cardId, metadata, laneId) =>{      
      console.log('onKanbanCardClick', cardId, metadata, laneId)
        if(cardId){
          //props.setEditedItemId(cardId )
          //setEditedIdx(item.index)
          //props.setOpenItemEditor(true)     
          history.push(`/db/${props.dbId}/${props.wiId}/${props.viewId}/${cardId}`) 
        }
    }

    const onKanbanLaneClick = (ev, laneId) =>{
      let col = props.columns.find(e => { return e._id === props.views[props.viewId].groupBy });
      handleOpenColumnSetting(col, ev.currentTarget);

    }

    const openKanbanCardAddDialog = (laneId) =>{            
        if(laneId){
          var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
          let newRow = {title:'Новый элемент',...defaultValues, work_item_list: props.tableId}
          if(laneId==='__unline'){            
            newRow[props.views[props.viewId].groupBy] =null
          } else {
          newRow[props.views[props.viewId].groupBy] = laneId;
        }
        console.log('openKanbanCardAddDialog groupBy',props.views[props.viewId].groupBy, laneId, newRow)
          props.setNewItem(newRow)

          //setEditedIdx(-1)
          props.setOpenItemNew(true)   
        }
    }    

    const onKanbanCardAdd = (card, laneId) =>{
      console.log('onKanbanCardAdd', card, laneId)
      //{id:items[ii]._id, title: items[ii][kanbanViewOptions.titleColumn], description: items[ii][kanbanViewOptions.descriptionColumn], label:items[ii][kanbanViewOptions.labelColumn], metadata: {rowIdx: ii}}
      //if(items[ii][kanbanViewOptions.lineColumn]===_kanban_items.lanes[il].id){
        var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
        var newRow = {...defaultValues, index: newItemIndex}
        newRow[props.views[props.viewId].kanbanColumnsOptions.titleColumn] = card.title;
        newRow[props.views[props.viewId].kanbanColumnsOptions.descriptionColumn] = card.description;
        newRow[props.views[props.viewId].kanbanColumnsOptions.labelColumn] = card.label;
        laneId==='__unline'?newRow[props.views[props.viewId].groupBy] =null:newRow[props.views[props.viewId].groupBy] = laneId;
        props.insertItem(props.tableId, newRow)

    }

    const onDashBoardLayoutChange = (layout) => {
      console.log('onDashBoardLayoutChange ', layout)
      const __layout = layout;
      
      setDashboardSettings(cur=> {
        var newDashboardSettings = {...cur}
        console.log('onDashBoardLayoutChange 2 ', [...newDashboardSettings.elements])
        //var newElements = [...newDashboardSettings.elements]
        newDashboardSettings.elements.forEach(el=>{
          el.data_grid = __layout.find(li=> li.i===el.i)
        })

        console.log('onDashBoardLayoutChange 3 ', [...newDashboardSettings.elements])
        return  newDashboardSettings
      })
      setEditedDashboardSettings(true)      
      
    };
   

    

    const onLaneUpdate = (laneId, data) => {
      console.log('onLaneUpdate ', laneId, data)
      //const newColumns = [...curcolumns]
      let col = props.columns.find(e => { return e._id === props.views[props.viewId].groupBy });
      let newCol = {...col}
      if (laneId!=='__unline' && data.title && newCol && newCol.type === 'select' && newCol.options && newCol.options.length > 0) {
        var changedOption = newCol.options.find(opt => { return opt.value === laneId });
        console.log('onLaneUpdate changedOption', changedOption)
        if(changedOption){
          changedOption.label = data.title 
          props.updateColumn(props.tableId, newCol._id, newCol)
        }
      } else {
        //setColumns(curcolumns => {
        //  const newColumns = [...curcolumns]
        //  return newColumns;
        //})
      }      
    }

    const KanbanLaneHeader = (props) => {
      
      const addItemHandler = () => {
        openKanbanCardAddDialog(props.id)
      }
      return (
        <div>
          <header
            style={{
              borderBottom: '2px solid #c5c5c5',
              paddingBottom: 6,
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              minWidth:props.collapsed?"25px":"230px"
            }}>
    {props.laneId==='__unline'?
    (props.collapsed?<span 
    style={{
      minInlineSize: "7em",
      /* border: 1px solid red; */
      textAlign: "center",
      writingMode: "vertical-rl",
      transform: "scale(-1)"
    }}>{props.title}</span>: <span>{props.title}</span>)
    : 
    (props.collapsed?
<Chip
    size="medium"
    //icon={<FormatColorFillIcon />}
    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
    label={<b>{props.title}</b>}
    clickable
    //color={o.color ? o.color : "default"}
    style={{ 
      backgroundColor: props.color?props.color:null, 
      //color:props.color?"white":null,  
      minInlineSize: "7em",
      /* border: 1px solid red; */
      textAlign: "center",
      writingMode: "vertical-rl",
      transform: "scale(-1)" 
    }}
    onClick={e=>onKanbanLaneClick(e, props.id)}
    //onDelete={(e) => handleDeleteOptionChoice(o, index)}
  //deleteIcon={<DeleteIcon />}
  />       
      :<Chip
    size="medium"
    //icon={<FormatColorFillIcon />}
    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
    label={<b>{props.title}</b>}
    clickable
    //color={o.color ? o.color : "default"}
    style={{ 
      backgroundColor: props.color?props.color:null, 
      //color:props.color?"white":null  

    }}
    onClick={e=>onKanbanLaneClick(e, props.id)}
    //onDelete={(e) => handleDeleteOptionChoice(o, index)}
  //deleteIcon={<DeleteIcon />}
  />    )}            
           
            
           {!props.collapsed && <div style={{width: '30%', textAlign: 'right', fontSize: 13}}>
              {!props.sharedView && 
              <IconButton 
              //onClick={addItemHandler} 
              size="small"
              >
                  ...
                </IconButton>}
              </div>}
            
          </header>
        </div>
      )
    }    

    const KanbanCustomLaneFooter = (props) => {
      const onClick = props.onClick;
      const collapsed = props.collapsed;
      
      const addItemHandler = () => {
        openKanbanCardAddDialog(props.id)
      }

      console.log('KanbanCustomLaneFooter ', props)
      return (
        collapsed?
        <div>
          <div
          style={{            
            position: "relative",            
            height: "30px",
            margin: "5px 0px 0px 0px",
            minInlineSize: "10em",
            /* border: 1px solid red; */
            textAlign: "center",
            writingMode: "vertical-rl",
            transform: "scale(-1)"             
            //font-weight: bold;
          }} 
        //onClick={onClick}
        >   
        <span>{props.cards?props.cards.length:0} карт </span>  
         </div> 
            <div      
            >        
            <IconButton size="small" onClick={onClick}>            
            {collapsed ?<ExpandIcon fontSize="small"/>:<CollapseIcon fontSize="small"/>}
                  </IconButton>                              
            </div>
          </div>                      
        :
        <div
          style={{            
            position: "relative",            
            height: "30px",
            margin: "5px 0px 0px 0px",
            //font-weight: bold;
          }} 
        //onClick={onClick}
        >
          <div
          style={{
            float: "left"
          }}          
          >         
          <span>{props.cards?props.cards.length:0} карт </span>
          </div> 
          {!props.sharedView && 
          <div
          style={{
            position: "absolute",
            left: "50%",
            top:"-15px",
            transform: "translate(-50%)"
          }}          
          >
          <IconButton 
          //size="small" 
          onClick={addItemHandler} color="primary">            
                  <AddCircleIcon fontSize="large"/>
                </IconButton>
            </div>}
            <div
          style={{
            float: "right"
          }}          
          >        
          <IconButton size="small" onClick={onClick}>            
          {collapsed ?<ExpandIcon fontSize="small"/>:<CollapseIcon fontSize="small"/>}
                </IconButton>                              
          </div>
        </div>      
      )
    }

    const KanbanCustomCard = (props) => {
      const clickDelete = e => {
        props.onDelete()
        e.stopPropagation()
      }
      
      return (
        <MovableCardWrapper
          onClick={props.onClick}
          style={props.cardStyle}
          className={props.className}
        >
          <header
            style={{
              borderBottom: '1px solid #eee',
              paddingBottom: 6,
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: props.cardColor
            }}>
            <div style={{fontSize: 14, fontWeight: 'bold'}}>{props.title}</div>
            <div style={{fontSize: 11}}>{props.label}</div>
            {props.showDeleteButton && <DeleteButton onClick={clickDelete} />}
          </header>
          <div style={{fontSize: 12}}>
            <div style={{color: '#4C4C4C', fontWeight: 'bold'}}>{props.subTitle}</div>
            <div style={{padding: '5px 0px'}} >{props.description}</div>
            <div style={{marginTop: 10, textAlign: 'center', color: props.cardColor, fontSize: 15, fontWeight: 'bold'}}>{props.escalationText}</div>
            {props.tags && (
              <div
                style={{
                  borderTop: '1px solid #eee',
                  paddingTop: 6,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}>
                {props.tags.map(tag => (
                  <Tag key={tag.title} {...tag} tagStyle={props.tagStyle} />
                ))}
              </div>
            )}
          </div>
        </MovableCardWrapper>
      )
    }
    


    //const handleLaneDragStart = (laneId) =>{
    //  if(laneId=== "__unline"){
        //нельзя двигать!
    //    return false;
    //  }
    //}

    const handleLaneDragEnd=(fromIndex, toIndex, laneData)=>{
      //console.log('handleLaneDragEnd ', removedIndex, addedIndex, payload)
      
      //if(laneData.id=== "__unline"){
        //нельзя двигать!
        //return false;
      //} else if(laneData) {
          const fi = fromIndex-1;
          const ti = toIndex-1;
        
          const newColumns = [...props.columns]
          let col = newColumns.find(e => { return e._id === props.views[props.viewId].groupBy });
          if (col && col.type === 'select' && col.options && col.options.length > 0) {
            var reorderedOptions = col.options;
            if(fromIndex >=0 && toIndex>=0){
            reorderedOptions.splice(
              ti,
              0,
              reorderedOptions.splice(fi, 1)[0]
            );
           
            }

          }
            props.setColumns(props.tableId, newColumns)     
        //}

    }
    
    const onKanbanCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) =>{
      console.log('onKanbanCardMoveAcrossLanes', fromLaneId, toLaneId, cardId, index)
      const item = {...props.items[cardId]}
      if(toLaneId === '__unline'){
        item[props.views[props.viewId].groupBy] = null;
        //TODO надо учесть еще index!!!
      } else {
        item[props.views[props.viewId].groupBy] = toLaneId;
        //TODO надо учесть еще index!!!
      }      
      props.onItemUpdate(props.tableId, item)
      //toLaneId==='__unline'?newRow[kanbanViewOptions.lineColumn] =null:newRow[kanbanViewOptions.lineColumn] = laneId;
    }

    const handleAddView = (newView, cb) => {
      const nv = newView;
      //nv._id=newView.viewtype+'123';      
      props.addView(props.tableId, newView, cb)
      //setViews(vs=> {
      //  var newViews = [...vs]
      //  newViews.push(nv)
      //  return newViews
      //})
    }



    const __setOpenViewsDrawer  = (_open) => {

      setOpenViewsDrawer(_open);
      localStorage.setItem('table_state_'+props.tableId, JSON.stringify({ViewDialogs:viewDialogs, viewAllPersonal:viewAllPersonal,  OpenViewsDrawer:_open}));
    };

    const __setViewDialogs = (_open) => {

      setViewDialogs(_open);
      localStorage.setItem('table_state_'+props.tableId, JSON.stringify({ViewDialogs:_open, viewAllPersonal:viewAllPersonal,  OpenViewsDrawer:openViewsDrawer}));
    };

    const __setViewAllPersonal = (_open) => {

      setViewAllPersonal(_open);
      localStorage.setItem('table_state_'+props.tableId, JSON.stringify({ ViewDialogs: viewDialogs, viewAllPersonal:_open, OpenViewsDrawer:openViewsDrawer}));
    };    

    
    
    
  
    const handleOpenColumnSetting = (column, _anchorEl) => {
      var rect = _anchorEl.getBoundingClientRect();
      setOpenSets({ top: rect.top, left: rect.left })                    

      setEditingSets(column)
    };
  
    const handleOpenNewColumnSetting = (idx, _anchorEl) => {
      setEditingSets({idx:idx, isNew:true})
      setOpenSets(_anchorEl);
    };  

    const handleChangeRobotTab = (event, newTab) => {
      setRobotTab(newTab);
  };  

  /**
   * Изменения связанные с открытием таблицы, т.е. инициация, а также подписка на события socket
   */
  useEffect(() => {
    init();

  }, [props.tableId, init]); 

  const loadDialog = useCallback(async (dialogId) => {
    try {
      var j = await request(`/api/dialog/${props.tableId}/${dialogId}`,'GET',null, {Authorization: `Bearer ${token}`});
      console.log('loadDialog j', j);
      if (!j) {
        //нет видов что делаем?
        return props.setErrorMsg('Ошибка получения настроек чат-бота! Попробуйте перезагрузить страницу...')
      }
      setDialog(j)
    } catch (e) {
      props.setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
      //console.log('saveWorkitemlist error', e); 
    }
  }, [token, props.tableId, setDialog, request]) 

  const saveDialog = useCallback(async( _dialog)=>{
    if(!props.isTemplate && props.accessLevel>=5){
    try {
          var req_dialog = await request(`/api/dialog/${props.tableId}/${_dialog._id}`,'POST',_dialog, {Authorization: `Bearer ${token}`});
          console.log('saveDialog req_dialog ', req_dialog)   
          setDialogEdited(false)    
    } catch (e) {
      props.setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
        //console.log('saveWorkitemlist error', e); 
    }
  }
},[token,  props.tableId,  props.isTemplate, request, props.accessLevel]) 

  /**
   * Изменения связанные с изменением вида
   */
  useEffect(() => {
    console.log('ReportEditor useEffect changed props.viewId')
    
    if (props.tableId === props.wiId && props.views[props.viewId] && props.views[props.viewId].viewtype === 'dialog' && props.views[props.viewId].dialog) {
      console.log('updateViews dialog!', props.views[props.viewId].dialog);
      loadDialog(props.views[props.viewId].dialog)
    } else {
      setDialog(null)
    }

  }, [props.tableId, props.viewId, props.wiId, props.views])    

  /**
   * Автосохранение при измененнии вида робота
   */
  useEffect(() => {
    if(dialog && dialogEdited){
    return history.listen((location) => {        
        console.log(`You changed the page to: ${location.pathname}`)
          saveDialog(dialog)
    })} else {
      return //()=>{}
    }
  },[history, dialogEdited, dialog])  

  /**
   * Автосохранение по таймауту
   */
   useEffect(() => {
    if(dialog && dialogEdited){
      console.log(`saving dialog... `)
      saveDialog(dialog)
    }
  },[dialogEdited, dialog])  

  /**
   * обновление элементов канбан и календаря
   */
  useEffect(() => {
    if (props.columns && props.columns.length > 0 && props.viewId && props.views[props.viewId]) {
      //setItems(items)
      if (props.views[props.viewId].viewtype === 'kanban' ) {
        let _kanban_items = { lanes: [] };
        var kanbanColumnsOptions = props.views[props.viewId].kanbanColumnsOptions?props.views[props.viewId].kanbanColumnsOptions:{} 
        _kanban_items.lanes.push({ id: '__unline', title: ' - ', label: '', cards: [], laneDraggable: false });
        let col = props.columns.find(e => { return e._id === props.views[props.viewId].groupBy });
        if (col && col.type === 'select' && col.options && col.options.length > 0) {
          for (let iopt = 0; iopt < col.options.length; iopt++) {
            let _card = { id: col.options[iopt].value, title: col.options[iopt].label, label: '', cards: [] }
            if (col.options[iopt].color) {
              // _card.style = {
              //   backgroundColor: col.options[iopt].color
              // }
              _card.color = col.options[iopt].color
            }
            _kanban_items.lanes.push(_card);
          }
        }
        if (col && col.type === 'member') {
          for (let iopt = 0; iopt < props.members.length; iopt++) {
            _kanban_items.lanes.push({ id: props.members[iopt]._id, title: props.members[iopt].name, label: '', cards: [] });
          }
        }
        if (col && col.type === 'chatmember') {
          for (let iopt = 0; iopt < props.chatmembers.length; iopt++) {
            _kanban_items.lanes.push({ id: props.chatmembers[iopt]._id, title: props.chatmembers[iopt].first_name?(props.chatmembers[iopt].first_name+(props.chatmembers[iopt].last_name?(" "+props.chatmembers[iopt].last_name):"")):props.chatmembers[iopt].name, label: '', cards: [] });
          }
        }  
        
        if (col && col.type === 'link') {
          let tbLink = props.tables[col.linkId]
          if (tbLink 
            && tbLink.itemsList 
            && col.linkViewField) {
              var linkItems = tbLink.itemsList;
              for(let lix = 0; lix<linkItems.length; lix++){
                _kanban_items.lanes.push({ id: linkItems[lix]._id, title: linkItems[lix][col.linkViewField], label: '', cards: [] });
              }            
          }            
        }

        let titleColumn =  kanbanColumnsOptions.titleColumn ? props.columns.find(e => { return e._id === kanbanColumnsOptions.titleColumn }) : null;
        let descriptionColumn =  kanbanColumnsOptions.descriptionColumn ? props.columns.find(e => { return e._id === kanbanColumnsOptions.descriptionColumn }) : null;
        let labelColumn =  kanbanColumnsOptions.labelColumn ? props.columns.find(e => { return e._id === kanbanColumnsOptions.labelColumn }) : null;

        //var itemsIds = Object.keys(items);
        sortedAndFilteredViewItems.forEach((item )=> {
          //let item = items[ii]
          var col_title = titleColumn?item[kanbanColumnsOptions.titleColumn]:"";
          var col_description = descriptionColumn?item[kanbanColumnsOptions.descriptionColumn]:"";
          var col_label = labelColumn?item[kanbanColumnsOptions.labelColumn]:"";

          if (titleColumn){
            if (titleColumn.type === 'member') {

              let col_member = props.members.find(o => o._id === col_title)
              if (col_member) {
                col_title = col_member.name
              }
            }else  if (titleColumn.type === 'link') {
              let tbLink = props.tables[titleColumn.linkId]
              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.linkViewField) {
                col_title = tbLink.items[col_title][titleColumn.linkViewField]
              }              
            }else  if (titleColumn.type === 'lookup') {
              //column.lookupId && column.lookupField
              let lookupColumn = props.columns.find(cl=> cl._id === titleColumn.lookupId) 

              let tbLink = props.tables[lookupColumn.linkId]

              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.lookupField) {
                  col_title = tbLink.items[col_title][titleColumn.lookupField]
              }                
            //} else {
            //  col_title = ""
            }else  if (titleColumn.type === 'date') {
              col_title = col_title?moment(col_title).local('ru').format(titleColumn.date_format):''           
            }
        }
          var _col_description = []
          if (descriptionColumn) {
            
            if (descriptionColumn.type === 'member') {

              let col_member = props.members.find(o => o._id === col_description)                          
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>
                {col_member &&
                  <div><Chip
                    size="small"
                    icon={<AccountCircleIcon />}
                    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                    label={col_member.name}
                    //avatar={<Avatar>M</Avatar>}
                    //clickable
                    //color={o.color ? o.color : "default"}
                    style={
                      {
                        left: "6px",
                        top: "3px",
                        fontSize: "12px",
                        height: "20px"
                      }
                    }
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                  //deleteIcon={<DeleteIcon />}
                  />
                  </div>}
                </div>)
            } else if (descriptionColumn.type === 'attachments') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>
                {col_description && <div
                >              
                        <AttachmentsEditor
                          id="item_attachments_editor"
                          //label={column.name}
                          //type="number"
                          //error={Boolean(titleError)}
                          //helperText={titleError}
                          view="list"
                          maxHeight="250px"
                          value={col_description}                        
                          onChange={(e) => {
                            //const newVal = e.target.checked;
                            //const colkey = column._id
                            
                          }}                          
                        />  
              </div>}
              </div>)     
            } else if (descriptionColumn.type === 'check') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}

                </div>
                <div>
                  <Checkbox
                    checked={col_description}
                    //disabled={true}
                    style={{
                      padding:0
                    }}
                    size="small"
                    color="primary" />
                </div>
              </div>)               
           
            } else if (descriptionColumn.type === 'select') {
              var _option = col_description?descriptionColumn.options.find(opt => { return opt.value === col_description }):null;
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>
                {_option &&
                  <div>                  
                    <Chip
                  size="small"
                  label={<span         
                    //style={
                    //{                  
                      //color:"white", 
                      //mixBlendMode: "darken",
                    //}
                  //}
                  >{_option ?_option.label:""}</span>}                  
                  style={
                    { 
                      backgroundColor: _option ?_option.color:null,     
                      fontSize:"12px", 
                      height: "20px"  
                    }
                  }
                />    
                  </div>}
                </div>)              
            } else if (descriptionColumn.type === 'link') {
              let tbLink = props.tables[descriptionColumn.linkId]
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>

                {tbLink
                  && tbLink.items
                  && tbLink.items[col_description]
                  && descriptionColumn.linkViewField &&
                  <Chip
                    size="small"
                    //icon={<FormatColorFillIcon />}
                    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                    label={tbLink.items[col_description][descriptionColumn.linkViewField]}
                    //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}

                    //color={o.color ? o.color : "default"}
                    style={{
                      fontSize: "12px",
                      height: "20px"
                    }}
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                  //deleteIcon={<DeleteIcon />}
                  />
                }
              </div>)              
            } else if (descriptionColumn.type === 'lookup') {
              //column.lookupId && column.lookupField
              let lookupColumn = props.columns.find(cl => cl._id === descriptionColumn.lookupId)

              let tbLink = props.tables[lookupColumn.linkId]

              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>

                {tbLink
                && tbLink.items
                && tbLink.items[col_description]
                && descriptionColumn.lookupField &&
                  <Chip
                    size="small"
                    //icon={<FormatColorFillIcon />}
                    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                    label={tbLink.items[col_description][descriptionColumn.lookupField]}
                    //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}

                    //color={o.color ? o.color : "default"}
                    style={{
                      fontSize: "12px",
                      height: "20px"
                    }}
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                  //deleteIcon={<DeleteIcon />}
                  />
                }
              </div>)                   
            //} else {
            //  col_description = ""
            }else  if (descriptionColumn.type === 'date') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>

              {col_description && 
                <div> {moment(col_description).local('ru').format(descriptionColumn.date_format)}</div>
              } 
              </div>)               
            } else {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{descriptionColumn.name}</div>

              {col_description && 
                <div> {col_description}</div>
              } 
              </div>)               
            }
          }

          for(var ivc=0; ivc<viewColumns.length; ivc++){
            if(viewColumns[ivc].width>0){
            let _descriptionColumn = viewColumns[ivc]
            var _col_description_value = item[_descriptionColumn._id]
            if (_descriptionColumn.type === 'member') {
              
              let col_member = props.members.find(o => o._id === _col_description_value)
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>
                {col_member &&
                  <div><Chip
                    size="small"
                    icon={<AccountCircleIcon />}
                    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                    label={col_member.name}
                    //avatar={<Avatar>M</Avatar>}
                    //clickable
                    //color={o.color ? o.color : "default"}
                    style={
                      {
                        left: "6px",
                        top: "3px",
                        fontSize: "12px",
                        height: "20px"
                      }
                    }
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                  //deleteIcon={<DeleteIcon />}
                  />
                  </div>}
                </div>)          
            } else if (_descriptionColumn.type === 'attachments') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>
                {_col_description_value && <div

                >              
                        <AttachmentsEditor
                          id="item_attachments_editor"
                          //label={column.name}
                          //type="number"
                          //error={Boolean(titleError)}
                          //helperText={titleError}
                          view="list"
                          maxHeight="250px"
                          value={_col_description_value}                        
                          onChange={(e) => {
                            //const newVal = e.target.checked;
                            //const colkey = column._id
                            
                          }}                          
                        />  
              </div>}
              </div>)  
            } else if (_descriptionColumn.type === 'geolocation') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>
                {_col_description_value && _col_description_value.latitude && 
                <div>
                  <a target="_blank" href={`https://yandex.ru/maps/?ll=${_col_description_value.longitude}%2C${_col_description_value.latitude}84&z=18`}>
                    {`${_col_description_value.latitude}, ${_col_description_value.longitude}`}
                  </a>
                </div>}
              </div>)       
          } else if (_descriptionColumn.type === 'check') {
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{_descriptionColumn.name} </div>
                <div>
                <Checkbox
              checked={_col_description_value}
              //disabled={true}
              style={{
                padding:0
              }}              
              size="small"
              color="primary" />                
                </div>
                 
              </div>)                                
            } else if (_descriptionColumn.type === 'select') {
              var _option = _col_description_value?_descriptionColumn.options.find(opt => { return opt.value === _col_description_value }):null;
              _col_description.push(<div style={{ marginTop:5 }}>
                <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>
                {_option &&
                  <div>                  
                    <Chip
                  size="small"
                  label={<span         
                  //  style={
                  //  {                  
                  //    color:"white", 
                      //mixBlendMode: "darken",
                  //  }
                  //}
                  >{_option ?_option.label:""}</span>}                  
                  style={
                    { 
                      backgroundColor: _option ?_option.color:null,     
                      fontSize:"12px", 
                      height: "20px"  
                    }
                  }
                />    
                  </div>}
                </div>)                         
          } else if (_descriptionColumn.type === 'link') {
            let tbLink = props.tables[_descriptionColumn.linkId]
            _col_description.push(<div style={{ marginTop:5 }}>
              <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>

              {tbLink
                && tbLink.items
                && tbLink.items[_col_description_value]
                && _descriptionColumn.linkViewField &&
                <Chip
                  size="small"
                  //icon={<FormatColorFillIcon />}
                  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                  label={tbLink.items[_col_description_value][_descriptionColumn.linkViewField]}
                  //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}

                  //color={o.color ? o.color : "default"}
                  style={{
                    fontSize: "12px",
                    height: "20px"
                  }}
                //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                //deleteIcon={<DeleteIcon />}
                />
              }
            </div>)              
          } else if (_descriptionColumn.type === 'lookup') {
            //column.lookupId && column.lookupField
            let lookupColumn = props.columns.find(cl => cl._id === _descriptionColumn.lookupId)

            let tbLink = props.tables[lookupColumn.linkId]

            _col_description.push(<div style={{ marginTop:5 }}>
              <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>

              {tbLink
              && tbLink.items
              && tbLink.items[_col_description_value]
              && _descriptionColumn.lookupField &&
                <Chip
                  size="small"
                  //icon={<FormatColorFillIcon />}
                  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                  label={tbLink.items[_col_description_value][_descriptionColumn.lookupField]}
                  //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}

                  //color={o.color ? o.color : "default"}
                  style={{
                    fontSize: "12px",
                    height: "20px"
                  }}
                //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                //deleteIcon={<DeleteIcon />}
                />
              }
            </div>)                   
          //} else {
          //  col_description = ""
          }else  if (_descriptionColumn.type === 'date') {
            _col_description.push(<div style={{ marginTop:5 }}>
              <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>

            {_col_description_value && 
              <div> {moment(_col_description_value).local('ru').format(_descriptionColumn.date_format)}</div>
            } 
            </div>)               
          }else  if (_descriptionColumn.type === 'multiline') {
            _col_description.push(
            <div style={{ marginTop:5 }}>
              <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>

            {_col_description_value && 
                            <div        
                            //ref={textElementRef} 
                            class="dinabot-multiline-formater"                               
                            dangerouslySetInnerHTML={{__html: _col_description_value}}  
                          /> 
            } 
            </div>)               
          } else {
            _col_description.push(<div style={{ marginTop:5 }}>
              <div style={{ opacity: 0.70 }}>{_descriptionColumn.name}</div>

            {_col_description_value && 
              <div> {_col_description_value}</div>
            } 
            </div>)   
          }
          }          
        }

          if(labelColumn){ 
            if(labelColumn.type==='member'){

            let col_member = props.members.find(o => o._id === col_label)
            if(col_member){
              col_label = col_member.name
            }
          } else if (labelColumn.type === 'link') {
            let tbLink = props.tables[labelColumn.linkId]
            if (tbLink
              && tbLink.items
              && tbLink.items[col_label]
              && labelColumn.linkViewField) {
                col_label = tbLink.items[col_label][labelColumn.linkViewField]
            }
          } else if (labelColumn.type === 'lookup') {
            //column.lookupId && column.lookupField
            let lookupColumn = props.columns.find(cl => cl._id === labelColumn.lookupId)

            let tbLink = props.tables[lookupColumn.linkId]

            if (tbLink
              && tbLink.items
              && tbLink.items[col_label]
              && labelColumn.lookupField) {
                col_label = tbLink.items[col_label][labelColumn.lookupField]
            }
          //} else {
          //  col_label = ""
          }else  if (labelColumn.type === 'date') {
              col_label = col_label?moment(col_label).local('ru').format(labelColumn.date_format):''
          }            
          } 

          let card = { 
            id: item._id, 
            title: col_title, 
            description: _col_description, 
            label: col_label, 
            metadata: { rowIdx: item.index } 
          }
          var fl = false;
          for (let il = 1; il < _kanban_items.lanes.length; il++) {
            if (item[props.views[props.viewId].groupBy] === _kanban_items.lanes[il].id) {
              _kanban_items.lanes[il].cards.push(card)
              fl = true;
              break;
            }
          }
          if (!fl) {
            _kanban_items.lanes[0].cards.push(card)
          }
        })
        //console.log('setKanbanItems ', _kanban_items)
        setKanbanItems(_kanban_items)
      } else if (props.views[props.viewId].viewtype === 'calendar' 
      && props.views[props.viewId].calendarColumnsOptions) {
        let _events = []
        //var itemsIds = Object.keys(items);
        let colorColumn = props.views[props.viewId].calendarColumnsOptions.colorColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].calendarColumnsOptions.colorColumn }) : null;

        let titleColumn = props.views[props.viewId].calendarColumnsOptions.titleColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].calendarColumnsOptions.titleColumn }) : null;
        //console.log('setCalendarItems colorColumn ', colorColumn)
        sortedAndFilteredViewItems.forEach(item => {
          //let item = items[ii]
          var colorOption = item[props.views[props.viewId].calendarColumnsOptions.colorColumn];
          //console.log('setCalendarItems colorOption ', colorOption)
          var colorValue = colorColumn ? colorColumn.options.find(opt => { return opt.value === colorOption }) : null;
          var color = (colorValue && colorValue.color) ? colorValue.color : null
          var col_title = item[props.views[props.viewId].calendarColumnsOptions.titleColumn];
          if (titleColumn) {
            if (titleColumn.type === 'member') {
              var col_member = props.members.find(o => o._id === col_title)
              if (col_member) {
                col_title = col_member.name
              }
            }else  if (titleColumn.type === 'link') {
              let tbLink = props.tables[titleColumn.linkId]
              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.linkViewField) {
                col_title = tbLink.items[col_title][titleColumn.linkViewField]
              }              
            }else  if (titleColumn.type === 'lookup') {
              //column.lookupId && column.lookupField
              let lookupColumn = props.columns.find(cl=> cl._id === titleColumn.lookupId) 

              let tbLink = props.tables[lookupColumn.linkId]

              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.lookupField) {
                  col_title = tbLink.items[col_title][titleColumn.lookupField]
              }                
            //} else {
            //  col_title = ""
            }
          }
          //var title = titleColumn? item[props.views[props.viewId].calendarColumnsOptions.titleColumn]
          //console.log('setCalendarItems color ', color)
          let event = {
            id: item._id,
            rowIdx: item.index,
            title: col_title,
            start: item[props.views[props.viewId].calendarColumnsOptions.startColumn],
            end: item[props.views[props.viewId].calendarColumnsOptions.endColumn],
            backgroundColor: color,
            editable: true
          }
          _events.push(event)
        })
        //console.log('setCalendarItems ', _events)
        setCalendarItems(_events)
      } else if (props.views[props.viewId].viewtype === 'timeline' 
      && props.views[props.viewId].timelineColumnsOptions) {
        var _events = []
        var _resource = []

        let _resourseColumn = props.views[props.viewId].timelineColumnsOptions.resourceColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].timelineColumnsOptions.resourceColumn }) : null;
        let titleCol_name = ""
        if(_resourseColumn) {
        if(_resourseColumn.type === "link" && _resourseColumn.linkId && props.tables[_resourseColumn.linkId]){
          //Теперь выберем все ресурсы из источника 
          //TODO наверное нужен вид для фильтрации!
          let _resourseTitleColumn = _resourseColumn.linkViewField//?_resourseColumn.linkViewField:props.tables[_resourseColumn.linkId].columns.find(rc=> rc.type==="title")
          
          if(_resourseTitleColumn){
            let _resourse_items = props.tables[_resourseColumn.linkId].items
            for (var _resourse_id in _resourse_items) {
              var _res_item = props.tables[_resourseColumn.linkId].items[_resourse_id] 
              if (_res_item[_resourseTitleColumn]) {
                _resource.push({
                  id: _resourse_id,
                  name: _res_item[_resourseTitleColumn]
                })
              }
            }
            //setTimelineResourceTitle(_resourseTitleColumn)
            //setTimelineResource(props.tables[_resourseColumn.linkId].itemsList)

          }
          
          

        } else if (_resourseColumn.type === "member" && props.members) {
          for (let iopt = 0; iopt < props.members.length; iopt++) {
            _resource.push({ id: props.members[iopt]._id, name: props.members[iopt].name });
          }          
        } else if (_resourseColumn.type === "select" ) {
          for(var io=0; io<_resourseColumn.options.length; io++){
            let __options = _resourseColumn.options[io]
            _resource.push({
              id: __options.value,
              name: __options.value
            })            
          }
        }
      }
        
        //var itemsIds = Object.keys(items);
        let colorColumn = props.views[props.viewId].timelineColumnsOptions.colorColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].timelineColumnsOptions.colorColumn }) : null;

        let titleColumn = props.views[props.viewId].timelineColumnsOptions.titleColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].timelineColumnsOptions.titleColumn }) : null;
        //console.log('setCalendarItems colorColumn ', colorColumn)
        for(let ii=0; ii<sortedAndFilteredViewItems.length; ii++){
          let item = sortedAndFilteredViewItems[ii]
       // sortedAndFilteredViewItems.forEach(item => {
          //let item = items[ii]
          var colorOption = item[props.views[props.viewId].timelineColumnsOptions.colorColumn];
          //console.log('setCalendarItems colorOption ', colorOption)
          var colorValue = colorColumn ? colorColumn.options.find(opt => { return opt.value === colorOption }) : null;
          var color = (colorValue && colorValue.color) ? colorValue.color : null
          var col_title = item[props.views[props.viewId].timelineColumnsOptions.titleColumn];
          if (titleColumn) {
            if (titleColumn.type === 'member') {
              var col_member = props.members.find(o => o._id === col_title)
              if (col_member) {
                col_title = col_member.name
              }
            }else  if (titleColumn.type === 'link') {
              let tbLink = props.tables[titleColumn.linkId]
              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.linkViewField) {
                col_title = tbLink.items[col_title][titleColumn.linkViewField]
              }              
            }else  if (titleColumn.type === 'lookup') {
              //column.lookupId && column.lookupField
              let lookupColumn = props.columns.find(cl=> cl._id === titleColumn.lookupId) 

              let tbLink = props.tables[lookupColumn.linkId]

              if (tbLink 
                && tbLink.items 
                && tbLink.items[col_title]
                && titleColumn.lookupField) {
                  col_title = tbLink.items[col_title][titleColumn.lookupField]
              }                
            //} else {
            //  col_title = ""
            }
          }
          //var title = titleColumn? item[props.views[props.viewId].calendarColumnsOptions.titleColumn]
          console.log('timelineColumnsOptions ', col_title, item[_resourseColumn._id], 
          item[props.views[props.viewId].timelineColumnsOptions.startColumn],
          item[props.views[props.viewId].timelineColumnsOptions.endColumn]
          )
          if (col_title != undefined && item[_resourseColumn._id] != undefined
            && item[props.views[props.viewId].timelineColumnsOptions.startColumn] != undefined
            && item[props.views[props.viewId].timelineColumnsOptions.endColumn] != undefined
          ) {
            
            let event = {
              id: item._id,
              title: col_title,
              resourceId: item[_resourseColumn._id],
              start: item[props.views[props.viewId].timelineColumnsOptions.startColumn],
              end: item[props.views[props.viewId].timelineColumnsOptions.endColumn],
              bgColor: color,
              editable: true
            }
            console.log('timelineColumnsOptions event ', event)
            _events.push(event)
          }
        }     
        //
        //setTimelineResource(_resource)   
        //console.log('_events ', _events, ' _resource ', _resource)
        //let _timelineData = 
        //new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Week,false, false, 
        //        {
        //          resourceName:_resourseColumn.name,
                  //besidesWidth: 50
        //        });
        timelineData.setLocaleMoment(moment);
        setTimelineResource(_resource)  
        setTimelineItems(_events)
        setTimelineResourceName(_resourseColumn.name)
        timelineData.setResources(_resource);
        timelineData.setEvents(_events) 
        
        //setTimelineData(_timelineData)     
      } else if (props.views[props.viewId].viewtype === 'external' && props.views[props.viewId].externalContentOptions) {
        setExternalContentOptions(props.views[props.viewId].externalContentOptions)
      } else if (props.views[props.viewId].viewtype === 'page') {
        console.log('pageContent ', props.views[props.viewId].pageContent)
        setPageContent(props.views[props.viewId].pageContent)
      } else if (props.views[props.viewId].viewtype === 'dashboard') {
        if(props.views[props.viewId].dashboardSettings){
          console.log('props.views[props.viewId].dashboardSettings not null ', props.views[props.viewId].dashboardSettings)
          setDashboardSettings(props.views[props.viewId].dashboardSettings)

        } else {
          console.log('props.views[props.viewId].dashboardSettings null!!! ')
          setDashboardSettings({
            elemants:[

            ]
          })
        }
        setEditedDashboardSettings(false)

    } else if (props.views[props.viewId].viewtype === 'map' && props.views[props.viewId].mapOptions) {
      setMapOptions(props.views[props.viewId].mapOptions)
      if(props.views[props.viewId].mapOptions && props.views[props.viewId].mapOptions.location){
        var colLocation = props.views[props.viewId].mapOptions.location        
        let colorColumn = props.views[props.viewId].mapOptions.colorColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].mapOptions.colorColumn }) : null;
        let titleColumn = props.views[props.viewId].mapOptions.titleColumn ? props.columns.find(e => { return e._id === props.views[props.viewId].mapOptions.titleColumn }) : null;
        var _mapItems = []
        sortedAndFilteredViewItems.forEach(item => {
          if(item[colLocation] && item[colLocation].longitude && item[colLocation].latitude){
            //let position={ lat:Number.parseFloat(item[colLocation].latitude), lng: Number.parseFloat(item[colLocation].longitude) }          
            //_mapItems.push({...item, position })
            var colorOption = item[props.views[props.viewId].mapOptions.colorColumn]
            var colorValue = (colorColumn && colorOption) ? colorColumn.options.find(opt => { return opt.value === colorOption }) : null;
            var color = (colorValue && colorValue.color) ? colorValue.color : null
    
            let position=[Number.parseFloat(item[colLocation].latitude), Number.parseFloat(item[colLocation].longitude)] 
            var col_title = item[props.views[props.viewId].mapOptions.titleColumn];
            if (titleColumn) {
              if (titleColumn.type === 'member') {
                var col_member = props.members.find(o => o._id === col_title)
                if (col_member) {
                  col_title = col_member.name
                }
              }else  if (titleColumn.type === 'link') {
                let tbLink = props.tables[titleColumn.linkId]
                if (tbLink 
                  && tbLink.items 
                  && tbLink.items[col_title]
                  && titleColumn.linkViewField) {
                  col_title = tbLink.items[col_title][titleColumn.linkViewField]
                }              
              }else  if (titleColumn.type === 'lookup') {
                //column.lookupId && column.lookupField
                let lookupColumn = props.columns.find(cl=> cl._id === titleColumn.lookupId) 
  
                let tbLink = props.tables[lookupColumn.linkId]
  
                if (tbLink 
                  && tbLink.items 
                  && tbLink.items[col_title]
                  && titleColumn.lookupField) {
                    col_title = tbLink.items[col_title][titleColumn.lookupField]
                }                
              //} else {
              //  col_title = ""
              }
            }         
            _mapItems.push({item:item, position, color, title:col_title })
          }
        })
        //console.log('_mapItems ', _mapItems)
        setMapItems(_mapItems)
      } else {
        setMapItems([])
      }
      
    }      
    }
    //console.log('_kanban_items ', _kanban_items)

  }, [props.columns, props.members, props.chatmembers, props.tables, sortedAndFilteredViewItems, props.viewId, props.views, viewColumns])

  useEffect(()=>{
    //var _filter = props.question.condition?props.question.condition:{"id": QbUtils.uuid(), "type": "group"}
    //console.log('useEffect props.columns ', props.columns)
    let _fields = {}
    if(props.columns && props.columns.length>0){        
      for(let ic=0; ic<props.columns.length; ic++){
        _fields[props.columns[ic]._id] = {
          label: props.columns[ic].name,
          type: props.columns[ic].type,
       
        }
        if(props.columns[ic].type === 'select'){
          _fields[props.columns[ic]._id].valueSources = ['value'];
          _fields[props.columns[ic]._id].fieldSettings= {
            listValues: props.columns[ic].options.map(opt=>{return {value:opt.value, title:opt.label}}),
          }               
        } else if(props.columns[ic].type === 'check'){
          _fields[props.columns[ic]._id].type = 'boolean'            
        } else if(props.columns[ic].type === 'link'){
          if(props.tables[props.columns[ic].linkId] && props.tables[props.columns[ic].linkId].columns){
            var titleColId = props.columns[ic].linkViewField//?props.columns[ic].linkViewField:props.tables[props.columns[ic].linkId].columns.find(cl=> cl.type === 'title')
            if(titleColId){
            var tableValues = props.tables[props.columns[ic].linkId].itemsList?props.tables[props.columns[ic].linkId].itemsList:[]
            //console.log('filter tableValues', tableValues, titleColId)
            _fields[props.columns[ic]._id].type = 'select'
            _fields[props.columns[ic]._id].valueSources = ['value'];
            _fields[props.columns[ic]._id].fieldSettings= {
              
              listValues: tableValues.map(opt=>{return {value:opt._id, title:opt[titleColId]}}),
            } 
          }
          }
        } else if(props.columns[ic].type === 'member'){
          _fields[props.columns[ic]._id].type = 'select'
          _fields[props.columns[ic]._id].valueSources = ['value'];
          _fields[props.columns[ic]._id].fieldSettings= {
            listValues: props.members.map(opt=>{return {value:opt._id, title:opt.name?opt.name:opt.email}}),            
          } 
        } else if(props.columns[ic].type === 'chatmember'){
          _fields[props.columns[ic]._id].type = 'select'
          _fields[props.columns[ic]._id].valueSources = ['value'];
          _fields[props.columns[ic]._id].fieldSettings= {
            listValues: props.chatmembers.map(opt=>{return {value:opt._id, title:opt.first_name?(opt.first_name+(opt.last_name?(" "+opt.last_name):"")):opt.name}}),
          }  
        } else if(props.columns[ic].type === 'date' || props.columns[ic].type === 'datetime'){
          _fields[props.columns[ic]._id].funcs = ['TODAY']
          _fields[props.columns[ic]._id].valueSources = ["value", "field", "func"]
        } else if(props.columns[ic].type === 'title'){
          _fields[props.columns[ic]._id].type = 'text'
        } else if(props.columns[ic].type === 'multiline'){
          _fields[props.columns[ic]._id].type = 'text'
        }
      }       
    }
    //setFields(_fields);
    var _funcs = {
      CurrentMember: {
        label: "@Текущий_пользователь",
        returnType: "string",
        mongoFunc: "$toLower",
        jsonLogic: ({str}) => ({ "method": [ str, "toLowerCase" ] }),
        returnType: "text",
        args: {
          str: {
            label: "String",
            type: "text",
            valueSources: ["value", "field"],
          },
        }
      },            
      ABCD: {
        label: "ABCD",
        returnType: "string",
        mongoFunc: "$toLower",
        jsonLogic: ({str}) => ({ "method": [ str, "toLowerCase" ] }),
        returnType: "text",
        args: {
          str: {
            label: "String",
            type: "text",
            valueSources: ["value", "field"],
          },
        }
      },          
      TODAY: {
        label: "Today",
        returnType: "date",
        //formatFunc: ({}, _) => `new Date()`,
        //sqlFormatFunc: ({}) => `new Date()`,
        //mongoFunc: `new Date()`,
        //jsonLogic: ({coef, bias, val}) => ({ "+": [ {"*": [coef, val]}, bias ] }),
        mongoFormatFunc: ({}) => ("new Date()"),
        //mongoFunc: "$toLower",
        //jsonLogic: ({str}) => ({ "method": [ str, "toLowerCase" ] }),   
        //mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0))).toISOString(), '$lt':(new Date((new Date()).setHours(23,59,59))).toISOString() }}),                 
        args: {
          //str: {
          //  label: "String",
          //  type: "text",
          //  valueSources: ["value", "field"],
          //},
        }
      },        
      LOWER: {
        label: "Lowercase",
        mongoFunc: "$toLower",
        jsonLogic: ({str}) => ({ "method": [ str, "toLowerCase" ] }),
        returnType: "text",
        args: {
          str: {
            label: "String",
            type: "text",
            valueSources: ["value", "field"],
          },
        }
      },
      LINEAR_REGRESSION: {
        label: "Linear regression",
        returnType: "number",
        formatFunc: ({coef, bias, val}, _) => `(${coef} * ${val} + ${bias})`,
        sqlFormatFunc: ({coef, bias, val}) => `(${coef} * ${val} + ${bias})`,
        mongoFormatFunc: ({coef, bias, val}) => ({"$sum": [{"$multiply": [coef, val]}, bias]}),
        jsonLogic: ({coef, bias, val}) => ({ "+": [ {"*": [coef, val]}, bias ] }),
        renderBrackets: ["", ""],
        renderSeps: [" * ", " + "],
        args: {
          coef: {
            label: "Coef",
            type: "number",
            defaultValue: 1,
            valueSources: ["value"],
          },
          val: {
            label: "Value",
            type: "number",
            valueSources: ["value"],
          },
          bias: {
            label: "Bias",
            type: "number",
            defaultValue: 0,
            valueSources: ["value"],
          }
        }
      },
    }

    const _config = {
      ...InitialConfig,
      fields: _fields,
      //funcs:_funcs
    };     
    _config.operators.current_member={
      label: "@Текуший_пользователь",
      labelForFormat: "@Current_Member",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$eq': props.currentMember?props.currentMember._id:null }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }          
    _config.operators.after_now={
      label: "> Сейчас",
      labelForFormat: "AFTER NOW",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$gte': "new Date()" }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }     
    _config.operators.befor_now={
      label: "< Сейчас",
      labelForFormat: "BEFOR NOW",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$lt': "new Date()" }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }          
    _config.operators.for_today={
      label: "За сегодня",
      labelForFormat: "FOR TODAY",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0))), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }
    _config.operators.for_week={
      label: "За неделю",
      labelForFormat: "FOR WEEK",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-604800000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }    
    _config.operators.for_30_day={
      label: "За 30 дней",
      labelForFormat: "FOR 30 DAY",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-2592000000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }  
    _config.operators.for_current_month={
      label: "За текущий месяц",
      labelForFormat: "FOR CURRENT MONTH",
      //reversedOp:"not_today",  
      cardinality: 0,
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
      mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).getFullYear(), (new Date()).getMonth(),1)), '$lt':(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1,0)) }}),
      //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
      
    }          
    console.log('_config.types', _config.types)
    //console.log('_config.types.date.operators ',_config.types.date.operators)
    if(_config.types.select.widgets.select.operators.findIndex(el=> el==='current_member')<0){
      _config.types.select.widgets.select.operators.push('current_member')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='after_now')<0){
    _config.types.date.widgets.date.operators.push('after_now')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='befor_now')<0){
    _config.types.date.widgets.date.operators.push('befor_now')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_today')<0){
    _config.types.date.widgets.date.operators.push('for_today')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_week')<0){
    _config.types.date.widgets.date.operators.push('for_week')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_30_day')<0){
    _config.types.date.widgets.date.operators.push('for_30_day')
    }
    if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_current_month')<0){
    _config.types.date.widgets.date.operators.push('for_current_month')
    }
    _config.types.geolocation = {
      valueSources: ['value'],
      defaultOperator: 'is_not_empty',
      widgets: {
          time: {
            operators: ['is_empty', 'is_not_empty'],
            opProps: {
            },
        },          
      },
  }       

    
 // _config.widgets.date.valueSrc = ['value', 'func']
 // _config.widgets.datetime.valueSrc = ['value', 'func']
    //console.log('_config ', _config)
    
    _config.settings.locale = {
      moment: 'ru',
      //antd: ru_RU,
      material: ruRU,
    };
    _config.settings.showNot= false;   
    _config.settings.maxNesting = 1; 
    _config.settings.groupActionsPosition =  "bottomLeft"
    //Локализуем
    _config.conjunctions.AND.label="И"
    _config.conjunctions.OR.label="ИЛИ"
    _config.settings.valueLabel = "Значение"
    _config.settings.valuePlaceholder = "Значение"
    _config.settings.fieldLabel = "Поле"
    _config.settings.operatorLabel = "Оператор"
    _config.settings.funcLabel = "Функция"
    _config.settings.fieldPlaceholder = "Выберите поле"
    _config.settings.funcPlaceholder = "Выберите функцию"
    _config.settings.operatorPlaceholder = "Выберите оператор"
    _config.settings.deleteLabel = null//"Удалить значение"
    _config.settings.delGroupLabel = null//"Удалить группу"
    _config.settings.addGroupLabel = "Добавить группу"
    _config.settings.addRuleLabel = "Условие"
    _config.settings.notLabel = "Не"
    _config.settings.valueSourcesPopupTitle = "Выберите источник"
    //_config.removeRuleConfirmOptions = {}
    //_config.removeRuleConfirmOptions.title = "Вы уверены?"
    //_config.removeRuleConfirmOptions.okText = "Да"
    //_config.removeRuleConfirmOptions.okType = "Значение"
    //_config.removeGroupConfirmOptions.title = "Вы уверены?"
    //_config.removeGroupConfirmOptions.okText = "Да"
    //_config.removeGroupConfirmOptions.okType = "Значение"
    _config.operators.between.label = "Между"
    _config.operators.is_empty.label = "Пусто"
    _config.operators.is_not_empty.label = "Не пусто"
    _config.operators.like.label = "Подобно"
    _config.operators.multiselect_equals.label = "Равно"
    _config.operators.multiselect_not_equals.label = "Не равно"
    _config.operators.not_between.label = "Не между"
    _config.operators.not_like.label = "Не подобно"
    _config.operators.proximity.label = "Поиск"
    _config.operators.select_any_in.label=  "Любой в"
    _config.operators.select_not_any_in.label = "Не в"
    _config.operators.starts_with.label = "Начинается с" 
    
    _config.widgets.boolean.labelNo = "Нет"  
    _config.widgets.boolean.labelYes = "Да"  

    _config.widgets.date.valueLabel = "Дата"   
    _config.widgets.date.valuePlaceholder = "Введите дату"   
    _config.widgets.date.valueLabels[0] = {label: "От", placeholder: "Введите дату от"}
    _config.widgets.date.valueLabels[1] = {label: "До", placeholder: "Введите дату до"}
    _config.widgets.datetime.valueLabel = "Дата и время" 
    _config.widgets.datetime.valuePlaceholder = "Введите дату и время"   
    _config.widgets.datetime.valueLabels[0] = {label: "От", placeholder: "Введите дату от"}
    _config.widgets.datetime.valueLabels[1] = {label: "До", placeholder: "Введите дату до"}    
    _config.widgets.field.valueLabel = "Поле для сравнения"
    _config.widgets.field.valuePlaceholder = "Выберите поле для сравнения"
    _config.widgets.func.valueLabel = "Функция"
    _config.widgets.func.valuePlaceholder = "Выберите функцию"
    _config.widgets.multiselect.valueLabel = "Значения"
    _config.widgets.multiselect.valuePlaceholder = "Выберите значения"
    _config.widgets.number.valueLabel = "Число" 
    _config.widgets.number.valuePlaceholder = "Введите число"   
    _config.widgets.number.valueLabels[0] = {label: "От", placeholder: "Введите число от"}
    _config.widgets.number.valueLabels[1] = {label: "До", placeholder: "Введите число до"} 
    _config.widgets.rangeslider.valueLabel = "Диапазон"
    _config.widgets.rangeslider.valuePlaceholder = "Введите диапазон"
    _config.widgets.rangeslider.valueLabels[0] = {label: "От", placeholder: "Введите число от"}
    _config.widgets.rangeslider.valueLabels[1] = {label: "До", placeholder: "Введите число до"} 
    _config.widgets.select.valueLabel = "Значение"
    _config.widgets.select.valuePlaceholder = "Выберите значение"
    _config.widgets.slider.valueLabel = "Число"
    _config.widgets.slider.valuePlaceholder = "Введите число или переместите слайдер" 
    _config.widgets.text.valueLabel = "Текст"
    _config.widgets.text.valuePlaceholder = "Введите текст"            
    _config.widgets.time.valueLabel = "Время" 
    _config.widgets.time.valuePlaceholder = "Введите время"   
    _config.widgets.time.valueLabels[0] = {label: "От", placeholder: "Введите время от"}
    _config.widgets.time.valueLabels[1] = {label: "До", placeholder: "Введите время до"}        
    //console.log('_config ', _config)
    setConfig(_config)

    var chatmem_cols = []
    var edited_cols = []
    var link_cols = []
    var link_views = {}
    var link_mem_cols = {}
    var attachments_cols=[]
    var geolocation_cols=[]
    for(let ic=0; ic<props.columns.length; ic++){
      if(props.columns[ic].type==='member'){
        chatmem_cols.push(props.columns[ic]);
      }
      if(props.columns[ic].type==='attachments'){
        attachments_cols.push(props.columns[ic]);
      }
      if(props.columns[ic].type==='geolocation'){
        geolocation_cols.push(props.columns[ic]);
      }            
      if(props.columns[ic].type!=='lookup'){ 
        if(props.columns[ic]._id==="ex_rAnswered" ||
        props.columns[ic]._id==="ex_requestDt" ||
        props.columns[ic]._id==="ex_rAnswerDt" ||
        props.columns[ic]._id==="ex_updatedAt" ||
        props.columns[ic]._id==="ex_createdAt" ||
        props.columns[ic]._id==="ex_changeAutor" ||  
        props.columns[ic]._id==="ex_createdAutor" ||                   
        props.columns[ic]._id==="ex_requestAc" ||
        props.columns[ic]._id==="_id"
        ){

        } else {                     
          edited_cols.push(props.columns[ic])
        }
      }
      if(props.columns[ic].type==='link'){            
        let link_tb = props.tables[props.columns[ic].linkId]
        if(link_tb){
          link_cols.push(props.columns[ic])
          link_views[props.columns[ic]._id] = link_tb.viewsList
          link_mem_cols[props.columns[ic]._id] = link_tb.columns.filter(cl=> {
            if(cl.type==='member') {
              return true
            } else if(cl.type==='lookup' && cl.lookupId){
              var lookupColumn = link_tb.columns.find(cl2=> cl2._id === cl.lookupId)
              if(lookupColumn) {
                if(props.tables[lookupColumn.linkId]){
                  var lookuplookupColumn = props.tables[lookupColumn.linkId].columns.find(cl3=> cl3._id===cl.lookupField)
                  
                  if(lookuplookupColumn.type==='member'){
                    return true
                  }
                  
                }                   
                
              }
            }
          return false
            
          })
          if(!link_mem_cols[props.columns[ic]._id]){
            link_mem_cols[props.columns[ic]._id] = []
          }
        }
        
      }              
    }
    //console.log('DialogEditor useEffect link_views', link_views, link_mem_cols)
    setLinkViews(link_views)
    setLinkColumns(link_cols); 
    setEditedColumns(edited_cols); 
    setLinkMemberColumns(link_mem_cols);    
    setAttachmentsColumns(attachments_cols)
    setGeolocationColumns(geolocation_cols)
    setMemberColumns(chatmem_cols);         
  }, [props.columns, props.tables, props.currentMember])   


  useEffect(()=>{
    if(props.views){    
      var _views = []
      for(let v in props.views){
        if(props.views[v].viewtype!=='dialog'){
          _views.push(props.views[v])
        }
        
      }
      //console.log('ViewsConfigList views', views);
      setNonDialogViews(_views)
    } else {
      setNonDialogViews([])
    }
  }, [props.views])  


  /**
   * Это событие идет от таблицы при изменении записей в ней
   * Приходят сразу все записи
   * Что изменилось приходит в change_data в виде индексов строк и колонки
   * {
    "indexes": [
        3,
        4,
        5
    ],
    "column": {
        "_id": "617ae02f488c155194d65716",
        "name": "Название",
        ...
        "minWidth": 0,
        "idx": 1,
        "isLastFrozenColumn": true,
        "rowGroup": false
    }
}
   */
  const onRowsChange = useCallback((_rows, change_data) => {
    console.log('onRowsChange ', _rows, ' change_data ', change_data) 
    if(change_data && change_data.indexes && change_data.indexes.length>0){
      var changed_values = []
      for(var i=0; i<change_data.indexes.length; i++){
      var idx = change_data.indexes[i]
        changed_values.push({
          _id:_rows[idx]._id,
          [change_data.column._id]: _rows[idx][change_data.column._id]
        })
        
      }
      props.onItemsUpdate(props.tableId, changed_values)
    } else {
      //TODO ЭТО ВОЗМОЖНО УСТАРЕЛО И НАДО УБРАТЬ!. РАНЬШЕ НЕ БЫЛО change_data!

    //Если есть фильтр, то надо перерисовать? т.е. useEffect не срабатывает!
    //А если изменились?
      
    let rows = [];
    //rows.push({_id:'new_row'})
    let _maxIndex = 0;
    var parentFilter = null

    for (var i in props.items) {
      if(props.tables[props.tableId].sourceWorkItemList) {
        if(props.items[i][props.tables[props.tableId].parentInSourceColumn] === props.tables[props.tableId].parentWorkItem){
          rows.push(props.items[i])
          _maxIndex = Math.max(props.items[i].index, _maxIndex)
      }
      } else {
        rows.push(props.items[i])
        _maxIndex = Math.max(props.items[i].index, _maxIndex)
      }

    }
    setNewItemIndex(_maxIndex)
    rows = rows.sort((a, b) => a.index - b.index);
    //console.log('mongoFilter ', mongoFilter)
    if(props.tables[props.tableId].sourceWorkItemList && props.tables[props.tableId].sourceFilterMongo){
      rows = Query.query(rows, props.tables[props.tableId].sourceFilterMongo);
    }
    rows = Query.query(rows, mongoFilter);
    setSortedAndFilteredViewItems(rows)
         
    }
    
    

  }, [props.tableId, props.items, props.onItemsUpdate, sortedAndFilteredViewItems, mongoFilter]); 

  const updateSortedAndFilteredViewItems = useCallback(() => {

    //console.log('sortedAndFilteredViewItems !!!')
    let rows = [];
    //rows.push({_id:'new_row'})
    let _maxIndex = 0;
    for (var i in props.items) {
      if(props.tables[props.tableId].sourceWorkItemList) {
        if(props.items[i][props.tables[props.tableId].parentInSourceColumn] === props.tables[props.tableId].parentWorkItem){
          rows.push(props.items[i])
          _maxIndex = Math.max(props.items[i].index, _maxIndex)
      }
      } else {
      rows.push(props.items[i])
      _maxIndex = Math.max(props.items[i].index, _maxIndex)
      }
    }
    setNewItemIndex(_maxIndex)
    rows = rows.sort((a, b) => a.index - b.index);
    if(props.tables[props.tableId].sourceWorkItemList && props.tables[props.tableId].sourceFilterMongo){
      rows = Query.query(rows, props.tables[props.tableId].sourceFilterMongo);
    }
    rows = Query.query(rows, mongoFilter);
    console.log('sortedAndFilteredViewItems !!!', rows)
    setSortedAndFilteredViewItems(rows)
  }, [props.items,props.tables,props.tableId, mongoFilter]);  


  useEffect(() => {
    //if (sortDirection === 'NONE') return rows;
    updateSortedAndFilteredViewItems()

  }, [props.items, mongoFilter]);    

  useEffect(() => {
    var columns = []
    var visibleColumns = []
    if (props.views && props.viewId && props.views[props.viewId]) {
      if(props.views[props.viewId].viewtype === 'list' || props.views[props.viewId].viewtype === 'kanban' ) {
      //console.log('useEffect setViewColumns!', props.columns)
      //props.columns?[...props.columns]:[]
      var nextIndex = 99//columnsKeys.length; 
      
      for (let ic = 0; ic < props.columns.length; ic++) {
        let colOpt = props.columns[ic]
        let colOpt2 = {}
        if (colOpt && props.views[props.viewId].listColumnsOptions) {
          colOpt2 = props.views[props.viewId].listColumnsOptions[colOpt._id];
          if (!colOpt2) {
            colOpt2 = {
              index: nextIndex,
              frozen: false,
              width: 0
            }
            nextIndex++
          }
        } 

        colOpt = {
          ...colOpt,
          ...colOpt2,
        }        
        //colOpt.idx = colOpt.index
        colOpt.key = colOpt._id//В коде DataGrid к сожалению используется свойство .key колонки!
        columns.push(colOpt)
        columns.sort((a, b) => a.index - b.index)
        visibleColumns =    columns.filter(cl=>           cl.width>0)             
      }



      } else {
        columns = [...props.columns]
      }
    }
    setViewColumns(columns)
    setViewVisibleColumns(visibleColumns)
  }, [props.columns, props.views, props.viewId]);  
  
  useEffect(()=>{
    if(props.columns && props.columns.length>0){
      var defCol = []
      for(var ic=0; ic<props.columns.length; ic++){
        var col = props.columns[ic]
        if(col._id==="ex_rAnswered" ||
        col._id==="ex_requestDt" ||
        col._id==="ex_rAnswerDt" ||
        col._id==="ex_updatedAt" ||
        col._id==="ex_createdAt" ||
        col._id==="ex_changeAutor" || 
        col._id==="ex_createdAutor" ||                    
        col._id==="ex_requestAc" ||
        col._id==="_id"
        ){

        } else {
          defCol.push(col)
        }
      }
      setDefaultColumns(defCol)        
    } else {
      setDefaultColumns([])
    }
  
  }, [props.columns])   

    return (            
      <div style={{overflow:"hidden", height: `calc(100vh - 88px)`}} >      
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} targetRef={headerRef}>
          <div ref={headerRef}>            
          {props.errorMsg && <Alert variant="filled" severity="error" onClose={() => {props.setErrorMsg(null)}}>{props.errorMsg}</Alert>}
                            
          <Fade
          in={props.loadingWI}
          style={{
            transitionDelay: props.loadingWI ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>   
                  <DialogActions
                  style={{
                    paddingBottom:3,
                    paddingTop:3,
                    backgroundColor: "white",
                    boxShadow: "rgb(200 200 200) 0 1px 0 0"
                  }}
                  > 
      
            {props.views && props.views.hasOwnProperty(props.viewId)  && 
            <>
            {!props.sharedView &&                   <div
                    style={{
                      display:"flex",
                      "-webkit-box-flex": 1,
                      flexGrow: 1,
                      flexShrink: 1,
                      flexBasis: "auto"
                    }}
                    >
                     
                    {props.views[props.viewId].viewtype !== "dialog" && 
                    props.views[props.viewId].viewtype !== "external" &&
                    props.views[props.viewId].viewtype !== "dashboard" &&
                    <>
                       {false && <ViewDialogsMenu
                          dialogs={props.dialogs && props.dialogs.filter(dialog=>
                            dialog && dialog.filter && dialog.filter===props.viewId
                          )}
                          dbId={props.dbId}
                          editedDialogId={props.editedDialogId}
                          setEditedDialogId={props.setEditedDialogId}
                          editedDialogTableId={props.editedDialogTableId}
                          setEditedDialogTableId={props.setEditedDialogTableId}
                          setIsEditedJobOpen={props.setIsEditedJobOpen}
                          onOpenJobsPanel={props.onOpenJobsPanel}
                          viewTitle={props.views[props.viewId].title}
                          viewId={props.viewId}
                          tableId={props.tableId} 
                          addDialog={props.addDialog}
                          rigthPanelContent={props.rigthPanelContent}
                          setRigthPanelContent={props.setRigthPanelContent}
                          collapseHeaderButton={collapseHeaderButton}
                        />}
                      <ViewFilterDialog 
                        columns={props.columns} 
                        filter={props.views[props.viewId].filter} 
                        handleChangeFilterViewOptions={handleChangeFilterViewOptions} 
                        setMongoFilter={setMongoFilter}
                        members={props.members}
                        chatmembers={props.chatmembers}
                        tables={props.tables}
                        userId={userId}
                        config={config}
                        collapseHeaderButton={collapseHeaderButton}
                        containerEl={headerRef}
                        /> 
                      </>}
                      {false && props.views[props.viewId].viewtype == "dialog" && dialog &&
                    <>
                      <ViewFilterDialog 
                        columns={props.columns} 
                        filter={dialog.filterIn} 
                        handleChangeFilterViewOptions={handleChangeFilterDialogOptions} 
                        setMongoFilter={setMongoFilter}
                        members={props.members}
                        chatmembers={props.chatmembers}
                        tables={props.tables}
                        userId={userId}
                        config={config}
                        collapseHeaderButton={collapseHeaderButton}
                        /> 
                      </>}                      
                    {props.views[props.viewId].viewtype === "kanban"  && 
                      <GroupBySettingsMenu 
                        columns={props.columns} 
                        groupBy={props.views[props.viewId].groupBy} 
                        handleChangeGroupBy={props.handleChangeGroupBy} 
                        tableId={props.tableId} viewId={props.viewId} 
                        collapseHeaderButton={collapseHeaderButton}
                      />
                    }                    
                    {props.views[props.viewId].viewtype === "list" && 
                      <>{false && <GroupsSettingsMenu 
                      columns={viewColumns.filter(cl=> cl.type==='select' || cl.type==='member' || cl.type==='check' || cl.type==='link' || cl.type==='text' || cl.type==='number' || cl.type==='date')} 
                      groups={(props.views[props.viewId].groups && props.columns)?props.views[props.viewId].groups.map(g=>
                        {
                          var __g = {...g}
                          var __cl = props.columns.find(cl=>g.column===cl._id)
                          if(__cl){
                            __g.name = __cl.name
                            return __g
                          } 
                          return
                      }):[]} 
                      handleChangeGroups={props.handleChangeGroups} 
                      tableId={props.tableId} 
                      viewId={props.viewId} 
                      accessLevel={props.accessLevel}
                      collapseHeaderButton={collapseHeaderButton}
                      />}
                      <SortsSettingsMenu 
                      columns={props.columns} 
                      sorts={(props.views[props.viewId].sorts && props.columns)?props.views[props.viewId].sorts.map(g=>
                        {
                          console.log('sorts g ', g)
                          var __g = {...g}
                          var __cl = props.columns.find(cl=>g.columnKey===cl._id)
                          if(__cl){
                            __g.name = __cl.name
                            return __g
                          } 
                          return
                      }):[]} 
                      handleChangeSorts={props.handleChangeSorts} 
                      tableId={props.tableId} 
                      viewId={props.viewId} 
                      accessLevel={props.accessLevel}
                      collapseHeaderButton={collapseHeaderButton}
                      />                      
                      </>
                    }                    
                    {props.views[props.viewId].viewtype === "list" && 
                    <ColumnsViewMenu 
                    columns={viewColumns} 
                    tableId={props.tableId} 
                    onColumnResize={props.onColumnResize} 
                    handleColumnsReorder={props.handleColumnsReorder} 
                    viewId={props.viewId}  

                    saveDefaultValues={props.saveDefaultValues} 
                    members={props.members} 
                    chatmembers={props.chatmembers}  
                    
                    tables={props.tables}
                    defaultValues={props.views[props.viewId].defaultValues}                    
                    defaultcolumns={defaultcolumns}
                    accessLevel={props.accessLevel}
                    collapseHeaderButton={collapseHeaderButton}
                    currentMember={props.currentMember}
                    />}
                    {props.views[props.viewId].viewtype === "list" && <>
                    <RowHeightMenu 
                    handleSetRowHeight={props.handleSetRowHeight} 
                    tableId={props.tableId} viewId={props.viewId}
                    />
                    </>
                    }
                    {!props.sharedView && props.views[props.viewId].viewtype === "calendar" && 
                     <CalendarViewSettingsMenu 
                      columns={props.columns} 
                      calendarColumnsOptions={props.views[props.viewId].calendarColumnsOptions} 
                      handleChangeCalendarViewOptions={handleChangeCalendarViewOptions} 
                      
                      saveDefaultValues={props.saveDefaultValues} 
                      members={props.members} 
                      chatmembers={props.chatmembers}  
                      
                      tables={props.tables}
                      tableId={props.tableId}
                      viewId={props.viewId}
                      defaultValues={props.views[props.viewId].defaultValues}   
                      defaultcolumns={defaultcolumns}  
                      accessLevel={props.accessLevel} 
                      collapseHeaderButton={collapseHeaderButton}   
                      currentMember={props.currentMember}                                
                      />}
                    {!props.sharedView && props.views[props.viewId].viewtype === "timeline" && 
                    <TimelineViewSettingsMenu 
                      columns={props.columns} 
                      timelineColumnsOptions={props.views[props.viewId].timelineColumnsOptions} 
                      handleChangeTimelineViewOptions={handleChangeTimelineViewOptions} 
                      
                      saveDefaultValues={props.saveDefaultValues} 
                      members={props.members} 
                      chatmembers={props.chatmembers}  
                      
                      tables={props.tables}
                      tableId={props.tableId}
                      viewId={props.viewId}
                      defaultValues={props.views[props.viewId].defaultValues}    
                      defaultcolumns={defaultcolumns} 
                      accessLevel={props.accessLevel}    
                      collapseHeaderButton={collapseHeaderButton}   
                      currentMember={props.currentMember}           
                      />}
                    {!props.sharedView && props.views[props.viewId].viewtype === "map" && 
                    <MapViewSettingsMenu 
                      columns={props.columns} 
                      mapOptions={props.views[props.viewId].mapOptions} 
                      handleChangeMapViewOptions={handleChangeMapViewOptions} 
                      
                      saveDefaultValues={props.saveDefaultValues} 
                      members={props.members} 
                      chatmembers={props.chatmembers}  
                      
                      tables={props.tables}
                      tableId={props.tableId}
                      viewId={props.viewId}
                      defaultValues={props.views[props.viewId].defaultValues}    
                      defaultcolumns={defaultcolumns} 
                      accessLevel={props.accessLevel}    
                      collapseHeaderButton={collapseHeaderButton}   
                      currentMember={props.currentMember}           
                      />}                      

                    {!props.sharedView && props.views[props.viewId].viewtype === "kanban" && 
                    <KanbanViewSettingsMenu 
                      columns={viewColumns }
                      kanbanColumnsOptions={props.views[props.viewId].kanbanColumnsOptions} 
                      handleChangeKanbanViewOptions={handleChangeKanbanViewOptions} 
                      onColumnResize={props.onColumnResize} 
                      saveDefaultValues={props.saveDefaultValues} 
                      members={props.members} 
                      chatmembers={props.chatmembers}  
                      handleColumnsReorder={props.handleColumnsReorder}
                        
                      tables={props.tables}
                      tableId={props.tableId}
                      viewId={props.viewId}
                      defaultValues={props.views[props.viewId].defaultValues}                      
                      defaultcolumns={defaultcolumns}
                      accessLevel={props.accessLevel}  
                      collapseHeaderButton={collapseHeaderButton}  
                      currentMember={props.currentMember}              
                    />}

                    {!props.sharedView && props.views[props.viewId].viewtype === "dialog" &&
                    <>

                    <DialogViewMenu
                                        dialog={dialog}
                                        onChangeDialog={onChangeDialog}
                                        viewsList={nonDialogViews}
                                        views={props.views}
                                        dbId={props.dbId}
                                        tableId={props.tableId}
                    />
                    <DialogViewSettingsMenu
                      views={nonDialogViews}
                      dialog={dialog}
                      memberColumns={memberColumns}
                      linkColumns={linkColumns}
                      linkMemberColumns={linkMemberColumns}
                      linkViews={linkViews}
                      editedColumns={editedColumns}
                      onChangeDialog={onChangeDialog}
                      accessLevel={props.accessLevel}
                      collapseHeaderButton={collapseHeaderButton}
                      currentMember={props.currentMember}
                    />
                    {!props.sharedView &&     <ShareDialogSettingsMenu
                        botchannel={props.botchannel}
                        chatViewLinks={props.chatViewLinks}
                        createChatViewLink={props.createChatViewLink}
                        deleteChatViewLink={props.deleteChatViewLink}
                        viewId={props.viewId}
                        tableId={props.tableId}  
                        accessLevel={props.accessLevel}  
                        collapseHeaderButton={collapseHeaderButton}                    
                        /> 
                    }
                        </>
                        }
                      {!props.sharedView && props.views[props.viewId].viewtype === "dashboard" && 
                        dashboardEditedMode &&
                        <>
  
                  <AddChartToDashboardDialog
                    dataSources = {geDataSourceForPlotlyEditor(props.columns, props.items, props.members, props.tables)}
                    setDashboardSettings={setDashboardSettings}
                    setEditedDashboardSettings={setEditedDashboardSettings}
                    accessLevel={props.accessLevel}
                  />
  <IconButton 
    size="small" 
    variant="text"
    disabled={props.accessLevel<9}      
    onClick={(e)=>{
      setDashboardSettings(cur => {
        var newSettings = { ...cur }
        var i = ObjectID().toHexString()
        const newChat = {
          i: i,
          type:"html",
          html: "",
          data_grid: { x: 0, y: 0, w: 50, h: 10 }
        }
        if (!newSettings.elements) {
          newSettings.elements = []
        }
        newSettings.elements.push(newChat)
        return newSettings
      })
    }}
    >      
    <TextFieldsIcon fontSize="small"/>
      </IconButton>   
      {collapseHeaderButton ? <IconButton                              
                          disabled = {!editedDashboardSettings}
                          size="small" 
                          variant="text" 
                          onClick={handleClickDashboardSettingsSave}                
                        >
                        <SaveIcon fontSize="small"/>
                        </IconButton> :
                        <Button    
                          startIcon={<SaveIcon fontSize="small"/>}
                          disabled = {!editedDashboardSettings}
                          size="small" 
                          variant="text" 
                          onClick={handleClickDashboardSettingsSave}                
                        >
                        Сохранить
                        </Button>                        
                        }
                        {collapseHeaderButton ? 
                          <IconButton                              
                          size="small" 
                          variant="text" 
                          onClick={e=> setDashboardEditedMode(false)}                
                        >
                        <VisibilityIcon fontSize="small"/>
                        </IconButton>:                        
                        <Button    
                          startIcon={<VisibilityIcon fontSize="small"/>}
                          size="small" 
                          variant="text" 
                          onClick={e=> setDashboardEditedMode(false)}                
                        >
                        Просмотр
                        </Button>    
                        }                       
                        </>
                        }
                    {!props.sharedView &&  props.views[props.viewId].viewtype === "dashboard" && 
                        props.accessLevel>=9 && !dashboardEditedMode &&
                        <> 
                        {collapseHeaderButton ? 
                        <IconButton                              
                          size="small" 
                          variant="text" 
                          onClick={e=> setDashboardEditedMode(true)}                
                        >
                        <EditIcon fontSize="small"/>
                        </IconButton> :  
                        <Button    
                        startIcon={<EditIcon fontSize="small"/>}
                        size="small" 
                        variant="text" 
                        onClick={e=> setDashboardEditedMode(true)}                
                      >
                      Редактировать
                      </Button>  }
                      </>                
                      }                        
                    {props.views[props.viewId].viewtype !== "dialog" && 
                    props.views[props.viewId].viewtype !== "external" &&
                    <>
                        {!props.sharedView &&<ShareViewSettingsMenu         
                        shareLinkId={props.views[props.viewId].shareLinkId}                                                                
                        createViewShareLink={props.createViewShareLink}
                        deleteViewShareLink={props.deleteViewShareLink}
                        viewId={props.viewId}
                        tableId={props.tableId}  
                        accessLevel={props.accessLevel}   
                        collapseHeaderButton={collapseHeaderButton}                        
                        />}                      
                      </>}



                   </div>
                 }
                </>}
                  </DialogActions>                
        </div>          
          </ReactResizeDetector>
          <Allotment>
            <Allotment.Pane 
                      visible={(openViewsDrawer && !props.sharedView)}
                      minSize={250}
                      maxSize={400}
          > 
          <div style={{minHeight: `calc(100vh - ${headerHeight + extPanelsHeight}px)`, backgroundColor:"white", borderTop: "1px solid #f1f1f1"}}>                          
          {(openViewsDrawer && !props.sharedView) && <ViewsConfigList 
          defaultcolumns={defaultcolumns} 
          title={title} 
          viewId={props.viewId} 
          views={props.views}  
          updateView={props.updateView} 
          handleChangeView={handleChangeView} 
          deleteView={props.deleteView} 
          height={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
          height2={`calc(100vh - ${headerHeight + extPanelsHeight+75}px)`}
          minHeight={`calc(100vh - ${headerHeight + extPanelsHeight+220}px)`}
          accessLevel={props.accessLevel}
          handleAddView={handleAddView}
          columns={props.columns}                       
          nonDialogViews={nonDialogViews}
          memberColumns={memberColumns}
          linkColumns={linkColumns}
          linkMemberColumns={linkMemberColumns}
          linkViews={linkViews}
          editedColumns={editedColumns}    
          setViewDialogs={__setViewDialogs}    
          viewDialogs={viewDialogs}
          viewAllPersonal={viewAllPersonal}
          setViewAllPersonal={__setViewAllPersonal}          
          userId={userId}
          currentMember={props.currentMember}
          tableId={props.tableId}
          dbId={props.dbId}
          />}                                                   
          </div>  
          </Allotment.Pane>
          <Allotment.Pane 
                      
                      minSize={700}
                      //maxSize={500}
          > 
          <ReactResizeDetector handleWidth handleHeight onResize={onResizeViewContent} targetRef={props.viewContentRef}>
          <div ref={props.viewContentRef} style={{
            minHeight: `calc(100vh - ${headerHeight + extPanelsHeight}px)`,
            backgroundColor:"white"
            }}>

          {props.views && props.viewId && props.views[props.viewId] && props.columns?
          <>
            {props.views[props.viewId].viewtype ==='list' && 
                  <WorkItemsTable2 
                  //workitemlist={workitemlist} 
                  gridRef={gridRef}
                  items={sortedAndFilteredViewItems}
                  columns={viewColumns}
                  viewVisibleColumns = {viewVisibleColumns}
                  summaryRow={props.views[props.viewId].summaryRow?props.views[props.viewId].summaryRow:{}}    
                  summaryGroupRow={props.views[props.viewId].summaryGroupRow?props.views[props.viewId].summaryGroupRow:{}}    
                  saveSummaryRow={props.saveSummaryRow}
                  saveSummaryGroupRow={props.saveSummaryGroupRow}              
                  view={props.views[props.viewId]}
                  viewId={props.viewId}
                  title_column={title_column}
                  groups={props.views[props.viewId].groups}
                  expandedGroupIds={props.views[props.viewId].expandedGroupIds}
                  onExpandedGroupIdsChange={onExpandedGroupIdsChange}
                  dbId={props.dbId}
                  tableId={props.tableId}
                  dialog={dialog}
                  members={props.members}
                  chatmembers={props.chatmembers}
                  rowHeight={props.views[props.viewId].rowHeight}
                  tableHeight={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
                  mongoFilter = {mongoFilter}
                  setNewItemIndex={setNewItemIndex}
                  newItemIndex = {newItemIndex}
                  updateView={props.updateView}
                  //setWorkitemlist={handleUpdateTable} 
                  handleOpenNewColumnSetting={handleOpenNewColumnSetting}
                  handleOpenColumnSetting={handleOpenColumnSetting}
                  handleColumnsReorder={props.handleColumnsReorder}
                  onColumnResize={props.onColumnResize}    
                  onColumnFrozen={props.onColumnFrozen}              
                  onChangeColumn={props.onChangeColumn}
                  onColumnDelete={props.onColumnDelete}
                  onColumnInsert={props.onColumnInsert}
                  handleRowUpdate={props.handleRowUpdate} 
                  onItemUpdate={props.onItemUpdate}
                  insertItem={props.insertItem}
                  onItemDelete={props.onItemDelete}
                  onRowsChange={onRowsChange}
                  onRowValueChange={props.onRowValueChange}
                  onRowReorder={props.onRowReorder}
                  //onRowInsertBelow={onRowInsertBelow}
                  //onRowInsertAbove={onRowInsertAbove}
                  EmptyRowsRenderer={EmptyRowsRenderer}
                  onChangeFullDialog={onChangeFullDialog}
                  handleEditItemById={handleEditItemById}
                  setEditedItemId = {props.setEditedItemId}
                  setOpenItemEditor={props.setOpenItemEditor}
                  tables={props.tables}
                  defaultValues = {props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}}
                  tablesList={props.tablesList}      
                  sortColumns={(props.views[props.viewId].sorts && props.columns)?props.views[props.viewId].sorts:[]}               
                  sharedView={props.sharedView}
                  currentMember={props.currentMember}
                  />    }
                  {props.views[props.viewId].viewtype==='kanban' && kanbanItems.lanes && 
                  <div style={{height: `calc(100vh - ${headerHeight + extPanelsHeight}px)`}}>
                  <Board 
                  style={{
                    height: `calc(100vh - ${headerHeight + extPanelsHeight}px)`, 
                    backgroundColor: '#fff'
                  }} 
                  laneStyle={{
                    maxHeight: `calc(100vh - ${headerHeight + extPanelsHeight+12}px)`,
                    backgroundColor: "#ebecf0"
                  }}                  
                  //components={{LaneHeader: KanbanLaneHeader,  Card: KanbanCustomCard, LaneFooter: KanbanCustomLaneFooter}}
                  //editable 
                  lang="ru"
                  //canAddLanes 
                  cardDraggable = {!props.sharedView}
                  draggable = {!props.sharedView}
                  laneDraggable = {!props.sharedView}
                  collapsibleLanes
                  hideCardDeleteIcon
                  //editLaneTitle 
                  data={kanbanItems} 
                  onLaneUpdate={onLaneUpdate}
                  //handleLaneDragStart={handleLaneDragStart}
                  handleLaneDragEnd={handleLaneDragEnd}
                  onCardAdd={onKanbanCardAdd}
                  onCardClick={onKanbanCardClick}
                  onCardMoveAcrossLanes={onKanbanCardMoveAcrossLanes}
                  onDataChange={onChangeKanbanCard}
                  sharedView = {props.sharedView}
                  onKanbanLaneClick={onKanbanLaneClick}
                  openKanbanCardAddDialog={openKanbanCardAddDialog}
                  //onLaneClick={onKanbanLaneClick}
                  />
                  </div>   
                  }
                  {props.views[props.viewId].viewtype==='calendar' &&
      <FullCalendar
      plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      }}      
      themeSystem="standard" 
      initialView="dayGridMonth"
      events={calendarItems}
      editable={props.sharedView?false:true}
      selectable={true}
      selectMirror={true}      
      //eventsSet={handleEventsSet}
      height={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
      locale={ruLocale}
      eventClick={handleEventClick}
      eventAdd={handleEventAdd}
      eventChange={handleEventChange}
      eventRemove={handleEventRemove}    
      select={handleDateSelect}  
    />                               
        }
              {props.views[props.viewId].viewtype === 'timeline' 
              && props.views[props.viewId].timelineColumnsOptions 
              && timelineData &&
                          
              <DndProvider backend={HTML5Backend}>
                    <Scheduler schedulerData={timelineData}
                    resourceName = {timelineResourceName}                    
                    prevClick={prevTimelineClick}
                    nextClick={nextTimelineClick}
                    onSelectDate={onSelectDateTimeline}
                    onViewChange={onViewTimelineChange}
                    width={props.viewContentWidth}
                    height={props.viewContentHeight}
                    updateEventStart={updateEventStart}
                    updateEventEnd={updateEventEnd}
                    moveEvent={moveEvent}                    
                    eventItemClick={handleTimelineEventClick}
                    newEvent = {handleTimelineAdd}
                   // leftCustomHeader={ <div><span style={{ fontWeight: 'bold' }}>Add a resource</span></div>}
                    /> 
              </DndProvider>
              

              }
               {false && <Timeline
                  groups={timelineResource}
                  items={sortedAndFilteredViewItems}
                  keys={{
                    groupIdKey: "id",
                    groupTitleKey: "title",
                    groupRightTitleKey: "rightTitle",
                    itemIdKey: "_id",
                    itemTitleKey: props.views[props.viewId].timelineColumnsOptions.titleColumn,
                    itemDivTitleKey: props.views[props.viewId].timelineColumnsOptions.titleColumn,
                    itemGroupKey: props.views[props.viewId].timelineColumnsOptions.resourceColumn,
                    itemTimeStartKey: props.views[props.viewId].timelineColumnsOptions.startColumn,
                    itemTimeEndKey: props.views[props.viewId].timelineColumnsOptions.endColumn,
                    groupLabelKey: "title"
                  }}
                  //itemsSorted
                  itemTouchSendsClick={false}
                  //stackItems
                  itemHeightRatio={0.75}
                  showCursorLine
                  canMove={props.sharedView?false:true}
                  canResize={props.sharedView?false:true}                  
                  defaultTimeStart={moment().add(-12, 'day')}
                  defaultTimeEnd={moment().add(12, 'day')}
                >
                <TimelineHeaders className="sticky">
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return <div {...getRootProps()}>Left</div>;
                  }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
              </TimelineHeaders>
              </Timeline>                
              }

                  {false && props.views[props.viewId].viewtype ==='dialog' && dialog && 
                  <DialogEditor             
                  isTemplate={props.isTemplate} 
                  dialog={dialog} 
                  config={config}
                  memberColumns={memberColumns}
                  linkColumns={linkColumns}
                  linkMemberColumns={linkMemberColumns}
                  attachmentsColumns={attachmentsColumns}
                  geolocationColumns={geolocationColumns}
                  linkViews={linkViews}
                  editedColumns={editedColumns}
                  dialogs={props.dialogs}
            viewId={props.viewId}
            tableId={props.tableId}
            //robotTab={robotTab}
            saveDialog={saveDialog}
            columns={props.columns}
            title={title}
            members={props.members}
            chatmembers={props.chatmembers}
            chatgroups={props.chatgroups}
            views={props.views}
            wiId={props.tableId}
            tables={props.tables}
            botchannel={props.botchannel}
            chatViewLinks={props.chatViewLinks}
            createChatViewLink={props.createChatViewLink}
            deleteChatViewLink={props.deleteChatViewLink}
            onChangeDialog={onChangeDialog}
            onChangeDialogQuestion={onChangeDialogQuestion}
            onChangeQuestions={onChangeQuestions}
            runDialog={onRunDialog}
            stopDialog={onStopDialog}
            handleCloseBotMenu={handleCloseBotMenu}
            handleOpenColumnSetting={handleOpenColumnSetting}
            onDeleteQuestionMenuItem={onDeleteQuestionMenuItem}
            onChangeDialogQuestionMenuItem={onChangeDialogQuestionMenuItem}
            onAddQuestionMenuItem={onAddQuestionMenuItem}
            minHeight={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
            //dialogviews={props.dialogviews}
            onColumnInsert={props.onColumnInsert} 
            onChangeColumn={props.onChangeColumn} 
            tablesList={props.tablesList}       
            />
            }  
            {props.views[props.viewId].viewtype ==='page' && 
            <ReactEditorJS 
            onInitialize={(instance) => (pageEditorRef.current = instance)}
            onChange={async (e,t)=> {
              const savedData = await pageEditorRef.current.save()
              setPageContent(savedData)
              props.updateView(props.tableId, props.views[props.viewId]._id, {pageContent: savedData})
            }}
            defaulltValue={pageContent} 
            tools={{
                // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
                //paragraph: EditorJS_Paragraph,
                embed: EditorJS_Embed,
                table: EditorJS_Table,
                list: EditorJS_List,
                warning: EditorJS_Warning,
                code: EditorJS_Code,
                linkTool: EditorJS_LinkTool,
                image: EditorJS_Image,
                raw: EditorJS_Raw,
                header: EditorJS_Header,
                quote: EditorJS_Quote,
                marker: EditorJS_Marker,
                checklist: EditorJS_CheckList,
                delimiter: EditorJS_Delimiter,
                inlineCode: EditorJS_InlineCode,
                simpleImage: EditorJS_SimpleImage
              }} />              
            }
            {props.views[props.viewId].viewtype ==='external' && externalContentOptions && externalContentOptions.link &&

              <iframe width="100%" src={externalContentOptions.link} frameborder="0" style={{border:0, height:`calc(100vh - ${headerHeight + extPanelsHeight}px)`}} allowfullscreen></iframe>              
            }
            {props.views[props.viewId].viewtype ==='dashboard' && dashboardSettings &&
              <div style={{minHeight: `calc(100vh - ${headerHeight + extPanelsHeight}px)`, marginLeft:"5px"}}>
                <GridLayout 
                className="layout" 
                isDraggable={dashboardEditedMode}
                isResizable={dashboardEditedMode}
                isBounded={dashboardEditedMode}
                //layout={dashboardSettings.layout} 
                cols={100} 
                rowHeight={30} 
                width={headerWidth}
                onLayoutChange={onDashBoardLayoutChange}
                > 
                  { dashboardSettings.elements && dashboardSettings.elements.map((el, idx)=>
                    <div key={el.i} data-grid={el.data_grid?el.data_grid:{x: 4, y: 10, w: 50, h: 10}}>
                      <ContainerDimensions> 
                      { ({ width, height }) => 
                      <div style={{width, height }}>
                      {el.type==="plotly" &&
      <Plot
        data={el.data}
        layout={{...el.layout, width:width, height: height}}
        frames={el.frames}
        config={{
          modeBarButtonsToAdd: dashboardEditedMode?[
            {
              name: 'edit',
              icon: plotly.Icons.pencil,
              direction: 'up',
              click: function(gd) {
                setEditedChart(el)
            }},            
            {
              name: 'delete',
              icon: {
  'width': 24,
  'height': 24,
  'path': 'M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z'
},
              direction: 'up',
              click: function(gd) {
                setDashboardSettings( cur=>{
                  var newSettings = {...cur}
                  var delIdx = idx
                  newSettings.elements.splice(
                    delIdx,
                    1
                  );                  
                  return newSettings
                })
                setEditedDashboardSettings(true)
            }}
          ]:[],
          //modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d']
        }}
      
        //onInitialized={(figure) => this.setState(figure)}
        //onUpdate={(figure) => this.setState(figure)}
      />        
        }
      
        {el.type==="html" && dashboardEditedMode &&
           <div> <ReactQuill
           theme="snow"
            //hide = { hiddenDesc }
            placeholder="Напишите здесь..."
            defaultValue={el.html?el.html:""}
            //onChange={onChangeEmailText}
            modules={{
              toolbar: [
                //[{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline','strike', 'blockquote'],
                //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                //['clean']
              ],
            }}
            formats={[
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              //'list', 'bullet', 'indent',
              'link'
            ]}
            onBlur={ (previousRange, source, editor) =>  {setDashboardSettings( cur=>{
              var newSettings = {...cur}
              var editIdx = idx
              newSettings.elements[editIdx].html = editor.getHTML();                  
              return newSettings
            })}
            }
            //onBlur={handleChangeDesc}
            setOptions={{
              mode: "inline",
              "showPathLabel": false,
              resizingBar: false,
              height: "auto",
              defaultStyle: "line-height: 1",
              //defaultTag:"",
              tagsBlacklist:"div|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|iframe|audio|video|table|thead|tbody|tr|th|td|var|u|ins|s|span|sub|sup|code|svg|path|details|summary",
              buttonList: [
                [
                  "fontSize",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "link"]
              ]// Or Array of button list, eg. [['font', 'align'], ['image']]
              // Other option
            }} />   </div>       
        }       
        {el.type==="html" && !dashboardEditedMode &&
              <div dangerouslySetInnerHTML={{ __html: el.html?el.html:"" }} />     
        }
        </div>
        }
      </ContainerDimensions>
                    </div>
                  )
                  }
                  

                      </GridLayout>                
                      <EditChartInDashboardDialog
                          dataSources = {geDataSourceForPlotlyEditor(props.columns, props.items, props.members, props.tables)}
                          editedChart={editedChart}
                          setEditedChart={setEditedChart}
                          saveChartParams={(changedChart)=>{
                            const __changedChart = changedChart
                            setDashboardSettings( cur=>{
                              var newSettings = {...cur}
                              var changedChart__idx = newSettings.elements.findIndex(el=> el.i===__changedChart.i)
                              if(changedChart__idx>=0){
                                newSettings.elements[changedChart__idx] = {...__changedChart}
                              }                              
                              return newSettings
                            })
                          }}
                        />
              </div>
            }            

                {false && props.views[props.viewId].viewtype === 'map' && mapOptions && mapOptions.location &&
                  <Wrapper apiKey={"AIzaSyDUMZ0nFMzXuiBcB_CpHD3epFzWz-zrNSk"}>
                    <MyMapComponent
                      center={{ lng: 37.573856, lat: 55.751574 }}
                      zoom={9}
                      height={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
                      mapItems={mapItems}
                    />
                  </Wrapper>
                }
                {props.views[props.viewId].viewtype === 'map' && mapOptions && mapOptions.location && <YMaps>
                  <div>
                    <Map defaultState={{ center: [55.75, 37.57], zoom: 9 }} 
                      height={`calc(100vh - ${headerHeight + extPanelsHeight+15}px)`}
                      width={headerWidth}
                    >                      
                      {mapItems && mapItems.map(_it=>
                        <Placemark geometry={_it.position}
                        properties={{
                        //  balloonContent:'<img src="http://img-fotki.yandex.ru/get/6114/82599242.2d6/0_88b97_ec425cf5_M" />',
                        //  iconContent:"Azerbaijan" 
                          iconCaption:mapOptions.titleColumn?(_it.title):""                       
                          }}
                          options={{

                            // Задаем стиль метки (метка в виде круга).
                            preset: "islands#dotIcon",
                            // Задаем цвет метки (в формате RGB).
                              iconColor: _it.color?(_it.color):'#ff0000'
                                
                              }}   
                          onClick={e=>{
                            props.setEditedItemId(it.item._id)
                            //setEditedIdx(item.index)
                            props.setOpenItemEditor(true)                                 
                          }}                       
                        />
                      )}

                      </Map>
                  </div>
                </YMaps>
                }         
                </>   
: 
            <div><Backdrop open={true}>
      <CircularProgress color="inherit" />
      </Backdrop>  </div>}
      </div>
          </ReactResizeDetector>
      </Allotment.Pane>
      </Allotment>
            <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={openSets}  //anchorElColEdit
              setOpen={setOpenSets} //setAnchorElColEdit
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              />
                            
          </div>
         
    );
  }
