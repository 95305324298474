import React, {useState, useContext, useCallback, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'
import {useHttp} from '../hooks/http.hook';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import {NavContext} from '../context/NavContext'
import ReportEditor from './ReportEditor';
import TelegramIcon from '@material-ui/icons/Telegram';
import SvgIcon from '@material-ui/core/SvgIcon';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}  


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 0, 3),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },  
}));

function DiscordIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z" />
    </SvgIcon>
  );
}

function SlackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" />
    </SvgIcon>
  );
}
function TadaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12.75,3.94C13.75,3.22 14.91,2.86 16.22,2.86C16.94,2.86 17.73,3.05 18.59,3.45C19.45,3.84 20.13,4.3 20.63,4.83C21.66,6.11 22.09,7.6 21.94,9.3C21.78,11 21.22,12.33 20.25,13.27L12.66,20.86C12.47,21.05 12.23,21.14 11.95,21.14C11.67,21.14 11.44,21.05 11.25,20.86C11.06,20.67 10.97,20.44 10.97,20.16C10.97,19.88 11.06,19.64 11.25,19.45L15.84,14.86C16.09,14.64 16.09,14.41 15.84,14.16C15.59,13.91 15.36,13.91 15.14,14.16L10.55,18.75C10.36,18.94 10.13,19.03 9.84,19.03C9.56,19.03 9.33,18.94 9.14,18.75C8.95,18.56 8.86,18.33 8.86,18.05C8.86,17.77 8.95,17.53 9.14,17.34L13.73,12.75C14,12.5 14,12.25 13.73,12C13.5,11.75 13.28,11.75 13.03,12L8.44,16.64C8.25,16.83 8,16.92 7.73,16.92C7.45,16.92 7.21,16.83 7,16.64C6.8,16.45 6.7,16.22 6.7,15.94C6.7,15.66 6.81,15.41 7.03,15.19L11.63,10.59C11.88,10.34 11.88,10.11 11.63,9.89C11.38,9.67 11.14,9.67 10.92,9.89L6.28,14.5C6.06,14.7 5.83,14.81 5.58,14.81C5.3,14.81 5.06,14.71 4.88,14.5C4.69,14.3 4.59,14.06 4.59,13.78C4.59,13.5 4.69,13.27 4.88,13.08C7.94,10 9.83,8.14 10.55,7.45L14.11,10.97C14.5,11.34 14.95,11.53 15.5,11.53C16.2,11.53 16.75,11.25 17.16,10.69C17.44,10.28 17.54,9.83 17.46,9.33C17.38,8.83 17.17,8.41 16.83,8.06L12.75,3.94M14.81,10.27L10.55,6L3.47,13.08C2.63,12.23 2.15,10.93 2.04,9.16C1.93,7.4 2.41,5.87 3.47,4.59C4.66,3.41 6.08,2.81 7.73,2.81C9.39,2.81 10.8,3.41 11.95,4.59L16.22,8.86C16.41,9.05 16.5,9.28 16.5,9.56C16.5,9.84 16.41,10.08 16.22,10.27C16.03,10.45 15.8,10.55 15.5,10.55C15.23,10.55 15,10.45 14.81,10.27V10.27Z" />
      
    </SvgIcon>
  );
}



function MsTeamsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19.19 8.77q-.46 0-.86-.17q-.39-.17-.69-.47q-.3-.3-.47-.69q-.17-.4-.17-.86q0-.45.17-.85q.17-.4.47-.69q.3-.3.69-.47q.4-.18.86-.17q.45-.01.85.17q.4.17.7.47q.29.29.47.69q.17.4.17.85q0 .46-.17.86q-.17.39-.47.69q-.3.3-.7.47q-.4.17-.85.17m0-3.12q-.39 0-.69.27q-.25.27-.25.66t.25.67q.3.25.69.25q.39 0 .66-.25q.28-.25.28-.67q0-.39-.28-.66q-.27-.27-.66-.27M22 10.33V15q0 .63-.24 1.2q-.26.57-.67.99q-.43.43-1 .67q-.59.25-1.21.25q-.38 0-.76-.11q-.39-.07-.71-.25q-.24.79-.71 1.44q-.47.65-1.1 1.11q-.63.46-1.39.7q-.76.27-1.58.27q-.96 0-1.81-.33q-.82-.33-1.5-.94q-.66-.57-1.09-1.36q-.44-.8-.57-1.74H2.83q-.33 0-.59-.25q-.24-.24-.24-.58V7.73q0-.34.24-.59q.26-.24.59-.24H10q-.29-.6-.29-1.25q0-.61.23-1.15q.22-.5.62-.92q.4-.39.94-.62q.5-.23 1.12-.23q.61 0 1.14.23q.53.23.93.62q.4.42.62.92q.23.54.23 1.15q0 .6-.23 1.14q-.22.53-.62.92q-.4.4-.93.63q-.53.23-1.14.23q-.15 0-.31-.02q-.15-.02-.31-.05v.9h9.06q.39 0 .67.27q.27.27.27.66M12.63 4q-.35 0-.63.11q-.33.13-.56.36q-.22.23-.35.53q-.13.31-.13.65q0 .35.13.65q.13.3.35.53q.23.22.56.36q.28.13.63.13q.34 0 .64-.13q.3-.14.53-.36q.23-.23.36-.53q.14-.3.14-.65q0-.34-.14-.65q-.13-.3-.36-.53q-.23-.23-.53-.36q-.3-.11-.64-.11m-4.85 6.18h1.88V8.62H4.34v1.56h1.88v5h1.56m8.6 1.09v-5.62H12v5.42q0 .34-.24.58q-.26.25-.59.25H8.92q.13.67.47 1.25q.34.57.82.99q.48.41 1.1.65q.61.21 1.32.21q.77 0 1.45-.27q.68-.3 1.2-.81q.51-.51.8-1.19q.3-.68.3-1.46M20.75 15v-4.35h-3.12v5.71q.25.25.57.38q.3.12.68.12q.39 0 .73-.15q.34-.15.59-.4q.26-.25.4-.6q.15-.34.15-.71z" />
    </SvgIcon>
  );
}


function ChatBotAddDialog(props) {

  
  const [token, setToken] = useState("");
  const [channelId, setChannelId] = useState("telegram");
  const [microsoftAppId, setMicrosoftAppId] = useState(null);
  const [microsoftAppPassword, setMicrosoftAppPassword] = useState(null);
  const [name, setName] = useState('');
  const [server_url, setServerUrl] = useState('');
  const [mention_name, setMention_name] = useState('');

  const classes = useStyles();
  const history = useHistory();    

  const onChangeTelegramBotToken = (event) => {
    const v = event.target.value;
    setToken(v)
  }   
  
  const onChangeTadaBotToken = (event) => {
    const v = event.target.value;
    setToken(v)
  }   

  const onChangeMicrosoftAppId = (event) => {
    const v = event.target.value;
    setMicrosoftAppId(v)
  }      

  const onChangeMicrosoftAppPassword = (event) => {
    const v = event.target.value;
    setMicrosoftAppPassword(v)
  }      

  const onChangeName = (event) => {
    const v = event.target.value;
    setName(v)
  }  
  
  const onChangeMention_name = (event) => {
    const v = event.target.value;
    setMention_name(v)
  }    

  const onChangeServerUrl = (event) => {
    const v = event.target.value;
    setServerUrl(v)
  }    

  



  const handleClose = () => {      
    props.setOpen(false);
  };

  const handleSave = () => {
    //original_db, bot_params
    props.onSelectHandle(props.selectedTemplate?props.selectedTemplate.original_db:null, {
      channelId,
      token,
      microsoftAppId,
      microsoftAppPassword,
      name,
      mention_name,
      server_url
    })               
      handleClose();        
  };        



  return (
    <React.Fragment>  

      <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="add_bot-dialog-title">Создание копии шаблона {props.selectedTemplate?props.selectedTemplate.name:""}
          <div><Typography component="span" variant="subtitle2">Выберите мессенджер для вашего чат-бота</Typography></div>
          <Toolbar position="static" color="default">
            <Tabs
              value={channelId}
              onChange={(event, newValue) => {
                setChannelId(newValue);
              }}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab label='Telegram' value="telegram" icon={<TelegramIcon fontSize="default" />} {...a11yProps(0)} />
              <Tab label="MS Teams" value="msteams" icon={<MsTeamsIcon fontSize="default" />} {...a11yProps(3)} />
              <Tab label="Tada" value="tada" icon={<TadaIcon fontSize="default" />} {...a11yProps(1)} />
              <Tab label="Slack" value="slack" icon={<SlackIcon fontSize="default" />} {...a11yProps(1)} />
              <Tab label="Discord" value="discord" icon={<DiscordIcon fontSize="default" />} {...a11yProps(2)} />

            </Tabs>
          </Toolbar>
        </DialogTitle>
        <DialogContent dividers>

          {channelId === "telegram" 
          && <Paper className={classes.job_card} elevation={3}>
            <div >
              <p>
              Для того, чтобы подключить Telegram к шаблону, вам необходимо создать и подключить бота. Для этого необходимо:
              <ul>
              <li>В поиске в мессенджере Telegram найти @BotFather.</li>
              <li>Написать ему /start и бот выдадет вам список доступных команд.</li>
              <li>Для создания нового бота введите команду /newbot, далее следуйте инструкицям Telegram.</li>
              <li>BotFather пришлет вам в ответ API token для доступа. Скопируйте его и вставьте в поле ниже</li>            
              </ul>
              </p>
              <TextField
                id={"new-telegram-bot-token"}
                value={token}
                onChange={onChangeTelegramBotToken}
                placeholder="Telegram bot access token"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                label="API Token"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Paper>}

          {channelId === "msteams" 
          && <Paper className={classes.job_card} elevation={3}>
          <div >

<TextField
  id={"new-msteams-bot-name"}
  value={name}
  onChange={onChangeName}
  placeholder="Имя"
  helperText=""
  fullWidth
  //multiline
  //rows={3}
  label="Имя"
  variant="outlined"
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
</div>
<div >

<TextField
  id={"new-msteams-bot-mention_name"}
  value={mention_name}
  onChange={onChangeMention_name}
  placeholder="Mention name"
  helperText=""
  fullWidth
  //multiline
  //rows={3}
  label="Mention name"
  variant="outlined"
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
</div>            
            <div >

              <TextField
                id={"new-msteams-bot-app-id"}
                value={microsoftAppId}
                onChange={onChangeMicrosoftAppId}
                placeholder="Microsoft AppId"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                label="Microsoft AppId"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div >

              <TextField
                id={"new-msteams-bot-app-password"}
                value={microsoftAppPassword}
                onChange={onChangeMicrosoftAppPassword}
                placeholder="Microsoft App Password"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                label="Microsoft App Password"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>


          </Paper>}

          {channelId === "tada"
            && <Paper className={classes.job_card} elevation={3}>
              <div >

                <TextField
                  id={"new-tada-bot-server_url"}
                  value={server_url}
                  onChange={onChangeServerUrl}
                  placeholder="Адрес сервера"
                  helperText=""
                  fullWidth
                  //multiline
                  //rows={3}
                  label="Адрес сервера"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div >

              <TextField
                id={"new-tada-bot-token"}
                value={token}
                onChange={onChangeTadaBotToken}
                placeholder="Tada bot access token"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                label="Access Token"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </div>

            </Paper>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
              Отмена
            </Button>              
            <Button color="primary" onClick={handleSave}>
              Создать
            </Button>
        </DialogActions>
      </Dialog>
    
      </React.Fragment>
  );
}

function ViewTemplateDialog(props) {
    const {token} = useContext(AuthContext);
    const {request} = useHttp();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const {onChangeReport} = useContext(NavContext);


    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
  
    const onSelectHandle = useCallback(async()=>{
      handleClose();
      try {
          var data = await request(`/api/workitemlist/`,'POST',{templateId:props.template._id, templateGlobal:props.template.global}, {Authorization: `Bearer ${token}`});
          console.log('onSelectHandle data', data); 
          history.push(`/reports/${data._id}`); 
          onChangeReport();
          console.log('onSelectHandle data', data);            
      } catch (e) {
          console.log('onSelectHandle error', e); 
      }
    },[token, history, props.template._id, props.template.global, request, onChangeReport]) 

    return (
      <React.Fragment>   
                    <Button size="small" color="primary" key="menu-new-job" onClick={handleClickOpen}>
                      Подробнее
                    </Button>      
        <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Просмотр шаблона {props.template.name}</DialogTitle>
          <DialogContent>
            <ReportEditor isTemplate={true} template={props.template}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Закрыть
            </Button>        
            <Button onClick={onSelectHandle} color="primary">
              Создать
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  }

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }  

export default function Templates() {
  const classes = useStyles();
  const {token} = useContext(AuthContext);
  const {loading, request} = useHttp();
  const [templates, setTemplates] = useState([]);
  const history = useHistory();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [msgAlert, setMsgAlert] = React.useState('');   
  const {onChangeReport, setCurrentPageTitle} = useContext(NavContext);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  let query = useQuery();
  const urlTemplateId = query.get('templateId')
  const useFreeTgBot = query.has('useFreeTgBot') //т.е. используем автоматом бесплатного чат бота!!!
  //const urlCmdCreateDB = query.has('createDB')

  const handleCloseAlert = () => {
    setOpenAlert(false)
    setMsgAlert("")
  };
    
  const handleClickOpen = (_template) => {
    //setGroup(props.groups[0]._id);
    setSelectedTemplate(_template)
    setOpenAddDialog(true);
  };

  const getTemplates = useCallback(async()=>{
    try {
        var data = await request(`/api/template/`,'GET',null, {Authorization: `Bearer ${token}`});
        setTemplates(data);
        console.log('getTemplates data', data);            
    } catch (e) {
        console.log('getTemplates error', e); 
    }
},[token, request])   

const onSelectHandle = useCallback(async(original_db, bot_params)=>{
  try {
    if(original_db){
      var data = await request(`/api/db/${original_db}/copyDB`,'POST',{bot_params:bot_params, copyItems: false}, {Authorization: `Bearer ${token}`});
      //console.log('onSelectHandle data', data); 
      window.ym(53785333,'reachGoal','createdb')
      history.push(`/db/${data._id}`); 
    } else {
      var data = await request(`/api/db/`,'POST',{bot_params:bot_params}, {Authorization: `Bearer ${token}`});
      
      //console.log('onSelectHandle data', data); 
      history.push(`/db/${data._id}`);       
    }

      onChangeReport();
      console.log('onSelectHandle data', data);            
  } catch (e) {
      console.log('onSelectHandle error', e); 
  }
},[token, history, request, onChangeReport])  

const onOpenHandle = useCallback(async(original_db)=>{
  try {
    if(original_db){
      var data = await request(`/api/db/${original_db}/copyDB`,'GET',{copyItems: false}, {Authorization: `Bearer ${token}`});
      //console.log('onSelectHandle data', data); 
      history.push(`/db/${data._id}`); 
    } else {
      var data = await request(`/api/db/`,'POST',{}, {Authorization: `Bearer ${token}`});
      
      //console.log('onSelectHandle data', data); 
      history.push(`/db/${original_db}`);       
    }

      onChangeReport();
      console.log('onSelectHandle data', data);            
  } catch (e) {
      console.log('onSelectHandle error', e); 
  }
},[token, history, request, onChangeReport])  


useEffect(()=>{
  console.log('useEffect getTemplates')
    getTemplates();
    setCurrentPageTitle(<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
    Шаблоны отчетов
    </Typography>);
}, [getTemplates])

useEffect(()=>{
  if( urlTemplateId && urlTemplateId.length>0 && templates.length>0 ){
    var inxtemplate = templates.find(re=> re._id===urlTemplateId)
    if(inxtemplate){
      setSelectedTemplate(inxtemplate)
      if(!useFreeTgBot){
        setOpenAddDialog(true);
      } else {
        //т.е. планируется использовать бесплатного типового чат-бота
        onSelectHandle(inxtemplate.original_db, {
          channelId: 'telegram',
          useFreeTgBot
        })          
        
      }
      
    } else {      
      setMsgAlert("Не найден шаблон с id "+urlTemplateId)
      setOpenAlert(true)
    }
    
  }
}, [urlTemplateId, useFreeTgBot, templates, onSelectHandle])     

  return (
    <React.Fragment>
      <div style={{overflow:"auto"}}>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Создайте рабочей области на основании шаблона
            </Typography>
          </Container>
        </div>
        <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <LinearProgress />
            </Fade>        
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {templates.map((template) => (
              <Grid item key={template._id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={template.image}
                    title={template.name}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h2">
                        {template.name}
                    </Typography>
                    <Typography>
                        {template.title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {template.original_db && 
                    <Button size="small" color="primary" key="menu-view-db" target="_blank" href={`/db/${template.original_db}`}>
                    Посмотреть
                  </Button> 
                    }
      <Tooltip title={"Использовать шаблон для создания чат-бота"}>
      <Button size="small" color="primary" key="menu-new-db"
        edge="end" aria-label="menu"  aria-controls="bot-menu" aria-haspopup="true"
        onClick={e=>handleClickOpen(template)}
        >
          Использовать
      </Button>
    </Tooltip>                    
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <ChatBotAddDialog open={openAddDialog} setOpen={setOpenAddDialog} onSelectHandle={onSelectHandle} selectedTemplate={selectedTemplate}/>                                          
        </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={'top_center_alert '}
      >
        <Alert onClose={handleCloseAlert} severity="warning">
          {msgAlert}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}