import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useMessage} from '../hooks/message.hook';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://projecton.ru">
        Проджектон ООО
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function SignUp() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
    const message = useMessage();
    const {loading, request, error, clearError}= useHttp();
    const [form, setForm] = React.useState({firstName:null, email:null, phone:null });

    const submitHandler = async (event) => { 
        try {            
            const data = await request('/api/auth/register','POST', form,{});
            console.log('registerHandler data ', data);//5f4ff16fd28b74415048d497 //5f56661db28e724dd0d24c6b
            var report = await request(`/api/workitemlist/`,'POST',{templateId:'5f56661db28e724dd0d24c6b', templateGlobal:true}, {Authorization: `Bearer ${data.token}`});
            console.log('onSelectHandle data', report); 
            auth.login(data.token, data.userId);
            history.push(`/reports/${report._id}`);       
            
            
        } catch (e) {
            console.log('registerHandler error ', e);
        }
    }

    const  onChangeHandler = (event) =>{        
        setForm({...form, [event.target.name]:event.target.value})
    } 
    

    const  onChangeOferta = (event) =>{   
      const checked = event.target.checked;     
      setForm({...form, ['allowOferta']:checked})
    }       

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>        
        <Typography component="h1" variant="h5">
          Используйте роботизированный ΞXLY отчет для контроля поручений. <br/>
          14 дней бесплатно. <br/>
          Введите данные, необходимые для начала работы:
        </Typography>
        <form className={classes.form} noValidate>     
          {error && <Alert variant="filled" severity="error" onClose={() => {}}>{error}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="firstName"
                variant="outlined"
                onChange={onChangeHandler}
                required
                fullWidth
                id="firstName"
                label="Имя"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                onChange={onChangeHandler}
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>       
            <Grid item xs={12}>
            <span>Нажимая на кнопку, вы принимаете  <a href="https://projecton.ru/oferta/"> соглашение об использовании</a></span>
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Старт
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Уже зарегистрированы? Войти
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}