import React, {useCallback} from 'react';
import { useDrag, useDrop, DragObjectWithType } from 'react-dnd';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem';
//import { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } from 'react-contextmenu';
import MoreIcon from '@material-ui/icons/MoreVert';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import NestedMenuItem from "material-ui-nested-menu-item";
import Divider from '@material-ui/core/Divider';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input';     
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import SubjectIcon from '@material-ui/icons/Subject';
import ColumnSettingsDialog from './ColumnSettingsDialog'
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Badge from '@material-ui/core/Badge';
import FunctionsIcon from '@material-ui/icons/Functions';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';

  const columnTypesMap = {
    'date':'Дата',
    'member':'Пользователь',
    'chatmember':'Чат-пользователь',
    "link": "Ссылка",
    'list':'Таблица',
    'text':'Текст',
    'title':'Заголовок',
    'number':'Число',
    'check':'Флаг',
    'file':'Файлы и фото',
  }

  function LookupIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
      </SvgIcon>
    );
  }  

   

  function ChatMemberIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
      </SvgIcon>
    );
  }  

  function PinIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M16 12V4h1V2H7v2h1v8l-2 2v2h5.2v6h1.6v-6H18v-2l-2-2z" />
      </SvgIcon>
    );
  }    

  function UnPinIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-5.93-5.93V22h-1.6v-6H6v-2l2-2v-.73l-6-6M16 12l2 2v2h-.18L8 6.18V4H7V2h10v2h-1v8z" />
      </SvgIcon>
    );
  }    



const useStyles = makeStyles((theme) => ({
       
  menuItemText: {
      margin: theme.spacing(1),
    }, 
    formulaBadge: {
      fontSize:"10px",
      backgroundColor: "white",//"rgba(0, 0, 0, 0.26)",
      color: "rgba(0, 0, 0, 0.26)",
      padding: "0 0",
      minWidth: "8px"
    }
}));



function useCombinedRefs(...refs) { 
  return useCallback(handle => { for (const ref of refs) { if (typeof ref === 'function') { ref(handle); } else if (ref !== null) { ref.current = handle; } } }, refs); 
}

export default function DraggableHeaderRenderer(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [menuPosition, setMenuPosition] = React.useState(null); 
  const [showBt, setShowBt] = React.useState(false);
  const divRef = React.useRef();
  //const [lookup, setLookup] = React.useState(props.column.lookup);
  const [{ isDragging }, drag] = useDrag({
    item: { key: props.column._id, type: 'COLUMN_DRAG' },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key, type }) {
      if (type === 'COLUMN_DRAG') {
        props.onColumnsReorder(props.tableId, props.viewId, key, props.column._id);
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    })
  });

  const handleOpenColumnContextMenu = (event) => {
    event.preventDefault();
    setAnchorEl(divRef.current);
    event.stopPropagation();
  };

  
  const handleOpenColumnMenu = (event) => {
    setAnchorEl(null);
    //console.log('handleOpenColumnMenu ',props.column)
    //props.handleOpenColumnSetting(props.column, divRef.current)
    if(!props.sharedView){
      //props.setAnchorElColEdit(event.currentTarget)
      var rect = divRef.current.getBoundingClientRect();
      props.setAnchorElColEdit({ top: rect.top, left: rect.left })                    

      props.setEditingSets(props.column)
    }
  };

  const handleHideColumnMenu = (event) => {
    setAnchorEl(null);
    props.onColumnResize(props.tableId, props.viewId, props.column._id, 0)
  };

  const handleFrozenColumnMenu = (event) => {
    setAnchorEl(null);
    props.onColumnFrozen(props.tableId, props.viewId, props.column._id, !props.column.frozen)
  };  

  const handleGroupColumnMenu = (event) => {
    setAnchorEl(null);
    props.onColumnFrozen(props.tableId, props.viewId, props.column._id, !props.column.frozen)
  };    

  const handleUngroupColumnMenu = (event) => {
    setAnchorEl(null);
    props.onColumnFrozen(props.tableId, props.viewId, props.column._id, !props.column.frozen)
  };    

  const handleOpenNewColumnMenu = (idx) => {
    console.log("handleOpenNewColumnMenu ", idx)
    setAnchorEl(null);
    var rect = divRef.current.getBoundingClientRect();
    props.setAnchorElColEdit({ top: rect.top, left: rect.left })  
    props.setEditingSets({idx:idx, isNew:true})    
  };  

  const handleCloseColumnMenu = () => {
    setAnchorEl(null);
  };  


  

  return (   
    <div
    onKeyDown={event => {
      console.log('onKeyDown ', event)
      if (event.key === 'Enter') {
        handleOpenColumnMenu()
        //event.stopPropagation();
      }
    }}     
    >
      <Tooltip title={'Нажмите, чтобы настроить колонку'}>
    <div
      onContextMenu={handleOpenColumnContextMenu}
      ref={useCombinedRefs(drag, drop, divRef)}         
      onClick={handleOpenColumnMenu}   
      onMouseEnter={() => setShowBt(true)}
      onMouseLeave={() => setShowBt(false)}    
      aria-haspopup="true"
      aria-controls={"simple-menu-"+props.column.idx}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',   
        display: 'flex',  
        fontSize:13,
        fontWeight:400 //был толстый 700      
      }}
    >
      
      <ListItem
        button
        ContainerComponent="div" 
        disableGutters
        style={{
          padding:0,
          width: props.column.width-8
        }}
        id={'main-grid-column-name-'+props.column._id}   
      >
      {!props.sharedView && showBt && <ListItemIcon style={{minWidth:"25px"}}>
      {props.column.type==='text' && !props.column.is_formula && <TextFieldsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='text' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='title' && !props.column.is_formula && <TextFieldsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='title' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='number' && !props.column.is_formula && <Looks3Icon color="disabled" fontSize="small"/>}
      {props.column.type==='number' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='date' && !props.column.is_formula && <EventIcon color="disabled" fontSize="small"/>}
      {props.column.type==='date' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='select' && !props.column.is_formula && <FilterListIcon color="disabled" fontSize="small"/>}
      {props.column.type==='select' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='multiline' && !props.column.is_formula  && <SubjectIcon color="disabled" fontSize="small"/>}
      {props.column.type==='multiline' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='member' && <PersonIcon color="disabled" fontSize="small"/>}
      {props.column.type==='chatmember' && <ChatMemberIcon color="disabled" fontSize="small"/>}
      {props.column.type==='link' && <LinkIcon color="disabled" fontSize="small"/>}
      {props.column.type==='geolocation' && <LocationOnIcon color="disabled" fontSize="small"/>}
      {props.column.type==='attachments' && <AttachFileIcon color="disabled" fontSize="small"/>}
      {props.column.type==='check' && !props.column.is_formula  && <CheckBoxIcon color="disabled" fontSize="small"/>}
      {props.column.type==='check' && props.column.is_formula && <FunctionsIcon color="disabled" fontSize="small"/>}
      {props.column.type==='lookup' && <LookupIcon color="disabled" fontSize="small"/>}
        
      </ListItemIcon>}
      <ListItemText 
      id={"main-grid-column-label-"+props.column._id} 
      style={{marginTop:"2px", marginBottom:0}}
      primary={props.column.name} 
      disableTypography
      />
      
        
       {!props.sharedView && showBt && <ListItemSecondaryAction
       style={{right:"3px"}}
       >
            <IconButton size="small" onClick={handleOpenColumnContextMenu} style={{float: "right"}}><ArrowDropDownIcon       style={{
              cursor: 'pointer'
            }} color="disabled" fontSize="small"/>
            </IconButton>
            </ListItemSecondaryAction>}
    </ListItem>      

    </div>
    </Tooltip>

       <Popover
        id={"simple-menu-"+props.column.idx}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        //anchorPosition={props.open}
        onClose={handleCloseColumnMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      //        style={{minWidth:"250px"}}
      >
            <MenuList id="row-height-menu-list" dense>
            {!props.sharedView && <MenuItem onClick={handleOpenColumnMenu}><ListItemIcon style={{minWidth:"35px"}}><SettingsIcon fontSize="small"/></ListItemIcon> Параметры колонки</MenuItem>}
            <MenuItem ><ListItemIcon style={{minWidth:"35px"}}><ArrowDownwardIcon fontSize="small"/></ListItemIcon> Сортировать по возрастанию</MenuItem>
            <MenuItem ><ListItemIcon style={{minWidth:"35px"}}><ArrowUpwardIcon fontSize="small"/></ListItemIcon> Сортировать по убыванию</MenuItem>
            <MenuItem onClick={handleHideColumnMenu}><ListItemIcon style={{minWidth:"35px"}}><VisibilityOffIcon fontSize="small"/></ListItemIcon> Скрыть колонку</MenuItem>
            {!props.column.frozen && <MenuItem onClick={handleFrozenColumnMenu}><ListItemIcon style={{minWidth:"35px"}}><PinIcon fontSize="small"/></ListItemIcon> Зафиксировать колонку</MenuItem>}
            {props.column.frozen && <MenuItem onClick={handleFrozenColumnMenu}><ListItemIcon style={{minWidth:"35px"}}><UnPinIcon fontSize="small"/></ListItemIcon> Расфиксировать колонку</MenuItem>}
            
            {!props.sharedView && (!props.column.questions || props.column.questions.length==0) && <MenuItem  onClick={(e) => props.onColumnDelete(props.tableId, props.column._id)}><ListItemIcon style={{minWidth:"35px"}}><DeleteIcon fontSize="small"/></ListItemIcon> Удалить колонку</MenuItem>}
            <Divider />
            {!props.sharedView &&  <MenuItem onClick={(e)=> handleOpenNewColumnMenu(props.column.index)}><ListItemIcon style={{minWidth:"35px"}}><ArrowForwardIcon fontSize="small"/></ListItemIcon> Добавить колонку справа</MenuItem>}
            {!props.sharedView && <MenuItem onClick={(e)=> handleOpenNewColumnMenu(props.column.index-1)}><ListItemIcon style={{minWidth:"35px"}}><ArrowBackIcon fontSize="small"/></ListItemIcon> Добавить колонку слева</MenuItem>}
          </MenuList>   
          </Popover>
      </div>  
  );
}