import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useMessage} from '../hooks/message.hook';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://projecton.ru/">
        Проджектон ООО
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function ResetPassword() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
    const message = useMessage();
    const {loading, request, error, clearError}= useHttp();
    const [form, setForm] = React.useState({email:null, password:null});
    const [oki, setOki] = React.useState(false);
    const submitHandler = async (event) => { 
        try {            
            const data = await request('/api/auth/resetPswd','POST', form,{});
            //console.log('loginHandler data ', data);
            //auth.login(data.token, data.userId);
            setOki(true);
        } catch (e) {
            console.log('resetHandler error ', e);
        }
    }

    const signinHandler = async (event) => { 
      history.push('/signin');
  }    

    const  onChangeHandler = (event) =>{        
        setForm({...form, [event.target.name]:event.target.value})
    }    

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>        
        <Typography component="h1" variant="h5">
         Восстановление пароля
        </Typography>
        <form className={classes.form} noValidate>
        {error && <Alert variant="filled" severity="error" onClose={() => {clearError()}}>{error}</Alert>}
        {oki && <div><Typography component="h3" variant="h6">
        Ссылка для восстановления пароля отправлена на вашу электронную почту {form.email}
        </Typography>
                  <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={signinHandler}
                >
                  Войти
                </Button></div>}
        {!oki &&   <div><TextField
            variant="outlined"
            margin="normal"
            required
            onChange={onChangeHandler}
            fullWidth
            id="email"
            label="Для восстановления пароля ведите свой Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Восстановить
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link href="/signin" variant="body2">
                {"Войти"}
              </Link>
            </Grid>
          </Grid></div>}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}