import React, {useState, useContext, useCallback, useEffect, forwardRef } from 'react';
import {useHistory, useParams, Switch, Route, Redirect, BrowserRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {useHttp} from '../hooks/http.hook';
import {useAuth} from '../hooks/auth.hook'
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProfileMenu from '../components/ProfileMenu'
import Profile from '../components/Profile'
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Templates from '../components/Templates'
import MyTables from '../components/MyTables'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        //backgroundColor:"rgb(34, 53, 139)",
        //color: "#004386"  
      },     
      title: {
        flexGrow: 1,
      },   
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor:"rgba(240, 240, 243, 1)"
      },    
      appBarSpacer: theme.mixins.toolbar, 
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
      },
      sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },           
}));

export default function Dashboard() {
  const classes = useStyles();
  const {token} = useAuth();
  const {loading, error, request} = useHttp();
  const [searchBase, setSearchBase] = React.useState("");
  //const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState(null);
  const path = useParams().path;
  console.log('path ', path)
  //if(!path){
  //  history.push(`/dashboard/tables`)
 // }
  const history = useHistory();
  const handleChange = (event, newValue) => {
     // console.log('handleChange ', newValue)
    //setValue(newValue);
    history.push(`/dashboard/${newValue}`)
  };

  const changeSearchBase = (event) => {
    setSearchBase(event.target.value)
 };
  
  
  const getProfile = useCallback(async()=>{
    try {
        var data = await request('/api/auth/profile','GET',null, {Authorization: `Bearer ${token}`});
        setProfile(data);
        //console.log('profile data', data);            
    } catch (e) {
      console.log('profile error', e);  
    }
  },[token, request,setProfile])
  
  useEffect( () => {  
    if(token){
      getProfile();   

    } else {
      setProfile(null);  
    }
}, [token])  

  return (
    <div className={classes.root}>
    <CssBaseline />
      <AppBar position="absolute">
      <Toolbar className={classes.toolbar}>
      <Typography component="h1" variant="h4" color="inherit" noWrap className={classes.title}>
      <Link href='/'> <img alt="logo" style={{height: "50px"}} src="/projecton_logo.png"/>  </Link>     
    </Typography>     
    {path && path==='dbs'    && <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Найти область..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={searchBase}
              onChange={changeSearchBase}
            />
          </div>   } 
          {path && path!=='dbs'    && 
          <Tooltip title={"Перейти в список рабочих областей вашей команды"}>
        <Button 
        onClick={e=> handleChange(e, "dbs")}
        >
          Мои рабочие области
        </Button> 
      </Tooltip>  }
      <Tooltip title={"Справочная информация"}>
        <IconButton color="inherit">
          <HelpOutlineIcon />
        </IconButton> 
      </Tooltip>  
      <Tooltip title={"Оповещания"}>     
        <IconButton color="inherit">
          <NotificationsNoneIcon />
        </IconButton>
        </Tooltip>
        <ProfileMenu profile={profile}/>
    </Toolbar>
      </AppBar>
      <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Switch>

<Route exact path="/dashboard/profile" component={Profile} />        
<Route exact path="/dashboard/templates" component={Templates} />
<Route exact path="/dashboard/dbs" >
  <MyTables searchBase={searchBase}/>
</Route>
<Redirect from="/signin" to="/templates" />
<Redirect from="/signup" to="/templates" />
</Switch>      

      </main>
    </div>
  );
}