import React, { useState, useContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'
import { useHttp } from '../hooks/http.hook';
import { ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment-timezone';
import { Helmet } from "react-helmet";
import WorkItemsTable2 from './WorkItemsTable/WorkItemsTable'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChipInput from 'material-ui-chip-input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StopIcon from '@material-ui/icons/Stop';
import SaveIcon from '@material-ui/icons/Save';
import ToggleButton from '@material-ui/lab/ToggleButton';
//import {QueryBuilder, formatQuery} from 'react-querybuilder';
//import GooglePicker from 'react-google-picker'
import Backdrop from '@material-ui/core/Backdrop';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import StyleIcon from '@material-ui/icons/Style';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import ProfileMenu from './ProfileMenu'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DnsIcon from '@material-ui/icons/Dns';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
//import { Scrollbars } from 'react-custom-scrollbars';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';
import ChatIcon from '@material-ui/icons/Chat';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogEditor from './DialogEditor'

import 'moment/locale/ru'
import io from 'socket.io-client';
import ReactResizeDetector from 'react-resize-detector';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Query as QbQuery, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import './QueryBuilder.css'
//import 'react-awesome-query-builder/lib/css/styles.css';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import EditIcon from '@material-ui/icons/Edit';
import Board from 'react-trello'
import RootRef from "@material-ui/core/RootRef";
import Popover from '@material-ui/core/Popover';
import { ruRU } from '@material-ui/core/locale';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FormRenderer, { componentTypes } from '@data-driven-forms/react-form-renderer';
import { componentMapper, FormTemplate } from '@data-driven-forms/mui-component-mapper';
import Avatar from '@material-ui/core/Avatar';
//import { ColorPicker } from 'material-ui-color';
import ColorPicker from './WorkItemsTable/ColorPicker'
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import './DbEditor.css'
import { orange, green, yellow, purple } from '@material-ui/core/colors';

import Query from '../Utils/query'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@material-ui/icons/Check';
import { ListItemAvatar } from '@material-ui/core';
import ReportEditor from './ReportEditor';
import MemberEditor from './WorkItemsTable/MemberEditor'
import ChatMemberEditor from './WorkItemsTable/ChatMemberEditor'
import SelectEditor from './WorkItemsTable/SelectEditor'
import LinkEditor from './WorkItemsTable/LinkEditor'
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import JobsEditorPanel from './JobsEditorPanel'
import ChatBotsPanel from './ChatBotsPanel'
import ObjectID from "./ObjectId";
import MenuList from '@material-ui/core/MenuList';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//const storageName = 'exlyDbEditorSplitSize';


const InitialConfig = MaterialConfig;//BasicConfig;
//const deepEqual = require('deep-equal')

moment.locale('ru');

const drawerWidth = 240;
//const socket = io();

//console.log("socket.connected", socket.connected); // false

//socket.on('connect', () => {
//  console.log("socket.connected 2", socket.connected); // true
//});

//socket.on('disconnect', () => {
//  console.log("socket disconnect", socket.connected); // false
//});


function KanbanIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M2.5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-11zm5 2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1zm-5 1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3zm9-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1z" />
    </SvgIcon>
  );
}

function DiscordIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z" />
    </SvgIcon>
  );
}

function SlackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" />
    </SvgIcon>
  );
}



function MsTeamsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19.19 8.77q-.46 0-.86-.17q-.39-.17-.69-.47q-.3-.3-.47-.69q-.17-.4-.17-.86q0-.45.17-.85q.17-.4.47-.69q.3-.3.69-.47q.4-.18.86-.17q.45-.01.85.17q.4.17.7.47q.29.29.47.69q.17.4.17.85q0 .46-.17.86q-.17.39-.47.69q-.3.3-.7.47q-.4.17-.85.17m0-3.12q-.39 0-.69.27q-.25.27-.25.66t.25.67q.3.25.69.25q.39 0 .66-.25q.28-.25.28-.67q0-.39-.28-.66q-.27-.27-.66-.27M22 10.33V15q0 .63-.24 1.2q-.26.57-.67.99q-.43.43-1 .67q-.59.25-1.21.25q-.38 0-.76-.11q-.39-.07-.71-.25q-.24.79-.71 1.44q-.47.65-1.1 1.11q-.63.46-1.39.7q-.76.27-1.58.27q-.96 0-1.81-.33q-.82-.33-1.5-.94q-.66-.57-1.09-1.36q-.44-.8-.57-1.74H2.83q-.33 0-.59-.25q-.24-.24-.24-.58V7.73q0-.34.24-.59q.26-.24.59-.24H10q-.29-.6-.29-1.25q0-.61.23-1.15q.22-.5.62-.92q.4-.39.94-.62q.5-.23 1.12-.23q.61 0 1.14.23q.53.23.93.62q.4.42.62.92q.23.54.23 1.15q0 .6-.23 1.14q-.22.53-.62.92q-.4.4-.93.63q-.53.23-1.14.23q-.15 0-.31-.02q-.15-.02-.31-.05v.9h9.06q.39 0 .67.27q.27.27.27.66M12.63 4q-.35 0-.63.11q-.33.13-.56.36q-.22.23-.35.53q-.13.31-.13.65q0 .35.13.65q.13.3.35.53q.23.22.56.36q.28.13.63.13q.34 0 .64-.13q.3-.14.53-.36q.23-.23.36-.53q.14-.3.14-.65q0-.34-.14-.65q-.13-.3-.36-.53q-.23-.23-.53-.36q-.3-.11-.64-.11m-4.85 6.18h1.88V8.62H4.34v1.56h1.88v5h1.56m8.6 1.09v-5.62H12v5.42q0 .34-.24.58q-.26.25-.59.25H8.92q.13.67.47 1.25q.34.57.82.99q.48.41 1.1.65q.61.21 1.32.21q.77 0 1.45-.27q.68-.3 1.2-.81q.51-.51.8-1.19q.3-.68.3-1.46M20.75 15v-4.35h-3.12v5.71q.25.25.57.38q.3.12.68.12q.39 0 .73-.15q.34-.15.59-.4q.26-.25.4-.6q.15-.34.15-.71z" />
    </SvgIcon>
  );
}



function RobotIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,2A2,2 0 0,1 14,4C14,4.74 13.6,5.39 13,5.73V7H14A7,7 0 0,1 21,14H22A1,1 0 0,1 23,15V18A1,1 0 0,1 22,19H21V20A2,2 0 0,1 19,22H5A2,2 0 0,1 3,20V19H2A1,1 0 0,1 1,18V15A1,1 0 0,1 2,14H3A7,7 0 0,1 10,7H11V5.73C10.4,5.39 10,4.74 10,4A2,2 0 0,1 12,2M7.5,13A2.5,2.5 0 0,0 5,15.5A2.5,2.5 0 0,0 7.5,18A2.5,2.5 0 0,0 10,15.5A2.5,2.5 0 0,0 7.5,13M16.5,13A2.5,2.5 0 0,0 14,15.5A2.5,2.5 0 0,0 16.5,18A2.5,2.5 0 0,0 19,15.5A2.5,2.5 0 0,0 16.5,13Z" />
    </SvgIcon>
  );
}

function AddColumnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M11,2A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H2V2H11M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M15,11H18V8H20V11H23V13H20V16H18V13H15V11Z" />
    </SvgIcon>
  );
}

function ConfigColumnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M16,5V18H21V5M4,18H9V5H4M10,18H15V5H10V18Z" />
    </SvgIcon>
  );
}

function AddRowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22,10A2,2 0 0,1 20,12H4A2,2 0 0,1 2,10V3H4V5H8V3H10V5H14V3H16V5H20V3H22V10M4,10H8V7H4V10M10,10H14V7H10V10M20,10V7H16V10H20M11,14H13V17H16V19H13V22H11V19H8V17H11V14Z" />
    </SvgIcon>
  );
}
function RowHightIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3,5H15A2,2 0 0,1 17,7V17A2,2 0 0,1 15,19H3A2,2 0 0,1 1,17V7A2,2 0 0,1 3,5M3,9V12H8V9H3M10,9V12H15V9H10M3,14V17H8V14H3M10,14V17H15V14H10M23,14V7H19V9H21V12H19V14H23Z" />
    </SvgIcon>
  );
}



const useStyles = makeStyles((theme) => ({
  colorDBPrimary: {
    color: "#fff",
    backgroundColor: "#3f5222"
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    visibility: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, //theme.spacing(7) + 1,
    visibility: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: 0,//theme.spacing(9) + 1,
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    //padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0//-drawerWidth,
  },
  contentShift: {
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

  },
  list: {
    marginBottom: theme.spacing(2),
  },
  paper_send_invite: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: purple[50]
  },

  paper_invite_links: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  question_list: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  job_card: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  fabButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 99
  },
  fileinput: {
    display: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  scheduler: {
    display: 'flex',
  },
  settingsPaper: {
    //marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  nonSelectedListItemSecondaryAction: {
    visibility: "hidden",
    //cursor:"move"
  },
  selectedListItemSecondaryAction: {

  },
  nonSelectedListItem: {
    "&:hover $nonSelectedListItemSecondaryAction": {
      visibility: "inherit"
    }
  }
}));

function IconForViewList(props) {
  return (<ViewListIcon fontSize="small" {...props} color="primary" />)
}

function IconForViewKanban(props) {
  return (<TableChartIcon fontSize="small" {...props} style={{ color: green[500] }} />)
}

function IconForViewCalendar(props) {
  return (<EventIcon fontSize="small" {...props} style={{ color: orange[700] }} />)
}

function IconForViewBot(props) {
  return (<QuestionAnswerIcon fontSize="small" {...props} color="secondary" />)
}

const AntTabs = withStyles({
  root: {
    //borderBottom: '1px solid #e8e8e8',
    minHeight: 24,
  },
  indicator: {
    //backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    minWidth: 72,
    minHeight: 24,
    paddingTop: 4,
    paddingRight: 12,
    paddingBottom: 3,
    paddingLeft: 12,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    backgroundColor: "rgba(0,0,0,0.1)",
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: "rgba(0, 0, 0, 0.87)", //'#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: "rgba(0, 0, 0, 0.87)", //'#1890ff',
      //opacity: 0.7,
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#fafafa"
    },
    '&:focus': {
      color: "rgba(0, 0, 0, 0.87)" //'#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function contentEditable() {

  return class extends React.Component {

    state = {
      editing: false
    }

    toggleEdit = (e) => {
      e.stopPropagation();
      if (this.state.editing) {
        this.cancel();
      } else {
        this.edit();
      }
    };

    edit = () => {
      this.setState({
        editing: true
      }, () => {
        this.domElm.focus();
      });
    };

    save = () => {
      this.setState({
        editing: false
      }, () => {
        if (this.props.onSave && this.isValueChanged()) {
          //console.log('Value is changed', this.domElm.textContent);
          this.props.onSave(this.domElm.textContent);
        }
      });
    };

    cancel = () => {
      this.setState({
        editing: false
      });
    };

    isValueChanged = () => {
      return this.props.value !== this.domElm.textContent
    };

    handleKeyDown = (e) => {
      const { key } = e;
      switch (key) {
        case 'Enter':
        case 'Escape':
          this.save();
          break;
      }
    };

    render() {
      let editOnClick = true;
      const { editing } = this.state;
      if (this.props.editOnClick !== undefined) {
        editOnClick = this.props.editOnClick;
      }
      return (

        <Tooltip title={'Нажмите, чтобы отредактировать'}>
          <span
            className={editing ? 'editing' : ''}
            onClick={editOnClick ? this.toggleEdit : undefined}
            contentEditable={editing}

            ref={(domNode) => {
              this.domElm = domNode;
            }}
            onBlur={this.save}
            onKeyDown={this.handleKeyDown}
            {...this.props}
          >
            {this.props.value}
          </span></Tooltip>

      )
    }
  }
}

function ItemEditDialog(props) {
  const [editedItem, setEditedItem] = useState(null);
  const [columns, setColumns] = useState([]);
  const theme = useTheme();
  const classes = useStyles();
  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //props.setEditedIdx(null);
    props.setEditedItem(null);
    props.setOpen(false);
  };



  const lookupValue = (col) => {
    let lookupColumn = columns.find(cl => cl._id === col.lookupId)
    if (lookupColumn) {
      if (props.tables[lookupColumn.linkId] && editedItem[lookupColumn._id]) {
        let item = props.tables[lookupColumn.linkId].items[editedItem[lookupColumn._id]]
        if (item[col.lookupField]) {
          var fieldCol = props.tables[lookupColumn.linkId].columns.find(cl => cl._id === col.lookupField)

          if (fieldCol && fieldCol.type === 'member') {
            var member = props.members.find(mb => mb._id === item[col.lookupField])
            if (member) {
              return member.name
            }

          }
          return item[col.lookupField]
        }

      }
    }
    return ""
  }


  useEffect(() => {
    if (props.editedItem) {
      //console.log('useEffect props.editedItem.item ', props.editedItem.item)
      var defCol = []
      if (props.editedItem.columns && props.editedItem.columns.length > 0) {

        for (var ic = 0; ic < props.editedItem.columns.length; ic++) {
          var col = props.editedItem.columns[ic]
          if (col._id === "ex_rAnswered" ||
            col._id === "ex_requestDt" ||
            col._id === "ex_rAnswerDt" ||
            col._id === "ex_updatedAt" ||
            col._id === "ex_createdAt" ||
            col._id === "ex_changeAutor" ||
            col._id === "ex_createdAutor" ||
            col._id === "ex_requestAc" ||
            col._id === "_id"
          ) {

          } else {
            defCol.push(col)
          }
        }
      }
      setColumns(defCol)
      setEditedItem(props.editedItem.item)
    }
  }, [props.editedItem])

  return (

    <Dialog
      fullWidth={true}
      maxWidth="sm"
      //fullScreen={fullScreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-item-edit-dialog-title"
    >
      <DialogTitle id="responsive-item-edit-dialog-title" ><Typography variant="h6" noWrap>{(!props.editedItem || !props.editedItem._id) ? "Добавить элемент" : "Редактировать элемент"}</Typography>
        <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton></DialogTitle>
      <DialogContent dividers>
        <div >
          {editedItem && columns && columns.map(column => (
            <Grid container alignItems="flex-end">
              <Grid item xs={4}>
                <Typography variant="body1">{column.name}:</Typography>
              </Grid>
              <Grid item xs={8}>
                {column.type === "member" ? (
                  <MemberEditor
                    value={editedItem[column._id]}
                    inForm={true}
                    onChange={value => {
                      const newVal = value;
                      const colkey = column._id
                      setEditedItem(curItem => {
                        var newItem = { ...curItem }
                        newItem[colkey] = newVal
                        return newItem
                      })
                    }}
                    members={props.members}
                    rowHeight={"25px"}
                    //menuPortalTarget={p.editorPortalTarget}
                    //onCreateOption={handleCreateColumnOpinion}
                    noOptionsMessage="Нет значений для выбора!"
                  />
                ) :
                  (column.type === "chatmember") ? (
                    <ChatMemberEditor
                      value={editedItem[column._id]}
                      inForm={true}
                      onChange={value => {
                        const newVal = value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                      }}
                      members={props.chatmembers}
                      rowHeight={"25px"}
                      //menuPortalTarget={p.editorPortalTarget}
                      //onCreateOption={handleCreateColumnOpinion}
                      noOptionsMessage="Нет значений для выбора!"
                    />
                  ) : (column.type === "select") ? (
                    <SelectEditor
                      value={editedItem[column._id]}
                      inForm={true}
                      onChange={value => {
                        const newVal = value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                      }}
                      options={column.options}
                      rowHeight={"25px"}
                      //menuPortalTarget={p.editorPortalTarget}
                      //onCreateOption={handleCreateColumnOpinion}
                      noOptionsMessage="Нет значений для выбора!"
                    />
                  ) : (column.type === "link" && column.linkId && props.tables[column.linkId]) ? (
                    <LinkEditor
                      //options, linkId, titleColName
                      value={editedItem[column._id]}
                      titleCol={column.linkViewField}
                      inForm={true}
                      tables={props.tables}
                      linkId={props.linkId}
                      filterView={column.linkSelectView}
                      currentMember={props.currentMember}
                      members={props.members}
                      chatmembers = {props.chatmembers}                        
                      onChange={value => {
                        const newVal = value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                      }}
                      options={props.tables[column.linkId].itemsList}//{props.tables}
                      rowHeight={"25px"}
                      //menuPortalTarget={p.editorPortalTarget}
                      //onCreateOption={handleCreateColumnOpinion}
                      noOptionsMessage="Нет значений для выбора!"
                    />
                  ) : (column.type === "lookup" && column.lookupId && column.lookupField) ? (
                    <TextField
                      id="item_lookup_editor"
                      multiline
                      value={lookupValue(column)}

                      //placeholder="Введите название для вида"
                      //helperText=""                            
                      margin="normal"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (column.type === "number") ? (
                    <TextField
                      id="item_number_editor"
                      //label={column.name}
                      type="number"
                      //error={Boolean(titleError)}
                      //helperText={titleError}
                      value={editedItem[column._id]}
                      onChange={(e) => {
                        const newVal = e.target.value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                      }}
                      //placeholder="Введите название для вида"
                      //helperText=""                            
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    ): (column.type ===   "geolocation")?(
                      <TextField
                        id="item_geolocation_editor"
                        value={(editedItem[column._id] && editedItem[column._id].longitude)?`${editedItem[column._id].longitude}, ${editedItem[column._id].latitude}`:"" }                        
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                              <IconButton target="_blank" href={(editedItem[column._id] && editedItem[column._id].longitude)?`https://yandex.ru/maps/?ll=${editedItem[column._id].longitude}%2C${editedItem[column._id].latitude}84&z=18`:"#"} size="small">
                                <LinkIcon fontSize="small" />
                              </IconButton>
                          )
                        }}
                      />                        
                      ): (column.type ===   "attachments")?(
                        <TextField
                          id="item_attachments_editor"
                          value={editedItem[column._id]}                        
                        />                        
                    ) : (column.type === 'check') ? (
                    <Checkbox
                      checked={editedItem[column._id]}
                      color="primary"
                      onChange={(e) => {
                        const newVal = e.target.checked;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                      }} />
                  ) : (column.type === 'multiline') ? (
                    <TextField
                      id="item_text_editor"
                      multiline
                      rows={3}
                      value={editedItem[column._id]}
                      onChange={(e) => {
                        const newVal = e.target.value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                          return newItem
                        })
                      }}
                      //placeholder="Введите название для вида"
                      //helperText=""                            
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (column.type === 'date') ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                      <KeyboardDateTimePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        id="item-editor-date-picker-inline"
                        //label={column.name}
                        value={editedItem[column._id]}
                        onChange={(e) => {
                          const newVal = e;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) :
                    <TextField
                      id="item_text_editor"
                      //label={column.name}
                      //type="number"
                      //error={Boolean(titleError)}
                      //helperText={titleError}
                      value={editedItem[column._id]}
                      onChange={(e) => {
                        const newVal = e.target.value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                          return newItem
                        })
                      }}
                      //placeholder="Введите название для вида"
                      //helperText=""                            
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                }
              </Grid>
            </Grid>))}
        </div>
      </DialogContent>
    </Dialog>
  );
}



function AddTableDialog(props) {
  const [open, setOpen] = useState(false);
  //const [type, setType] = useState('list');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(null);
  const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
    //setType('list')
    setTitle("")
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (event) => {
    if (!title || title.trim().length < 2) {
      setTitleError('Название меньше 2 симоволов!')
      return
    }
    var newTable = {
      title: title,
    }

    props.addTable(newTable)
    handleClose();
  };

  return (
    <>
      <Tooltip title="Добавить новую таблицу">
        <IconButton color="inherit" size="small" onClick={handleClickOpen}> <AddBoxIcon fontSize="small" /></IconButton>
      </Tooltip>
      <Dialog
        //fullScreen={fullScreen}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && title && title.length>1){
            handleCreate()
          }
        }}        
        open={open}
        onClose={handleClose}
        aria-labelledby="add_table-dialog-title"
      >
        <DialogTitle id="add_table-dialog-title">Добавить новую таблицу</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              id="add_table_title"
              name="table_title"
              autoFocus
              //label="Название вида"
              error={Boolean(titleError)}
              helperText={titleError}
              value={title}
              onChange={(e) => { setTitle(e.target.value); setTitleError(null) }}
              placeholder="Введите название для таблицы"
              //helperText=""                            
              margin="normal"
              fullWidth

              InputLabelProps={{
                shrink: true,
              }}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Отмена
          </Button>
          <Button size="small" color="primary" variant="contained" onClick={handleCreate}>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


function TableTabsListConfig(props) {
  const [tablesTabs, setTablesTabs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e, tb) => {
    const __tb = tb
    if (__tb.hidden) {
      props.updateTable(__tb._id, { hidden: false }, () => props.handleChangeTable(e, __tb._id))

    } else {
      props.handleChangeTable(e, __tb._id)

    }
    handleClose()
  };

  const handleUnHide = (e, tb) => {
    const __tb = tb
    if (__tb.hidden) {
      props.updateTable(__tb._id, { hidden: false })
    }
  };


  const onDragEnd = (result) => {
    console.log('TableTabsList onDragEnd ', result)
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    //console.log('ViewsConfigList onDragEnd ', result.source, result.destination)
    //props.handleViewsReorder(props.views[result.source.index]._id, props.views[result.destination.index]._id)

    var _newTables = [...tablesTabs];
    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;

    //const sourceColumnIndex = reorderedColumns.findIndex(c => c.key === sk);
    //const targetColumnIndex = reorderedColumns.findIndex(c => c.key === tk);
    //const reorderedColumns = [...columns];

    _newTables.splice(
      targetIndex,
      0,
      _newTables.splice(sourceIndex, 1)[0]
    );
    //console.log('onDragEnd _newViews', _newViews)
    setTablesTabs(_newTables)

    for (let idx = 0; idx < _newTables.length; idx++) {
      if (_newTables[idx].index === idx) {

      } else {
        props.updateTable(_newTables[idx]._id, { index: idx }) //TODO Сделать групповое обновление
      }
    }
  }

  useEffect(() => {
    if (props.tables) {
      //console.log('ViewsConfigList props.views', props.views);
      var sortedIds = Object.keys(props.tables).sort((a, b) => props.tables[a].index - props.tables[b].index);
      var _tables = []
      for (let iv = 0; iv < sortedIds.length; iv++) {
        _tables.push(props.tables[sortedIds[iv]])
      }
      //console.log('ViewsConfigList views', views);
      setTablesTabs(_tables)
    } else {
      setTablesTabs([])
    }
  }, [props.tables])

  const open = Boolean(anchorEl);
  const id = open ? 'table-tabs-popover' : undefined;

  return (
    <React.Fragment>
      <Tooltip title="Настройка списка таблиц">
        <IconButton onClick={handleClickOpen} size="small" variant="text" color="inherit">
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableTableTab">
            {(provided, snapshot) => (
              <RootRef rootRef={provided.innerRef} >
                <List dense={true} >
                  {tablesTabs && tablesTabs.map((tb, idx) => (
                    <Draggable key={tb._id} draggableId={tb._id} index={idx}>
                      {(provided, snapshot) => (

                        <ListItem
                          disableGutters
                          button
                          onClick={e => handleClick(e, tb)}
                          selected={tb._id === props.wiId}
                          ContainerComponent="li"
                          ContainerProps={{ ref: provided.innerRef, className: classes.nonSelectedListItem }}
                          //style={{
                          //paddingLeft: "2px"
                          //}}         
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListItemIcon style={{ minWidth: "32px" }}

                          >

                            <DragIndicatorIcon fontSize="small" color="disabled" className={classes.nonSelectedListItemSecondaryAction} />
                          </ListItemIcon>
                          <ListItemText

                            id={"tb-el-label-" + idx}
                            primary={tb.title}
                            primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
                          />
                          <ListItemSecondaryAction
                            onClick={e => handleUnHide(e, tb)}
                          >
                            {tb._id === props.wiId && <CheckIcon fontSize="small" />}
                            {tb.hidden && <VisibilityOffIcon fontSize="small" color="disabled" />}
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      </Popover>
    </React.Fragment>
  );
}




function DbDescMenu(props) {
  const { token } = useContext(AuthContext);
  //const {loading, error, request} = useHttp();
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState("");
  const classes = useStyles();
  const history = useHistory();



  const handleClickOpen = () => {
    //setGroup(props.groups[0]._id);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.handleChangeDesc(desc)
    handleClose();
  };

  const handleChangeDesc = (previousRange, source, editor) => {
    setDesc(editor.getHTML())
  }

  useEffect(() => {
    setDesc(props.desc)
  }, [props.desc])


  return (
    <React.Fragment>
      <Tooltip title={"Показать описание чат-бота"}>
        <IconButton
          color="inherit"
          size="small"
          value="hiddenDesc"
          onClick={handleClickOpen}
        >
          <InfoIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Описание {props.title} </Typography>
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <ReactQuill
              //hide = { hiddenDesc }
              theme="snow"
              placeholder="Напишите здесь описание ..."
              defaultValue={desc}
              modules={{
                toolbar: [
                  //[{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link'],
                  //['clean']
                ],
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                //'list', 'bullet', 'indent',
                'link'
              ]}
              onBlur={handleChangeDesc}
              //onBlur={handleChangeDesc}
              //onBlur={handleChangeDesc}
              />
          </div>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            Отмена
          </Button>
          <Button onClick={handleSave} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}





function DBCopyDialog({ onSelectHandle }) {

  const [open, setOpen] = useState(false);
  const [newTelegramBotToken, setNewTelegramBotToken] = useState("");

  const classes = useStyles();
  const history = useHistory();

  const onChangeTelegramBotToken = (event) => {
    const v = event.target.value;
    setNewTelegramBotToken(v)
  }


  const handleClickOpen = () => {
    //setGroup(props.groups[0]._id);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSelectHandle(newTelegramBotToken)
    handleClose();
  };



  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <FilterNoneIcon fontSize="small" />
        </ListItemIcon> Создать копию
      </MenuItem>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Введите API Token нового Telegram-бота </Typography>
        </DialogTitle>
        <DialogContent dividers>

          <Paper className={classes.job_card} elevation={3}>
            <div >

              <TextField
                id={"new-telegram-bot-token"}
                value={newTelegramBotToken}
                onChange={onChangeTelegramBotToken}
                placeholder="Telegram bot access token"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                label="API Token"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>            
          </Paper>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Отмена
          </Button>
          <Button color="primary" onClick={handleSave}>
            Создать бота
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
}

function BotchannelSettingsPopover(props) {
  //const [name, setName] = useState("");
  //const [icon, setIcon] = useState(<></>);
  //const [invite_url, setInvite_url] = useState("");
  //const [viewEmoji, setViewEmoji] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClickOpen = (event) => {
    //setGroup(props.groups[0]._id);
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const onChangeBotDefaultDialog = (_botId, _dialogId) => {
    const v = _dialogId
    const _id = _botId
    props.changeBot(
      _id,
      {
        defaultDialog: v
      })
  }


  const onChangeNameHandler = (event) => {
    //console.log('event.target.name',event.target.name);
    //props.updateTable(props._id, {title: event.target.value})
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    //onSelectHandle();        
    setAnchorEl(null);
  };

  const handleConnectBot = () => {
    props.connectBot(props.botchannel._id);
    handleClose();
  };

  const handleDel = () => {
    props.deleteBot(props.botchannel._id)
    handleClose();
  };

  const handleCopy = () => {
    //props.handleCopyTable(props._id); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
    handleClose();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  return (
    <React.Fragment>

      <Tooltip title={'Нажмите, чтобы отредактировать'}>
        <ButtonGroup
          variant="text"
          //color={props.question.hasCondition?"secondary":"default"}
          size="small"
          //ref={anchorRef} 
          aria-label="split button">
          <Button
            variant="text"
            size="small"
            edge="end"
            aria-label="menu"
            color="inherit"
            aria-controls="bot-menu"
            aria-haspopup="true"
            //onClick={handleClickOpen} 
            href={props.botchannel.invite_url}
            target="_blank"
            startIcon={props.botchannel.channel === "telegram" ?
              <TelegramIcon fontSize="small" /> :
              props.botchannel.channel === "slack" ?
                <SlackIcon fontSize="small" /> :
                props.botchannel.channel === "teams" ?
                  <MsTeamsIcon fontSize="small" /> :
                  props.botchannel.channel === "discord" ?
                    <DiscordIcon fontSize="small" /> : <></>}
            onContextMenu={handleClickOpen}
          //endIcon={<IconButton  size="small" onClick={handleClickOpen}><ArrowDropDownIcon fontSize="small"/></IconButton>}
          >
            @{props.botchannel.name}
          </Button>
          <Button
            //disabled
            color="inherit"
            //variant="outlined"
            size="small"
            //aria-controls={open ? 'split-button-menu' : undefined}
            //aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleClickOpen}
          >
            <SettingsIcon fontSize="small" />
          </Button>
        </ButtonGroup>


      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      //        style={{minWidth:"250px"}}
      >
        <Paper elevation={3} >
          <div className={classes.settingsPaper}>
            {(props.botchannel.channelId && props.botchannel.channel) ?
              <>
                <div>
                  <a target="_blank" href={props.botchannel.invite_url}>{props.botchannel.name}</a> <CopyToClipboard text={props.botchannel.invite_url}>
                    <IconButton size="small" >
                      <FilterNoneIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                </div>
                <div><b>@{props.botchannel.mention_name}</b> | <b>ID:</b>{props.botchannel.channelId} </div>
              </> : <>
                <div>{props.botchannel.name}</div></>}
            <div>
              <TextField
                id={"bot-default-dialog-" + props.botchannel._id}
                //label="Уровень доступа:"
                value={props.botchannel.defaultDialog}
                onChange={(e) => onChangeBotDefaultDialog(props.botchannel._id, e.target.value)}
                placeholder=""
                helperText="Диалог по-умолчанию"
                fullWidth
                select
                margin="normal"
                className={classes.selectEmpty}
                InputLabelProps={{
                  shrink: true,
                }}>
                {props.dialogs && props.dialogs.map(dialog => (
                  <MenuItem key={dialog._id} value={dialog._id}><IconForViewBot />
                    {dialog.name}
                  </MenuItem>
                )

                )}


              </TextField>
            </div>
          </div>
          {props.botchannel.channelId && props.botchannel.channel &&
            <ChatBotChangeTokenDialog botchannel={props.botchannel} connectBot={props.connectBot} />}
          <MenuItem onClick={handleDel}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>Удалить</MenuItem>
        </Paper>
      </Popover>
    </React.Fragment>
  );
}
function ChatBotChangeTokenDialog(props) {
  const { token } = useContext(AuthContext);
  //const {loading, error, request} = useHttp();
  const [open, setOpen] = useState(false);
  const [newChatChannel, setNewChatChannel] = useState(null);
  //const [newChatDefaultDialog, setNewChatDefaultDialog] = useState("");
  const [newTelegramBotToken, setNewTelegramBotToken] = useState("");

  const classes = useStyles();
  const history = useHistory();

  const onChangeTelegramBotToken = (event) => {
    const v = event.target.value;
    setNewTelegramBotToken(v)
  }

  const handleDeleteBotChannel = (channelId) => {
    //console.log('handleDeleteBotChannel ',linkId)
    //props.deleteLinkInvite(linkId);
  };

  const handleDeleteChatMembar = (member_id) => {
    //console.log('handleDeleteBotChannel ',)
    //props.deleteLinkInvite(linkId);
  };

  const handleInviteByChat = () => {
    props.connectBot(props.botchannel._id, {
      channel: "telegram",
      // channel_type:"telegram",
      token: newTelegramBotToken,
      //defaultDialog:newChatDefaultDialog
    })
    setOpen(false);
  };



  const handleClickOpen = () => {
    //setGroup(props.groups[0]._id);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleInviteByChat()
    handleClose();
  };




  return (
    <React.Fragment>
      <Tooltip title={"Изменить токен telegram бота"}>
        <MenuItem onClick={handleClickOpen}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>Изменить токен</MenuItem>
      </Tooltip>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Подключить telegram-бота </Typography>
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          {props.errorMsg && <Alert variant="filled" severity="error" onClose={() => { props.setErrorMsg(null) }}>{props.errorMsg}</Alert>}
        </DialogTitle>
        <DialogContent dividers>

          <Paper className={classes.job_card} elevation={3}>
            <div >
              <Typography>Введите новый токен Telegram-бота</Typography>

              <TextField
                id={"new-telegram-bot-token"}
                value={newTelegramBotToken}
                onChange={onChangeTelegramBotToken}
                placeholder="Telegram bot access token"
                helperText=""
                fullWidth
                //multiline
                //rows={3}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />

            </div>
          </Paper>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Отмена
          </Button>
          <Button onClick={handleSave}>
            Изменить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}



function TableSettingsPopover(props) {
  const [title, setTitle] = useState("");
  //const [icon, setIcon] = useState("");
  //const [viewEmoji, setViewEmoji] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClickOpen = (event) => {
    if (props.accessLevel >= 5) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    }
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value)
  }

  const onChangeTitleHandler = (event) => {
    //console.log('event.target.name',event.target.name);
    props.updateTable(props._id, { title: event.target.value })
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    //onSelectHandle();        
    setAnchorEl(null);
  };



  const handleDel = () => {
    props.deleteTable(props._id);
    handleClose();
  };

  const handleHide = () => {
    const __tbId = props._id
    props.updateTable(__tbId, { hidden: true }, () => props.handleChangeTable(null, __tbId))
    handleClose();
  }

  const handleCopy = () => {
    //props.handleCopyTable(props._id); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
    handleClose();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setTitle(props.title)
    //setIcon(props.icon)
  }, [props.title])

  return (
    <React.Fragment>
      <span ref={props.dragRef}><span onContextMenu={handleClickOpen} style={{ textOverflow: "ellipsis", maxWidth: "240px", overflow: "hidden" }}>{props.title}</span>
        {props.isActive && (props.accessLevel >= 5) &&
          <Tooltip title={'Нажмите, чтобы отредактировать'}>
            <IconButton color="inherit" size="small" onClick={handleClickOpen}><ArrowDropDownIcon fontSize="small" /></IconButton>
          </Tooltip>}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      //        style={{minWidth:"250px"}}
      >
        <Paper elevation={3} >
          <div className={classes.settingsPaper}>
            <TextField
              autoFocus
              id={"table_title_field_" + props._id}
              value={title}
              name="title"

              placeholder="Название"
              helperText=""


            />
          </div>
          <MenuList id="table-context-menu-list" dense>
            <MenuItem onClick={handleHide}>
              <ListItemIcon>
                <VisibilityOffIcon fontSize="small" />
              </ListItemIcon> Скрыть
            </MenuItem>
            <MenuItem onClick={handleCopy}>
              <ListItemIcon>
                <FilterNoneIcon fontSize="small" />
              </ListItemIcon> Создать копию
            </MenuItem>
            <MenuItem onClick={handleDel}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>Удалить</MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </React.Fragment>
  );
}

function RowHeightMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetRowHeight = (height) => {
    var rowHieght = 25;
    if (height === "middle") {
      var rowHieght = 50;
    } else if (height === "tall") {
      var rowHieght = 75;
    } else if (height === "extratall") {
      var rowHieght = 100;
    }
    props.handleSetRowHeight(rowHieght);
    handleClose()
  };



  return (
    <React.Fragment>

      <Tooltip title="Установить высоту строк">
        <IconButton size="small" variant="text" onClick={handleClick}>
          <RowHightIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="more-item-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleSetRowHeight("small")}>Узкая</MenuItem>
        <MenuItem onClick={(e) => handleSetRowHeight("middle")}>Средняя</MenuItem>
        <MenuItem onClick={(e) => handleSetRowHeight("tall")}>Широкая</MenuItem>
        <MenuItem onClick={(e) => handleSetRowHeight("extratall")}>Супер широкая</MenuItem>
      </Menu>
    </React.Fragment>
  );
}


function SavingDialog(props) {
  //const [open, setOpen] = React.useState(false);
  const handleClose = () => {

    props.handleClose()

  };

  const handleCancel = () => {

    props.handleClose()

  };

  const handleSave = () => {

    props.handleSave();
    props.handleClose()
  };

  const handleNotSave = () => {

    props.handleClose()
  };

  return (
    <React.Fragment >
      <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Сохранить отчет</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Сохранить изменения в отчете?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotSave} color="primary">
            Не сохранять
          </Button>
          <Button onClick={handleSave} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}




export default function SharedView(props) {
  const { token, isAuthenticated } = useContext(AuthContext);
  const { onChangeReport, setCurrentPageTitle } = useContext(NavContext);
  const { request, error } = useHttp();
  const unblockHandle = useRef();
  const [errorMsg, setErrorMsg] = useState(null);
  const [openSets, setOpenSets] = React.useState(false);
  const [editingSets, setEditingSets] = React.useState(null);
  const viewContentRef = useRef(null);
  const [viewContentWidth ,setViewContentWidth]= useState(800)
  const [viewContentHeight ,setViewContentHeight]= useState(800)     

  //const [isOpenDescription, setOpenDescription] = useState(true);     
  const [nextPage, setNextPage] = useState(null)
  const [edited, setEdited] = useState(false);
  const [profile, setProfile] = useState(null);
  const [chatgroups, setChatgroups] = useState([]);
  const [accessLevel, setAccessLevel] = useState(0);
  const [currentMember, setCurrentMember] = useState(null);  
  const [desc, setDesc] = useState('');
  const [title, setTitle] = useState('')
  const [isPublic, setIsPublic] = useState(false)
  const [icon, setIcon] = useState(null);
  const [title_column, setTitle_column] = useState(null)
  const [hiddenDesc, setHiddenDesc] = useState(true)
  const [loadingWI, setLoadingWI] = useState(true);
  const [members, setMembers] = useState([]);
  const [chatmembers, setChatMembers] = useState([]);
  const [noLoadDB, setNoLoadDB] = useState(false);
  
  const [inviteLinks, setInviteLinks] = useState([]);
  const [dialogs, setDialogs] = useState([]);
  const [chatViewLinks, setChatViewLinks] = useState([]);

  const [botchannel, setBotchannel] = useState([]);
  const [botchannelMessages, setBotchannelMessages] = useState([]);
  const [jobs, setJobs] = useState([]);
  const history = useHistory();
  const [currentView, setCurrentView] = useState(null);
  const [tables, setTables] = useState([])
  const [tablesTabs, setTablesTabs] = useState([])
  const [openItemEditor, setOpenItemEditor] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const [editedIdx, setEditedIdx] = useState(null)
  const [openJobsPanel, setOpenJobsPanel] = useState(false)
  const [currentPanelSize, setCurrentPanelSize] = useState("100%")
  const [jobsPanelSize, setJobsPanelSize] = useState("70%")
  const [botsPanelSize, setBotsPanelSize] = useState("50%")
  const [rigthPanelContent, setRigthPanelContent] = useState(null)
  const [dbBackgroundColor, setDbBackgroundColor] = useState("#3f5222")


  const sharedId = useParams().sharedId;
  const [dbId, setDbId] = useState(null)  
  const [wiId, setWiId] = useState(null)
  const [viewId, setViewId] = useState(null)  
  const currentURLPath = window.location.pathname + '' + window.location.search

  const classes = useStyles();

  let EditableDIV = contentEditable();


  const saveTable = useCallback(async (_tableId, params) => {

  }, [])


  const insertItem = useCallback(async (_tableId, values) => {
    try {
      //if(values._id) {delete values._id}
      //console.trace()
      var _id = ObjectID().toHexString()
      //console.log('insertItem values ', values)
      const addedItem = {
        ...values,
        _id: _id,
        work_item_list: _tableId
      }
      //console.log('insertItem addedItem ', addedItem)
      //if(!values) {
      //  values = {}
      //}
      //values._id = _id
      //values.work_item_list = _tableId
      //delete values.__ex_new_row
      //const addedItem = values
      const __tableId = _tableId
      setTables(currentTables => {
        var newTables = { ...currentTables }
        var newItems = { ...newTables[__tableId].items };
        var newItemsList = [...newTables[__tableId].itemsList]
        newItems[addedItem._id] = addedItem;
        newItemsList.push(addedItem)

        newTables[__tableId].items = newItems
        newTables[__tableId].itemsList = newItemsList
        return newTables
      });

    } catch (e) {
      setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
      console.log('insertItem error', e); 
    }
  }, [setTables])

  const insertItem2 = useCallback(async (_tableId, addedItem) => {

  }, [])  

  const deleteItem = useCallback(async (_tableId, itemId) => {

  }, [])  

  const getProfile = useCallback(async () => {
    if (isAuthenticated && token) {
      console.log('getProfile ', isAuthenticated, token)
      try {
        var data = await request('/api/auth/profile', 'GET', null, { Authorization: `Bearer ${token}` });
        setProfile(data);
        console.log('profile data', data);
      } catch (e) {
        console.log('profile error', e);
      }
    } else {
      setProfile(null);
    }
  }, [token, request, setProfile, isAuthenticated])

  const init = useCallback(async () => {
    try {      
      setLoadingWI(true);

      var db = await request(`/api/shared/${sharedId}`, 'GET', null, { Authorization: `Bearer ${token}` });
      console.log('init db', db);
      //console.timeLog("db init");

      //console.time("db init sets");
      getProfile()
      setTitle(db.title);
      setIsPublic(db.isPublic)
      setIcon(db.icon)
      setDbBackgroundColor(db.dbBackgroundColor)
      setDesc(db.desc);
      setAccessLevel(db.accessLevel)
      setCurrentMember(db.currentMember)
      //console.log('db.accessLevel ', db.accessLevel)
      setChatgroups(db.chatGroups)
      setMembers(db.members)
      setChatMembers(db.chatMembers)
      setInviteLinks(db.inviteLinks)
      setBotchannel(db.botchannel)

      setTables(db.tablesAsObject)
      setDbId(db._id)
      setWiId(db.wiId)
      setViewId(db.viewId)
      //setTablesTabs(db.tablesList)              
      setJobs(db.jobs)           
      setDialogs(db.dialogs)
      setLoadingWI(false);
    } catch (e) {
      setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
      setNoLoadDB(true)
      console.log('init report error', e);
    }
  }, [token, dbId, request])



  /////////////////////////////////////
  const onColumnInsert = (_tableId, colIdx, col, viewId) => {
    console.log('onColumnInsert ', _tableId, colIdx, col, viewId);
    const __сix = colIdx
    const __tableId = _tableId
    const __viewId = viewId
    const newCol = {
      ...col,
      resizable: true,
      sortable: true,
      // width: 150
    };
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newcolumns = [...newTables[__tableId].columns];
      var сix = (__сix && __сix > 0) ? __сix : newcolumns.length;

      newcolumns.push(newCol)
      newTables[__tableId].columns = newcolumns

      var newViews = { ...newTables[__tableId].views }
      if (newViews[__viewId] && newViews[__viewId].viewtype === "list") {
        console.log('onColumnInsert list')
        newViews[__viewId].listColumnsOptions[newCol._id] = {
          index: сix,
          frozen: false,
          width: 150
        }
        newTables[__tableId].views = newViews
      }


      return newTables
    })

  }

  const onColumnDelete = (_tableId, colId) => {
    console.log('onColumnDelete', _tableId, colId);
    const __tableId = _tableId
    const cix = colId;

    setTables(currentTables => {
      const colIdx = currentTables[__tableId].columns.findIndex(cl => cl._id === cix)
      const delCol = currentTables[__tableId].columns[colIdx]
      if (delCol.type === 'title') {
        //нельзя удалять ключевую колонку
        setErrorMsg('Нелья удалять колонку ' + delCol.name + '! Вы можете переименовать ее')
      } else {
        var newTables = { ...currentTables }
        var newcolumns = [...newTables[__tableId].columns];

        newcolumns = [
          ...newcolumns.slice(0, colIdx),
          ...newcolumns.slice(colIdx + 1)
        ];

        newTables[__tableId].columns = newcolumns
        return newTables
      }
    });


  }

  const setColumns = (_tableId, newColumns) => {
    console.log('setColumns ', _tableId, newColumns)
    const __tableId = _tableId
    const __newColumns = newColumns
    setTables(currentTables => {
      var newTables = { ...currentTables }
      newTables[__tableId].columns = __newColumns

      return newTables
    });
    //}

  }

  const onChangeColumn = (_tableId, colIdx, newCol) => {

    const nc = newCol;

    //updateColumn(_tableId, newCol._id, newCol)//изменяем на сервере, сюда придет через подписку!

  }

  //function onRowInsertAbove({ rowIdx }) {
  //  insertRow(rowIdx);
  //}

  //function onRowInsertBelow(_tableId, { rowIdx }) {
  //  insertRow(_tableId, rowIdx + 1);
  //}

  function onRowReorder(_tableId, fromIndex, toIndex, fromItem, toItem) {
    console.log('onRowReorder ', _tableId, fromIndex, toIndex, fromItem, toItem)
    //var updatedItemsList = []
    //updatedItemsList.push({...fromItem, index:toItem.index})
    //updatedItemsList.push({...toItem, index:fromItem.index})
    //updateItem(_tableId, fromItem._id, { index: toItem.index })
    /*
    const __tableId = _tableId
    const fi = fromIndex;
    const ti = toIndex;
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newitemsList = [...newTables[__tableId].itemsList];      
      
      var updatedItemsList = [] 
      for (let idx = 0; idx < newitemsList.length; idx++) {
        if (newitemsList[idx].index === fi) {
          newitemsList[idx].index = ti
          updatedItemsList.push(newitemsList[idx])
        } else if ((fi-ti)>0) {
          //Т.е. свинули снизу вверх
         if(newitemsList[idx].index > ti && newitemsList[idx].index<= fromIndex) {
          newitemsList[idx].index = newitemsList[idx].index+1;
          updatedItemsList.push(newitemsList[idx])
         }
        } else if ((ti-fi)>0) {
          //Т.е. свинули снизу вниз
         if(newitemsList[idx].index < ti && newitemsList[idx].index>= fromIndex) {
          newitemsList[idx].index = newitemsList[idx].index-1;
          updatedItemsList.push(newitemsList[idx])
         }           
        } else {        

        }
      }
             
      newTables[__tableId].itemsList = newitemsList
      newTables[__tableId].items = {...newTables[__tableId].items} //чтобы просто объект обновить!
      if (!props.isTemplate && updatedItemsList.length>0) {

        updateItems(__tableId, updatedItemsList) //TODO Сделать групповое обновление
      }        
      return newTables;
    }); 
    //setEdited(true);  
    */
  }

  const onRowValueChange = (_tableId, rowId, value, rix, colKey) => {
    console.log('onRowValueChange ', _tableId, rowId, value, rix, colKey)
    const __tableId = _tableId
    const v = value;
    const rx = rix;
    const k = colKey;

    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newitems = { ...newTables[__tableId].items };

      newitems[rowId][k] = v;

      newTables[__tableId].itemsList = [...newTables[__tableId].itemsList] //чтобы объект обновить!
      newTables[__tableId].items = newitems
      return newTables
    });
    //setEdited(true);         
  }




  const handleRowUpdate = (_tableId, changes, sortedRows) => {
    console.log('handleRowUpdate changes', changes)
    const __tableId = _tableId

    //есть _id        
    if (!props.isTemplate) {
      if (changes.action === "COPY_PASTE" && sortedRows && changes.toRow >= 0) {
        /**
         * вот такой формат:
         * {
            "cellKey": "6172dd695ae380283441f9ea",
            "fromRow": 1,
            "toRow": 18,
            "updated": {
              "6172dd695ae380283441f9ea": "2021-11-10T10:30:00.000Z"
            },
            "action": "COPY_PASTE",
            "fromCellKey": "6172dd695ae380283441f9ea"
          }
         */
        if (sortedRows[changes.toRow] && sortedRows[changes.toRow]._id) {
          var changes_id = sortedRows[changes.toRow]._id
          //updateItem(__tableId, changes_id, changes.updated);
        }
      } else {
        if (!changes._id) {
          //var defaultValues = props.views[props.viewId].defaultValues?props.views[props.viewId].defaultValues:{}
          const newRow = { ...changes.updated, index: 999 }
          //insertItem(__tableId, newRow);
        } else {
          //updateItem(__tableId, changes._id, changes.updated);
        }
      }

    }

  }

  const onItemUpdate = (_tableId, item) => {
    console.log('onItemUpdate row', item);
    const r = item;
    const __tableId = _tableId
    if (!r._id) {
      //var defaultValues = views[_viewId].defaultValues?views[_viewId].defaultValues:{}
      const newRow = { ...r.updated, index: r.fromRow }
      //insertItem(__tableId, r);
    }
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newitems = { ...newTables[__tableId].items };
      var newItemsList = [...newTables[__tableId].itemsList]

      newitems[r._id] = r;


      newTables[__tableId].items = newitems
      newTables[__tableId].itemsList = newItemsList
      return newTables
    });
  }
  const onItemsUpdate = useCallback((_tableId, items)  => {
    console.log('onItemsUpdate items', items);
    const _items = items;
    const __tableId = _tableId

    if (_items && Array.isArray(_items) && _items.length > 0) {
        const updatedItems = []
        const addedItems = []
        var newTables = { ...tables }
        var newitems = { ...newTables[__tableId].items };
        console.log('onItemsUpdate updatedItems 1 ', updatedItems);
      for (var idx = 0; idx < _items.length; idx++) {
        var updatedItem = _items[idx]
        var changed = false
        if (!updatedItem._id) {
          //это новый элемент
          var _id = ObjectID().toHexString()
          //console.log('insertItem values ', values)
          const addedItem = {
            ...updatedItem,
            _id: _id,
            work_item_list: __tableId
          }
          addedItems.push(addedItem)
          newitems[_id] = { ...addedItem }
          console.log('onItemsUpdate updatedItems addedItem ', addedItem);
        } else {
          //это изменнный
          var changedCols = { _id: updatedItem._id }
          for (var col in updatedItem) {
            if (newitems[col] === undefined || newitems[col] !== updatedItem[col]) {
              changed = true
              changedCols[col] = updatedItem[col]
            }
          }
          console.log('onItemsUpdate updatedItems updatedItem ', updatedItem);
          if (changed) {
            newitems[updatedItem._id] = { ...newitems[updatedItem._id], ...changedCols };
            updatedItems.push(updatedItem)
          }
        }
      }
        if(updatedItems.length>0 || addedItems.length>0){
          console.log('onItemsUpdate updatedItems 2 ', updatedItems);
          console.log('onItemsUpdate addedItems 2 ', addedItems);
          var newItemsList = [...newTables[__tableId].itemsList]          
          newTables[__tableId].items = newitems
          newTables[__tableId].itemsList = newItemsList
          if (!props.isTemplate ) {
            if(updatedItems.length>0){
              //updateItems(__tableId, updatedItems);
            } 
            if(addedItems.length===1){
              //insertItem2(__tableId, addedItems[0]);
            } else if(addedItems.length>1 )   {
              addedItems.forEach(ait=> insertItem2(__tableId, ait) )
            }          
          }        
          setTables(newTables)
    }
  }
  }, [tables]); 

  const onItemDelete = (_tableId, item) => {
    console.log('deleteItem ', _tableId, item);
    const __tableId = _tableId
    const _itemId = item._id
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newitems = { ...newTables[__tableId].items };
      var newitemList = [...newTables[__tableId].itemsList];
      const idx = newitemList.findIndex(it => it._id === _itemId)
      newitemList = [
        ...newitemList.slice(0, idx),
        ...newitemList.slice(idx + 1)
      ];
      delete newitems[_itemId]
      newTables[__tableId].items = newitems
      newTables[__tableId].itemsList = newitemList
      if (!props.isTemplate) {
        deleteItem(__tableId, _itemId);
      }
      return newTables
    });

    //setEdited(true); 
  }

  /////////////////////////// /////////////////////////// /////////////////////////// /////////////////////////// /////////////////////////// 
  /*
    const setViews = (tableId, newViews) => {
      const _tableId = tableId
      setTables(currentTables => {
        var newTables = { ...currentTables }
        newTables[_tableId].views = newViews;
        return newTables
      });
    }
  */
    const handleChangeGroups = (_tableId, _viewId, newGroups) => {
      const __tableId = _tableId
      const __viewId = _viewId
      const v = newGroups
      setTables(currentTables => {
        var newTables = { ...currentTables }
        newTables[__tableId].views[__viewId].groups = v;
        //updateView(__tableId, __viewId, { groups: v });
        return newTables
      });
    };  

    const handleChangeSorts = (_tableId, _viewId, newSorts) => {
      const __tableId = _tableId
      const __viewId = _viewId
      const v = newSorts
      setTables(currentTables => {
        var newTables = { ...currentTables }
        newTables[__tableId].views[__viewId].sorts = v;
        //updateView(__tableId, __viewId, { sorts: v });
        return newTables
      });
    };     

  const handleChangeGroupBy = (_tableId, _viewId, newGroupBy) => {
    const __tableId = _tableId
    const __viewId = _viewId
    const v = newGroupBy
    setTables(currentTables => {
      var newTables = { ...currentTables }
      newTables[__tableId].views[__viewId].groupBy = v;
      //updateView(__tableId, __viewId, { groupBy: v });
      return newTables
    });
  };

  const saveDefaultValues = (_tableId, _viewId, newDefaultValues) => {
    const __tableId = _tableId
    const __viewId = _viewId
    const _newDefaultValues = newDefaultValues

    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newViews = { ...newTables[__tableId].views }
      newViews[__viewId].defaultValues = _newDefaultValues
      newTables[__tableId].views = newViews
      //newTables[__tableId].views[__viewId].listColumnsOptions = _newListColumnsOptions;
      //updateView(__tableId, __viewId, { defaultValues: _newDefaultValues });
      return newTables
    });

  }

  const onUpdateListColumnsOptions = (_tableId, _viewId, newListColumnsOptions) => {
    const __tableId = _tableId
    const __viewId = _viewId
    const _newListColumnsOptions = newListColumnsOptions

    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newViews = { ...newTables[__tableId].views }
      newViews[__viewId].listColumnsOptions = _newListColumnsOptions
      newTables[__tableId].views = newViews
      //newTables[__tableId].views[__viewId].listColumnsOptions = _newListColumnsOptions;
      //updateView(__tableId, __viewId, { listColumnsOptions: _newListColumnsOptions });
      return newTables
    });

  }
  const onColumnResize = (_tableId, _viewId, key, width) => {
    console.log('onColumnResize', _tableId, _viewId, key, width)    //TODO Вызов идет по сути уже после того как сама таблица изменилась визуульно.    
    const __tableId = _tableId
    const __viewId = _viewId
    const __key = key
    const __width = width
    //var newListColumnsOptions = {...props.views[vId].listColumnsOptions};

    //newListColumnsOptions[key].width = width;

    //props.onUpdateListColumnsOptions(props.tableId, vId, newListColumnsOptions)
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newListColumnsOptions = { ...newTables[__tableId].views[__viewId].listColumnsOptions };
      console.log('onColumnResize newListColumnsOptions ', newListColumnsOptions)
      if (!newListColumnsOptions[__key]) {
        //console.log('onColumnResize не найдена колонка в виде! ')
        //return currentTables 
        //а такая колонка есть в списке колонок
        var newCol = newTables[__tableId].columns.find(cl => cl._id === __key)
        if (!newCol) {
          console.log('onColumnResize не найдена колонка в представлении! ')
          return currentTables
        } else {
          newListColumnsOptions[__key] = {
            index: 99,
            frozen: false,
            width: __width
          }
        }
      } else {
        newListColumnsOptions[__key].width = __width;
      }

      var newViews = { ...newTables[__tableId].views }
      newViews[__viewId].listColumnsOptions = newListColumnsOptions
      newTables[__tableId].views = newViews

      //newTables[__tableId].views[__viewId].listColumnsOptions = newListColumnsOptions
      //newTables[__tableId].views[__viewId].listColumnsOptions = _newListColumnsOptions;
      //updateView(__tableId, __viewId, { listColumnsOptions: newListColumnsOptions });
      return newTables
    });

  }

  const onColumnFrozen = (_tableId, _viewId, key, val) => {
    console.log('onColumnFrozen', _tableId, _viewId, key, val)
    const __tableId = _tableId
    const __viewId = _viewId
    const __key = key
    const __val = val
    //const __width = width
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newListColumnsOptions = { ...newTables[__tableId].views[__viewId].listColumnsOptions };
      console.log('onColumnFrozen newListColumnsOptions ', newListColumnsOptions)
      if (!newListColumnsOptions[__key]) {
        var newCol = newTables[__tableId].columns.find(cl => cl._id === __key)
        if (!newCol) {
          console.log('onColumnResize не найдена колонка в представлении! ')
          return currentTables
        } else {
          newListColumnsOptions[__key] = {
            index: 99,
            frozen: __val,
            width: 150
          }
        }
      } else {
        newListColumnsOptions[__key].frozen = __val;
      }
      //var __frozen = newListColumnsOptions[__key].frozen

      var newViews = { ...newTables[__tableId].views }
      newViews[__viewId].listColumnsOptions = newListColumnsOptions
      newTables[__tableId].views = newViews

      //newTables[__tableId].views[__viewId].listColumnsOptions = _newListColumnsOptions;
      console.log('onColumnFrozen newListColumnsOptions 2 ', newListColumnsOptions)
      //updateView(__tableId, __viewId, { listColumnsOptions: newListColumnsOptions });
      return newTables
    });
  }

  const handleColumnsReorder = (_tableId, _viewId, sourceKey, targetKey) => {
    console.log('handleColumnsReorder ', _tableId, _viewId, sourceKey, targetKey)
    const __tableId = _tableId
    const __viewId = _viewId
    const sk = sourceKey;
    const tk = targetKey;

    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newListColumnsOptions = { ...newTables[__tableId].views[__viewId].listColumnsOptions };
      console.log('handleColumnsReorder newListColumnsOptions 1', newListColumnsOptions)
      var nextIndex = 99//columnsKeys.length; 
      var _cols = newTables[__tableId].columns
      for (let ic = 0; ic < _cols.length; ic++) {
        //let colOpt = newTables[__tableId].columns[ic]
        let colOpt = newListColumnsOptions[_cols[ic]._id];
        if (!colOpt) {
          newListColumnsOptions[_cols[ic]._id] = {
            index: nextIndex,
            frozen: false,
            width: 0
          }
          nextIndex++
        }

      }
      console.log('handleColumnsReorder newListColumnsOptions 2', newListColumnsOptions)

      const reorderedColumns = Object.keys(newListColumnsOptions).sort((a, b) => newListColumnsOptions[a].index - newListColumnsOptions[b].index);
      reorderedColumns.forEach((e, index) => {
        newListColumnsOptions[e].index = index;
      })
      const sourceColumnIndex = newListColumnsOptions[sk].index;
      const targetColumnIndex = newListColumnsOptions[tk].index;

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );
      console.log('handleColumnsReorder reorderedColumns ', reorderedColumns)

      reorderedColumns.forEach((e, index) => {
        newListColumnsOptions[e].index = index;
      })

      var newViews = { ...newTables[__tableId].views }
      newViews[__viewId].listColumnsOptions = newListColumnsOptions
      newTables[__tableId].views = newViews
      //newTables[__tableId].views[__viewId].listColumnsOptions = newListColumnsOptions
      //newTables[__tableId].views[__viewId].listColumnsOptions = _newListColumnsOptions;
      //updateView(__tableId, __viewId, { listColumnsOptions: newListColumnsOptions });
      return newTables
    });

  }

  const handleSetRowHeight = (_tableId, _viewId, v) => {
    const __tableId = _tableId
    const __viewId = _viewId
    const _val = v
    setTables(currentTables => {
      var newTables = { ...currentTables }
      newTables[__tableId].views[__viewId].rowHeight = _val;
      //updateView(__tableId, __viewId, { rowHeight: _val });
      return newTables
    });

  }


  const onClickChatBotsPanel = (e) => {
    if (openJobsPanel) {
      if (rigthPanelContent === "bots") {
        //закроем
        setOpenJobsPanel(false)
        setRigthPanelContent(null)
        setCurrentPanelSize("100%")
      } else {
        //помеянем
        setRigthPanelContent("bots")
        setCurrentPanelSize(botsPanelSize)
      }
    } else {
      setRigthPanelContent("bots")
      setOpenJobsPanel(true)
      setCurrentPanelSize(botsPanelSize)
    }

  }

  const onClickJobsPanel = (e) => {
    if (openJobsPanel) {
      if (rigthPanelContent === "jobs") {
        //закроем
        setOpenJobsPanel(false)
        setRigthPanelContent(null)
        setCurrentPanelSize("100%")
      } else {
        //помеянем
        setRigthPanelContent("jobs")
        setCurrentPanelSize(jobsPanelSize)
      }
    } else {
      setRigthPanelContent("jobs")
      setOpenJobsPanel(true)
      setCurrentPanelSize(jobsPanelSize)
    }

  }

  /**
   * Изменения связанные с открытием таблицы, т.е. инициация, а также подписка на события socket
   */
  useEffect(() => {
    console.log('SharedView useEffect sharedId', sharedId)
    init();
  }, [sharedId, init]);

  useEffect(() => {

    var _tables = []
    if (tables) {
      //console.log('ViewsConfigList props.views', props.views);
      var sortedIds = Object.keys(tables).sort((a, b) => tables[a].index - tables[b].index);

      for (let it = 0; it < sortedIds.length; it++) {
        let tb = tables[sortedIds[it]]
        if (tb.hidden) {

        } else {
          _tables.push(tb)
        }
      }
    }
    setTablesTabs(_tables)

  }, [tables])



  return (

    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>{title ? title : "Projecton - система управления проектами, задачами и целями со встроенным ассистентом"}</title>
        <link rel="icon" href={`data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${icon ? icon : "🎯"}</text></svg>`} />
      </Helmet>
      <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: dbBackgroundColor }}>
        <Toolbar style={{ minHeight: "48px" }}>
          <Tooltip title={"Перейти на главную страницу"}>
            <Link to={`/dashboard`} color="inherit">
              <IconButton edge="start" className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Typography
            variant="h6"
            component="h1"
            color="inherit"
            noWrap
            style={{
              flexGrow: 1,
            }}
          >
          <span>
            {title}  
          </span>          
          </Typography>       
          {profile ? <>


            <ProfileMenu profile={profile} />
          </>
            :
            <Button variant="outlined" size="small"
              edge="end" color="inherit"
              href={"/signin?redirect_url=" + encodeURIComponent(currentURLPath)} >
              Войти
            </Button>
          }
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: false,
        })}>
        <Toolbar style={{ minHeight: "48px" }} />
        {noLoadDB && <div
          style={{
            height: `calc(100vh - 80px)`,
          }}        
        >
          <Typography variant="h6">Выбранной рабочей области не существует, либо у вас нет доступа к ней.</Typography>
          </div>}

          <div>
            {tablesTabs && tables && tablesTabs.map(table => (
              <div
                role="tabpanel"
                hidden={table._id !== wiId}
                id={`scrollable-prevent-tabpanel-${table._id}`}
                aria-labelledby={`scrollable-prevent-tab-${table._id}`}
              >
                {tables[table._id] && <>
<ReportEditor
                    viewContentRef={viewContentRef}
                    viewContentWidth = {viewContentWidth}
                    setViewContentWidth={setViewContentWidth}
                    viewContentHeight={viewContentHeight}
                    setViewContentHeight={setViewContentHeight}  
  dbtitle={title}
  wiId={wiId}
  tableId={table._id}
  views={tables[table._id].views}
  items={tables[table._id].items}
  columns={tables[table._id].columns}
  //setColumns={setColumns}
  chatViewLinks={tables[table._id].chatViewLinks}
  dbId={dbId}
  viewId={viewId}
  members={members}
  chatmembers={chatmembers}
  chatgroups={chatgroups}
  botchannel={botchannel}
  jobsPanelSize={currentPanelSize} //(openJobsPanel && rigthPanelContent)?(rigthPanelContent==="jobs"?jobsPanelSize:botsPanelSize):0}
  handleChangeGroupBy={handleChangeGroupBy}
  handleChangeGroups={handleChangeGroups}
  handleChangeSorts={handleChangeSorts}
  onUpdateListColumnsOptions={onUpdateListColumnsOptions}
  handleSetRowHeight={handleSetRowHeight}
  updateView={e=>{}}
  tables={tables}
  tablesList={tablesTabs}
  errorMsg={errorMsg}
  setErrorMsg={setErrorMsg}
  onItemDelete={onItemDelete}
  insertItem={insertItem}
  onItemUpdate={onItemUpdate}
  onItemsUpdate={onItemsUpdate}
  handleRowUpdate={handleRowUpdate}
  onRowValueChange={onRowValueChange}
  onRowReorder={onRowReorder}
  addView={e=>{}}
  onChangeColumn={onChangeColumn}
  saveTable={saveTable}
  onColumnDelete={onColumnDelete}
  onColumnInsert={onColumnInsert}
  updateColumn={e=>{}}
  setColumns={setColumns}
  deleteView={e=>{}}
  copyView={e=>{}}
  loadingWI={loadingWI}
  onColumnResize={onColumnResize}
  onColumnFrozen={onColumnFrozen}
  handleColumnsReorder={handleColumnsReorder}
  createChatViewLink={e=>{}}
  deleteChatViewLink={e=>{}}
  editedItem={editedItem}
  setEditedItem={setEditedItem}
  setOpenItemEditor={setOpenItemEditor}
  openItemEditor={openItemEditor}
  dialogs={dialogs}
  saveDefaultValues={saveDefaultValues}
  accessLevel={accessLevel}
  createViewShareLink={e=>{}}
  deleteViewShareLink={e=>{}}
  saveSummaryRow={e=>{}}
  saveSummaryGroupRow={e=>{}}
  currentMember={currentMember}
  sharedView={true}
/>

</>}
              </div>
            ))}
            <ItemEditDialog

              members={members}
              chatmembers={chatmembers}
              //columns={(viewColumns && viewColumns.length>0) ? viewColumns:props.columns }
              editedItem={editedItem}
              setEditedItem={setEditedItem}
              //editedIdx={editedIdx} setEditedIdx={setEditedIdx} 
              open={openItemEditor}
              setOpen={setOpenItemEditor}
              //newItemIndex={newItemIndex}
              //tableId={props.tableId}
              tables= {[]}//{tables} пока не грузим элементы с других листов
              accessLevel={accessLevel}
              currentMember={currentMember}
            />
          </div>



      </main>

    </div>

  );
}
